<template>
  <v-container
      fill-height
      fluid
      grid-list-xl
      class="main-layout_inner_second"
  >
    <v-layout
        justify-center
        wrap
    >
      <!-- inline edit table -->
      <v-flex xs12>

        <v-flex xs12 md12 v-if="form">
          <center>
            <h3 class="main_title" style="color:rgb(61 81 94)">{{ $t('Checkin.protection-password') }}</h3>

            <p class="pw-title font-weight-bold" style="color:rgb(51 72 86)">{{ form.password }}</p>

            <p style="color:rgb(186 186 186); width: 70%; font-size: 12px;">{{ $t('Checkin.you-will-need-the-above-password-the-next-time-you-access-the-page') }}</p>

            <h5 class="mb-0 checkin_qr_txt font-weight-bold">{{ $t('Checkin.qr-code-for-check-in') }}</h5>
            <vue-qrcode v-if="qrCodeStr" id="qrblock" ref="qrcode" style="width: 70%" :value="qrCodeStr" />

            <p class="mb-5 checkin_qr_txt">{{ $t('Checkin.scanning-this-qr-code-with-the-tablet-device-at') }}</p>

            <v-btn @click.native="downloadQrCode()"
                   class="qr-code-download-btn checkin_qr mb-5"
            >{{ $t('Checkin.save-image') }}</v-btn>

            <v-col class="text-left" style="width: 80%" v-bind:style="{ 'background-image': 'url(' + '/img/human_icon.png' + ')', 'background-repeat' : 'no-repeat', 'background-position' : '0 10px' }">
              <p style="color: rgb(150 150 150); margin-left:36px;">{{ $t('Checkin.please-note-that-during-check-in') }}</p>
            </v-col>

          </center>
        </v-flex>
      </v-flex>
    </v-layout>
<!--    <div class="main_btn pt-0" >-->
<!--      <v-btn  @click="closeTab()" block class="btn-custom btn-custom-main" style="height: auto !important; min-height: 47px !important;padding: 3px 0px !important"-->
<!--      >{{ $t('Checkin.close-browser') }}</v-btn>-->
<!--    </div>-->
  </v-container>
</template>

<script>
import VueQrcode from "vue-qrcode";

export default {
  name: "GuestReiviewRegisted",
  components: {
    VueQrcode,
  },

  data: () => ({
    form : null,
    qrCodeStr : '',
    step : '6/6',
    careerItems : { 1 : '会社員', 2 : '自営業', 3 : 'その他' },
  }),
  watch : {

  },

  computed: {
    getEnterFinishedScreenUrl() {
      return "finished";
    },

    getGuestEnterCreateCareerScreenUrl(){
      return 'guest-checkin-create-career'
    },
    isWebviewAndroid() {
      return navigator.userAgent.includes("wv");
    },

    isWebviewIos() {
      return /MessengerLiteForiOS|Line|Zalo iOS|Instagram/i.test(navigator.userAgent)
    }
  },

  created () {
    this.fetchGuest()
  },

  methods: {
    fetchGuest(){
      this.form = this.$store.state.guest
      let qrCodeForm = {bookId: this.$store.state.guestReserve.rsvId, personId: this.$store.state.guest.id}
      this.qrCodeStr = JSON.stringify(qrCodeForm)
    },

    downloadQrCode() {
      let oQrcode = document.getElementById("qrblock");
      let url = oQrcode.src;
      if (this.isWebviewAndroid || this.isWebviewIos) {
        this.$router.push({ path: "/qr", query: { qr: this.qrCodeStr } });
      } else {
        const a = document.createElement("a");
        let event = new MouseEvent("click");
        // Customize the name of the image after downloading
        a.download = "QR code";
        a.href = url;
        a.dispatchEvent(event);
      }
    }
  }
}
</script>

<style lang="scss">
.next-screen-btn {
  background-color: rgb(97 110 126) !important;
  color: #fff;
}
.next-screen-btn span {
  font-weight: bold;
}

.pw-title {
  font-size: 60px !important;
  color: rgb(51 72 86) !important;
  text-align: center !important;
}

.qr-code-download-btn.checkin_qr {
  background-color: #1EA6C0 !important;
  border-radius: 999px !important;
  color: #fff !important;
  box-shadow: none !important;
  font-size: 15px !important;
  padding: 0px 25px !important;
  height: 45px!important;
}
</style>
