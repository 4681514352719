<template>
  <v-app>
  <core-multi-lang  :number-step="step" :back-path="getGuestEnterCreateAddressScreenUrl"/>
<v-container
    fill-height
    fluid
    grid-list-xl
    class="main-layout_inner"
  >
    <v-layout
      justify-center
      wrap
    >
      <!-- inline edit table -->
      <v-flex xs12>

        <v-form v-if="form">
          <v-flex xs12>
            <h3 class="main_title">{{ $t('Checkin.please-select-the-guest-occupation') }}</h3>

            <v-row
              align="center"
              justify="space-around"
              class="mx-auto mt-5 px-0"
            >

            <div class="radio-block">
              <div xs4 sm4 v-bind:key="careerIndex" v-for="(careerItem, careerIndex) in careerItems" class="career-item">
                <input type="radio" :id="'radio-career-' + careerIndex" name="radioCareer" :value="careerIndex"  v-model="form.occupation">
                <label :for="'radio-career-' + careerIndex" v-bind:style="{ 'background-color' : ( (careerIndex == form.occupation) ? '#ccc' : '#616e7e')}">{{ $t('Checkin.' + careerItem) }}</label>
              </div>

            </div>
            
            </v-row>

          </v-flex>
          <v-flex v-if="form.occupation == 3">
            <v-text-field
              v-model="otherOccupationName"
              class="purple-input"
              :label="$t('Checkin.other-optional')"
            />
          </v-flex>
        </v-form>

      </v-flex>
    </v-layout>
    <div class="main_btn">
            <v-btn 
              class="btn-custom w-100"
              v-on:click="handleSubmit()"
              v-bind:disabled="!valid" v-bind:class="valid ? 'btn-custom-main' : 'btn-custom-disabled'" 
            >{{ $t('Checkin.next') }}</v-btn>
          </div>
  </v-container>
  </v-app>
</template>

<style lang="scss">
  .radio-toolbar {
    margin: 10px;
  }

  .radio-toolbar .career-item {
    float: left;
  }

  .radio-toolbar input[type="radio"] {
    opacity: 0;
    width: 0;
  }

  .radio-toolbar label {
    display: inline-block;
    background-color: #616e7e;
    padding: 10px 20px;
    font-family: sans-serif, Arial;
    font-size: 12px;
    color : #f3f4f6;
    border-top-right-radius: 20% 50%;
    border-bottom-right-radius: 20% 50%;

    border-top-left-radius: 20% 50%;
    border-bottom-left-radius: 20% 50%;
  }

  .radio-toolbar label:hover {
    background-color: #ccc;
  }

  .btn-submit-green{
    width:100%;
    background-color :rgb(97 110 126) !important;
    color : #fff !important;
  }
  
  .btn-submit-green-disabled{
    width:100%;
    background-color: #e0e0e0 !important;
    color: #f3f3f3;
  }

</style>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data: () => ({
    form : null,
    step : '5/6',
    valid : false,
    careerItems : { 1 : 'company-employee', 2 : 'business-owner', 3 : 'other' },

  }),
  watch : {
    occupation(value){
      if(value){
        this.valid = true
      }else{
        this.valid = false
      }
    },

    lang(val){
      this.handleOccupationGrid(val)
    }
  },

  computed: {
    getEnterReviewRegisteredScreenUrl(){
      return 'review-registered'
    },

    getGuestEnterCreateAddressScreenUrl(){
      return 'guest-checkin-create-address'
    },

    getEnterFinishRegisteredScreenUrl(){
      return 'finish-registered'
    },

    ...mapState(['reserve_guests', 'reserve_user']),
    ...mapGetters(['lang']),

    occupation(){
      if(this.form){
        return parseInt(this.form.occupation)
      }else{
        return null
      }
    },
    otherOccupationName: {
      get() {
        if (this.form){
          return this.form.otherOccupationName
        } else {
          return null
        }
      },
      set(value) {
        if (value) {
          this.form.otherOccupationName = value
        } else {
          this.form.otherOccupationName = null
        }
      }
    }
  },

  created () {
    this.fetchGuest()
  },

  mounted(){
    this.handleOccupationGrid(this.$i18n.locale)
  },

  updated(){
    this.handleOccupationGrid(this.$i18n.locale)
  },

  methods: {
    handleOccupationGrid(lang){
      var lists = document.getElementsByClassName('career-item')
      if(lists.length){
        for(let item of lists){
          item.style.minWidth = 'auto'
        }
      }

      if(lang !== 'ja'){
        var width = 0
        for(let item of lists){
          if(item.offsetWidth > width){
            width = item.offsetWidth
          }
        }

        console.log(width)
        for(let item of lists){
          item.style.minWidth = width + 'px'
        }
      }else{
        for(let item of lists){
          item.style.minWidth = 'auto'
        }
      }
    },

    handleSubmit(){
      this.form.occupation = (this.form.occupation ? parseInt(this.form.occupation) : null)
      this.$store.dispatch('guestUpdateReservation', this.form).then(() => {
        this.$router.push(this.getEnterReviewRegisteredScreenUrl)
      }).catch( err => {
        console.log(err.response)
      })
    },

    fetchGuest(){
      this.form = this.$store.state.guest
      this.userId = this.form.id
    },
  }
}
</script>

<style lang="scss">
</style>
