<template>
  <section class="message_item mb-20" :id="'guest-item-' + personItem.id">
    <div class="d-flex flex-column">
      <div class="d-inline-flex align-baseline">
        <h5 v-if="isFinishPage" class="message_title">
          {{
            personItem.nickName
              ? personItem.nickName
              : $t("Checkin.guest").replace("%n%", personIndex + 2)
          }}
        </h5>
        <h5 v-else class="message_title">
          {{ $t("Checkin.guest").replace("%n%", personIndex + 2) }}
        </h5>
        <template v-if="!notOtherThanOwner">
          <img
            v-if="showAddNickName"
            @click="_handleEditName"
            alt="ic-edit"
            class="mx-3 ic-edit"
            src="../../assets/img/icon-edit.png"
          />
          <span class="sub-feature-text">{{
            $t("Checkin.add-nick-name")
          }}</span>
        </template>
      </div>

      <template v-if="!notOtherThanOwner">
        <span v-if="personItem.name && personItem.address">{{
          $t("Checkin.entered")
        }}</span>
        <span v-else>{{ $t("Checkin.not-entered") }}</span>
      </template>
    </div>

    <template v-if="!notOtherThanOwner">
      <div class="text-right mb" v-if="isPc">
        <a
          v-if="reserve"
          :href="
            'mailto:' +
              (personItem.email != null ? personItem.email : '') +
              '?body=' +
              guestLoginScreenUrl +
              '/' +
              reserve.booking.id +
              '?userId=' +
              personItem.id +
              '%26nickName=' +
              personItem.nickName
          "
          class="enter-infor-btn btn-custom"
          v-bind:style="{
            'background-image': 'url(' + '/img/icon-share.png' + ')',
            'background-repeat': 'no-repeat',
            'background-position': '20px',
            'text-decoration': 'none',
            'line-height': '48px',
            display: 'inline-block',
          }"
          >{{ $t("Checkin.send") }}</a
        >
      </div>

      <div class="text-right mb" v-else>
        <button
          class="enter-infor-btn btn-custom"
          v-if="reserve"
          @click="_handleShareMobile"
          :style="{
            'background-image': 'url(' + '/img/icon-share.png' + ')',
            'background-repeat': 'no-repeat',
            'background-position': '20px',
            cursor: 'pointer',
            background: '',
          }"
        >
          {{ $t("Checkin.send") }}
        </button>
      </div>
    </template>

    <template v-if="notOtherThanOwner">
      <div class="text-right mt-n6">
        <button
          class="enter-infor-btn btn-custom"
          @click="_handleUploadPassport"
        >
          {{ $t("Checkin.enter") }}
        </button>
      </div>
    </template>
    <template v-else>
      <div class="text-right">
        <button
          class="enter-infor-btn btn-custom"
          @click="_handleUploadPassport"
        >
          {{ $t("Checkin.agent-enter") }}
        </button>
      </div>
    </template>
    <hr class="border-bottom mt-20" />
  </section>
</template>

<script>
export default {
  name: "PersonCard",
  props: {
    personItem: {
      type: Object,
      required: true,
    },
    personIndex: {
      type: Number,
    },
    reserve: {
      type: Object,
    },
    guestLoginScreenUrl: {
      type: String,
      default: () => "",
    },

    supportedShare: {
      type: Boolean,
      default: () => false,
    },

    isPc: {
      type: Boolean,
      default: () => false,
    },
    showAddNickName: {
      type: Boolean,
      default: () => false,
    },
    isFinishPage: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    notOtherThanOwner() {
      return this.$store.getters.notOtherThanOwner
    },
  },
  methods: {
    _handleShareMobile() {
      this.$emit("share-mobile")
    },

    _handleUploadPassport() {
      this.$emit("upload-passport")
    },

    _handleEditName() {
      this.$emit("edit-name")
    },
  },
}
</script>

<style scoped lang="scss">
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.enter-infor-btn.btn-custom {
  background: rgb(97, 110, 126);
  color: rgb(255, 255, 255);
  width: 50%;
  background-position: 15px center !important;
}

.message-heading {
  color: rgb(66 66 66) !important;
  font-size: 19.5px !important;
  font-weight: 600 !important;
  margin-bottom: 25px;
}
.message_item {
  margin-top: 16px;
}
.message_title {
  font-size: 17px;
  color: #424242;
  margin-bottom: 10px;
}
.message-desc {
  color: rgb(91 91 91) !important;
  font-size: 14px !important;
}

.btn-submit-green span {
  font-weight: bold;
}
.v-menu__content {
  background-color: #ffffff;
  width: 70%;
  max-height: 150px;
  min-width: auto !important;
  left: 50% !important;
  transform: translate(-50%, -100px) !important;
}

.ic-edit {
  cursor: pointer;
}
</style>
