<template>
  <div v-if="sizePc && showOcrPc">
    <OcrPc
      :button-text-one="$t('Checkin.take-photo-again')"
      :button-text-two="$t('Checkin.next')"
      @icon-close="onIconClose"
      :form="ocrForm"
      @submit-two="handleForeignSubmit"
      @submit-one="showOcrPc = false"
    ></OcrPc>
  </div>
  <v-container
    v-else
    fill-height
    fluid
    grid-list-xl
    class="main-layout_inner_second layout-h"
  >
    <v-layout justify-center wrap>
      <!-- inline edit table -->
      <v-flex xs12 class="pt-0 checkin_upload">
        <v-flex xs12 md12>
          <h3 class="main_title">
            {{ $t("Checkin.please-upload-a-photo-of-the-guest-passport") }}
          </h3>

          <v-img
            v-if="passportImage"
            max-width="100%"
            :src="passportImage"
            class="passport-img mb-3"
          ></v-img>
          <div class="d-flex">
            <v-col cols="1" class="d-flex align-self-center pr-0 pl-1">
              <img
                :src="cameraIconUrl"
                alt=""
                class="img-fluid"
                style="max-height: 36px"
              />
            </v-col>

            <v-col cols="11" class="pr-0">
              <v-btn
                class="
                    text-none
                    font-weight-bold
                    upload-passport-btn
                    checkin_upload_btn
                  "
                :loading="isSelecting"
                @click="onButtonClick"
              >
                {{ $t("Checkin.upload-passport") }}
              </v-btn>
            </v-col>
          </div>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept="image/*"
            @change="onFileChanged"
          />
          <div class="sub-title sub-passport">
            <ul>
              <li>{{ $t("Checkin.preventive-measures") }}</li>
              <li class="mt-2">
                {{
                  $t(
                    "Checkin.please-page-with-matching-face-photo-and-passport-number"
                  )
                }}
              </li>
              <li class="mt-4">{{ $t("Checkin.check-the-characters") }}</li>
            </ul>
          </div>
        </v-flex>
      </v-flex>
      <OcrMobile
        :button-text-one="$t('Checkin.take-photo-again')"
        :button-text-two="$t('Checkin.next')"
        :dialog="showOcr"
        @icon-close="onIconClose"
        :form="ocrForm"
        @submit-two="handleForeignSubmit"
        @submit-one="showOcr = false"
      ></OcrMobile>
    </v-layout>
    <div v-if="!showOcr" class="main_btn">
      <v-btn
        v-bind:style="{
          'font-size':
            $i18n.locale != 'ja' ? '14px!important' : '17px !important'
        }"
        block
        id="japanese-citizen"
        class="btn-custom btn-custom-black mb"
        v-on:click="handleJapanSubmit()"
        >{{ $t("Checkin.i-am-a-japanese-citizen") }}
      </v-btn>

      <v-btn
          :loading="uploading"
        v-bind:disabled="!valid"
        block
        v-bind:class="
          valid
            ? 'btn-submit-green'
            : 'btn-submit-green-disabled btn-custom-disabled'
        "
        class="btn-custom"
        v-on:click="handleDefaultSubmit()"
        >{{ $t("Checkin.next") }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import StringUtil from "@/shared/StringUtil";
import moment from "moment";

export default {
  name: "UploadPassport",
  components: {
    OcrMobile: () => import("@/components/popup/OcrMobile"),
    OcrPc: () => import("@/components/popup/OcrPc")
  },
  data() {
    return {
      step: "1/5",
      valid: false,
      passportImage: null,
      selectedFile: null,
      isSelecting: false,

      // from url params
      isGuest: false,
      userId: null,
      addParams: "",
      showOcr: false,
      showOcrPc: false,
      ocrForm:{},
      uploading:false
    };
  },

  computed: {
    getCheckinInforScreenUrl() {
      return "enter-information" + this.addParams;
    },

    cameraIconUrl() {
      return require("@/assets/img/camera-icon.png");
    },
    ...mapState({
      showHeader: state => state.showHeader,
      breakWidth: state => state.breakWidth,
      reserve_user:state => state.reserve_user,
      reserve_guests:state => state.reserve_guests,
      currentUser:state => state.currentUser
    }),
    ...mapGetters(["bookingId", "lang"]),
    sizePc() {
      return this.breakWidth >= 600;
    }
  },
  watch: {
    lang(val) {
      if (val != "ja") {
        document.getElementById("japanese-citizen").style.fontSize =
          "14px!important";
      } else {
        console.log("not de");
        document.getElementById("japanese-citizen").style.fontSize =
          "17px !important";
      }
    }
  },

  mounted() {
     const isGuest = StringUtil.getParamData("isGuest") === "true";
    const userId = StringUtil.getParamData("userId");
    this.isGuest = isGuest;
    this.userId = userId;

    this.addParams = "?isGuest=" + isGuest;

    if (!isGuest) {
      this.fetchUser();
    } else {
      this.addParams = this.addParams + "&userId=" + this.userId;
      this.fetchGuest();
    }
    this.fetchPassportImageUrl();
  },

  updated() {
    if (this.showOcr || this.showOcrPc) {
      this.$store.commit("setShowHeader", false);
    } else {
      this.$store.commit("setShowHeader", true);
    }
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      let fileSelecting = e.target.files[0];
      if (fileSelecting) {
        this.valid = true;
        this.passportImage = URL.createObjectURL(fileSelecting);
        this.selectedFile = fileSelecting;
      }

      this.isSelecting = false;
    },

    handleJapanSubmit() {
      this.handleNextScreen();
    },

    handleForeignSubmit() {
      this.fillFormUser(this.ocrForm)
      this.handleNextScreen();
    },

    handleNextScreen() {
      this.$emit("next-step");
      this.onIconClose();
    },

    handleDefaultSubmit() {
      if (this.selectedFile == null && this.passportImage != null) {
        this.handleNextScreen();
      } else {
        let form = new FormData();
        form.append("passportImageFile", this.selectedFile);
        this.uploading = true;
        this.$store
          .dispatch("uploadPersonPassport", { form: form, id: this.userId })
          .then(res => {
            // console.log('res++',res.request.responseURL);
            this.$store.dispatch('detectPassport',res.request.responseURL).then(res => {

              if(this.sizePc) {
                this.showOcrPc = true;
              } else {
                this.showOcr = true;
              }
              this.ocrForm = {...res.data, birthDate:moment(res.data.birthDate).format("DD MM YYYY")} ?? {};
              this.fillFormUser(res.data)
              this.uploading = false;
            }).catch(error => {
              console.log(error);
              if(this.sizePc) {
                this.showOcrPc = true;
              } else {
                this.showOcr = true;
              }
              this.uploading = false;
              this.ocrForm = {};
              this.clearFormUser();
            })
            // this.handleNextScreen();
          })
          .catch(err => {
            this.uploading = false
            console.log(err);
          });
      }
    },

    fetchUser() {
      this.form = this.$store.state.reserve_user;
      if (this.form) {
        this.userId = this.form.id;
      } else {
        console.log("User is logout!");
      }
    },

    async fetchGuest() {
      this.form = await this.$store.dispatch("getUser", this.userId);
      if (!this.form) {
        console.log("Guest is not existed or deleted!");
      }
    },

    async fetchPassportImageUrl() {
      fetch(window.appApiUrl + "reserved/personPassport/" + this.userId, {
        headers: {
          Authorization: "Bearer " + this.$store.state.token
        }
      })
        .then(response => {
          if (response.status == 200) {
            // console.log('res',response)
            return response.blob();
          } else if (response.status == 401) {
            if (this.bookingId) {
              window.location.href = "/confirm/" + this.bookingId;
            } else {
              window.location.href = "/error-page";
            }
          } else {
            throw "Not image response!";
          }
        })
        .then(blob => {
          let url = URL.createObjectURL(blob);
          // console.log(blob);
          this.passportImage = url;
          this.valid = true;
        })
        .catch(err => {
          console.log(err);
        });
    },
    onIconClose() {
      if (this.sizePc) {
        this.showOcrPc = false;
      } else {
        this.showOcr = false;
      }
    },

    fillFormUser(data) {
      this.$store.commit("setCurrentUser", {
        ...this.currentUser,
        birthDate: moment(data.birthDate).format('YYYY-MM-DD'),
        name:data.name,
        gender:data.gender === 'F' ? 2 : 1
      });
    },

    clearFormUser() {
      this.$store.commit("setCurrentUser", {
        ...this.currentUser,
        birthDate: null,
        name:null,
        gender:null
      });
    }
  }
};
</script>

<style lang="scss">
.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  color: #fff !important;
  height: 43px !important;
}

.btn-submit-green-disabled {
  width: 100%;
  background-color: #e0e0e0 !important;
  color: #f3f3f3;
  height: 43px !important;
}

.upload-passport-btn span {
  display: block;
}

.upload-passport-btn > span.v-btn__loader {
  text-align: center;
}
.upload-passport-btn > span.v-btn__loader > .v-progress-circular {
  margin-top: 6%;
}

.next-screen-btn {
  background: #ccc;
  color: #fff;
}

.next-screen-btn span {
  font-weight: bold;
  color: #fff;
}

.sub-passport {
  ul li {
    font-size: 12px;
    color: #212121;
    max-width: 339px;
    line-height: 16px;
  }
}
</style>
