<template>
  <v-app>
    <core-multi-lang />
    <v-container fill-height fluid grid-list-xl class="main-layout_inner">
      <v-layout justify-center wrap>
        <!-- inline edit table -->
        <v-flex xs12 class="pt-xs-0 pt-0">
          <v-flex>
            <h4 class="main_title">{{ $t("Checkin.intro1") }}</h4>
            <h4 class="main_title">
              {{ $t("Checkin.please-start-the-pre-check-in-system") }}
            </h4>
            <p class="message-desc">
              {{ $t("Checkin.new-intro").replace("%h%", getName) }}
            </p>
            <p class="message-desc mt-3">{{ $t("Checkin.second-intro") }}</p>
          </v-flex>
        </v-flex>
      </v-layout>
      <div class="main_btn">
        <v-btn class="btn-custom btn-submit-green" @click="handleNext">
          {{ $t("Checkin.next") }}
        </v-btn>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import StringUtil from "@/shared/StringUtil"
import { mapState } from "vuex"

export default {
  name: "DaihyoIntro",
  computed: {
    ...mapState({
      settings: (state) => state.settings,
    }),
    getName() {
      return this.settings ? this.settings.name : ""
    },
  },

  methods: {
    handleNext() {
      const rsvId = this.$route.params.bookingId
      const password = StringUtil.getParamData("password")
      if (rsvId && password) {
        this.$store
          .dispatch("login", { password, rsvId })
          .then(() => {
            return this.$router.push("/enter-information")
          })
          .catch((err) => {
            console.log(err)
          })
      }
      this.$emit("next-step", "DaihyoSubmitPassword")
    },
  },
}
</script>

<style scoped></style>
