var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"main-layout_inner_second",attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{staticClass:"pt-0",attrs:{"xs12":""}},[(_vm.form)?_c('v-form',[_c('v-flex',{attrs:{"xs12":""}},[_c('h3',{staticClass:"main_title"},[_vm._v(" "+_vm._s(_vm.$t("Checkin.please-select-the-guest-occupation"))+" ")]),_c('v-row',{staticClass:"mx-auto mt-5 px-0",attrs:{"align":"center","justify":"space-around"}},[_c('div',{staticClass:"radio-block"},_vm._l((_vm.careerItems),function(careerItem,careerIndex){return _c('div',{key:careerIndex,staticClass:"career-item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newForm.occupation),expression:"newForm.occupation"}],attrs:{"type":"radio","id":'radio-career-' + careerIndex,"name":"radio-career"},domProps:{"value":careerItem.value,"checked":_vm._q(_vm.newForm.occupation,careerItem.value)},on:{"change":function($event){return _vm.$set(_vm.newForm, "occupation", careerItem.value)}}}),_c('label',{style:({
                    'background-color':
                      careerItem.value == _vm.newForm.occupation
                        ? '#ccc'
                        : '#616e7e',
                    color: '#FFFFFF',
                  }),attrs:{"for":'radio-career-' + careerIndex}},[_vm._v(_vm._s(_vm.$t("Checkin." + careerItem.label)))])])}),0)])],1),(_vm.newForm.occupation === 3)?_c('v-flex',[_c('v-text-field',{staticClass:"purple-input",attrs:{"label":_vm.$t('Checkin.other-optional')},model:{value:(_vm.otherOccupationName),callback:function ($$v) {_vm.otherOccupationName=$$v},expression:"otherOccupationName"}})],1):_vm._e()],1):_vm._e()],1)],1),_c('div',{staticClass:"main_btn"},[_c('v-btn',{staticClass:"btn-custom",class:_vm.newForm.occupation ? 'btn-submit-green' : 'btn-submit-green-disabled',staticStyle:{"color":"#ffffff !important"},attrs:{"loading":_vm.loading,"disabled":!_vm.newForm.occupation},on:{"click":function($event){return _vm.handleSubmitOccupation()}}},[_vm._v(_vm._s(_vm.$t("Checkin.next")))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }