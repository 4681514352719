<template>
  <v-app>
    <core-multi-lang :number-step="step" :back-path="getGuestUploadPassportScreenUrl"/>
<v-container
    fill-height
    fluid
    grid-list-xl
    class="main-layout_inner"
  >
    <v-layout
      justify-center
      wrap
    >
      <!-- inline edit table -->
      <v-flex xs12 class="pt-0">

        <v-flex xs12 v-if="form">
          <h3 class="main_title">{{ $t('Checkin.your-privacy-protection-password-has-been-set') }}</h3>
          <p class="sub-title">{{ $t('Checkin.your-password-is-number-you-will-need-it-the').replace('%password%', form.password) }}</p>
          <p class="pw-title font-weight-bold">{{ form.password }}</p>
        </v-flex>

      </v-flex>
    </v-layout>
    <div class="main_btn">
      <v-btn block class="btn-custom btn-custom-main" v-on:click="handleToCreateNameScreen()"
      >{{ $t('Checkin.next') }}</v-btn>
    </div>
  </v-container>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    step : '2/6',
    form : null,

    // from url params
    isGuest : false,
    userId : null,
    addParams : ""

  }),

  computed: {
    getGuestCreateNameScreenUrl(){
      return 'guest-checkin-create-name'
    },

    getGuestUploadPassportScreenUrl(){
      return 'guest-upload-passport'
    },

  },

  created () {
    this.fetchGuest()
  },

  mounted(){
  },

  methods: {
    handleToCreateNameScreen(){
      this.$router.push(this.getGuestCreateNameScreenUrl)
    },

    fetchGuest(){
      this.form = this.$store.state.guest
    },
  }
}
</script>

<style lang="scss">
.pw-title{
  font-weight: 400 !important;
  font-size: 60px !important;
  color: rgb(51 72 86) !important;
  text-align: center !important;
}

.next-screen-btn {
  background-color: rgb(97 110 126) !important;
  color: #fff;
  height: 43px;
}
.next-screen-btn span {
  font-weight : bold;
}
</style>
