<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    class="main-layout_content bts-class-pc"
  >
    <v-layout justify-center wrap>
      <v-flex>
        <div style="padding-bottom: 5rem">
          <div class="main_content d-flex flex-column">
            <img
              src="../../assets/img/icon-close.png"
              @click="_iconClick"
              class="align-self-end ic-close"
            />
            <v-flex>
              <h3 class="main_title">
                {{ $t("Checkin.please-enter-nick-name") }}
              </h3>

              <p class="sub-title mt-3">
                {{ $t("Checkin.nick-name-description") }}
              </p>
            </v-flex>
            <slot name="sheetpc"></slot>
          </div>
        </div>
        <div class="main_btn">
          <v-btn class="btn-custom btn-custom-default" @click="_submitOne">
            <slot style="color: #616e7e !important">{{ buttonTextOne }} </slot>
          </v-btn>
          <v-btn
            :disabled="disabledTwo"
            :class="[
              'btn-custom',
              !disabledTwo ? 'btn-submit-green' : 'btn-submit-green-disabled',
            ]"
            @click="_submitTwo"
            :loading="loading"
          >
            {{ buttonTextTwo }}
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "NickNamePc",
  props: {
    disabledOne: {
      type: Boolean,
      default: () => false,
    },
    disabledTwo: {
      type: Boolean,
      default: () => false,
    },

    buttonTextOne: {
      type: String,
      default: () => "",
    },
    buttonTextTwo: {
      type: String,
      default: () => "",
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    _iconClick() {
      this.$emit("icon-close");
    },

    _submitOne() {
      this.$emit("submit-one");
    },

    _submitTwo() {
      this.$emit("submit-two");
    },
  },
};
</script>

<style scoped lang="scss">
.bts-class-pc {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  margin-top: 4rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: rgb(0 0 0 / 50%) 0px -6px 6px -6px;
  background-color: white;
  .ic-close {
    cursor: pointer;
    padding-bottom: 1rem;
  }
  .content-bts {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: calc(812px - 139px);
  }

  .btn-submit-green {
    width: 100%;
    background-color: rgb(97 110 126) !important;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #fff;
  }

  .btn-submit-green-disabled {
    width: 100%;
    background-color: #e0e0e0 !important;
    color: #f3f3f3;
    height: 43px !important;
  }

  .btn-custom-default {
    min-height: 48px !important;
    height: auto !important;
    padding: 3px 8px !important;
    margin-bottom: 1.5rem !important;
  }
}
</style>
