<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    class="main-layout_content_second"
  >
    <v-layout justify-center wrap>
      <v-flex xs12>
        <v-flex xs12 class="pt-0">
          <h3 class="main_title">
            {{ $t("Checkin.please-enter-birth-day") }}
          </h3>

          <v-form
            v-model="valid"
            v-if="form"
            class="mt-5 mb-30 pt-5 main_content checkin_form"
            @submit.prevent.stop="handleSubmitBirthday()"
          >
            <div class="xs12 flex-row d-flex mx-2">
              <!--              <v-text-field-->
              <!--                v-model="currentForm.year"-->
              <!--                required-->
              <!--                :rules="yeardVerifyRules"-->
              <!--                class="sub-feature-text"-->
              <!--                style="flex: 2.5"-->
              <!--                validate-on-blur-->
              <!--                placeholder="YYYY"-->
              <!--              >-->
              <!--                <template v-slot:label>-->
              <!--                  <span class="custom-label-input">{{-->
              <!--                    $t("Checkin.year")-->
              <!--                  }}</span>-->
              <!--                </template>-->
              <!--                <template v-slot:message>-->
              <!--                  <span style="font-size: 10px">{{ getYearError }}</span>-->
              <!--                </template></v-text-field-->
              <!--              >-->
              <!--              <v-text-field-->
              <!--                v-model="currentForm.month"-->
              <!--                required-->
              <!--                :rules="monthVerifyRules"-->
              <!--                class="sub-feature-text mx-5"-->
              <!--                style="flex: 1.5"-->
              <!--                validate-on-blur-->
              <!--                placeholder="MM"-->
              <!--              >-->
              <!--                <template v-slot:label>-->
              <!--                  <span class="custom-label-input">{{-->
              <!--                    $t("Checkin.month")-->
              <!--                  }}</span>-->
              <!--                </template>-->
              <!--                <template v-slot:message>-->
              <!--                  <span style="font-size: 10px">{{ getMonthError }}</span>-->
              <!--                </template></v-text-field-->
              <!--              >-->
              <!--              <v-text-field-->
              <!--                v-model="currentForm.date"-->
              <!--                required-->
              <!--                :rules="dateVerifyRules"-->
              <!--                class="sub-feature-text"-->
              <!--                style="flex: 1.5"-->
              <!--                validate-on-blur-->
              <!--                placeholder="DD"-->
              <!--              >-->
              <!--                <template v-slot:label>-->
              <!--                  <span class="custom-label-input">{{-->
              <!--                    $t("Checkin.date")-->
              <!--                  }}</span>-->
              <!--                </template>-->
              <!--                <template v-slot:message>-->
              <!--                  <span style="font-size: 10px">{{ getDayError }}</span>-->
              <!--                </template>-->
              <!--              </v-text-field>-->
              <div class="select-wrapper" style="flex: 2.5">
                <label class="custom-label-input" for="yearDaihyo">{{
                  $t("Checkin.year")
                }}</label>
                <select
                  class="custom-label-input"
                  id="yearDaihyo"
                  v-model="guestBirthday.year"
                >
                  <option
                    :key="index"
                    v-for="(item, index) in yearList"
                    :value="item.toString()"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>

              <div class="select-wrapper mx-5" style="flex: 1.5">
                <label class="custom-label-input" for="monthDaihyo">{{
                  $t("Checkin.month")
                }}</label>
                <select
                  @input="onSelect"
                  class="custom-label-input"
                  id="monthDaihyo"
                  v-model="guestBirthday.month"
                >
                  <option
                    :key="index"
                    v-for="(item, index) in monthList"
                    :value="item.toString()"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>

              <div class="select-wrapper" style="flex: 1.5">
                <label class="custom-label-input" for="dayDaihyo">{{
                  $t("Checkin.date")
                }}</label>
                <select
                  class="custom-label-input"
                  id="dayDaihyo"
                  v-model="guestBirthday.date"
                >
                  <option
                    :key="index"
                    v-for="(item, index) in dateList"
                    :value="item.toString()"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
          </v-form>
        </v-flex>
        <div class="main-btn" style="margin-top: 5rem">
          <v-btn
            @click="handleSubmitBirthday()"
            v-bind:disabled="getDisable()"
            v-bind:class="
              !getDisable() ? 'btn-submit-green' : 'btn-submit-green-disabled'
            "
            class="btn-right btn-custom"
            >{{ $t("Checkin.next") }}</v-btn
          >
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from "moment"
import { mapGetters } from "vuex"

export default {
  name: "GuestBirthDay",
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // form: null,
      currentForm: { year: "", month: "", date: "" },
      valid: false,
      isGuest: false,
      userId: null,
      disableSubmit: false,
    }
  },

  computed: {
    ...mapGetters(["guestBirthday"]),

    yeardVerifyRules() {
      return [
        (v) => !!v || this.$t("Checkin.the-following-field-is-required"),

        (v) =>
          (v && this.checkSpecificKey(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
        (v) =>
          (v && this.checkFourCharacters(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
        (v) =>
          (v && this.checkYear(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
      ]
    },
    monthVerifyRules() {
      return [
        (v) => !!v || this.$t("Checkin.the-following-field-is-required"),

        (v) =>
          (v && this.checkSpecificKey(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
        (v) =>
          (v && this.checkTwoCharacterMonth(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
      ]
    },
    dateVerifyRules() {
      return [
        (v) => !!v || this.$t("Checkin.the-following-field-is-required"),

        (v) =>
          (v && this.checkSpecificKey(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
        (v) =>
          (v && this.checkTwoCharacterDate(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
      ]
    },

    getYearError() {
      if (!this.currentForm.year) {
        return this.$t("Checkin.the-following-field-is-required")
      } else if (
        this.currentForm.year &&
        (this.checkSpecificKey(this.currentForm.year) ||
          this.checkFourCharacters(this.currentForm.year) ||
          this.checkYear(this.currentForm.year))
      ) {
        return this.$t("Checkin.the-entered-characters-format-is-not-supported")
      } else {
        return null
      }
    },

    getMonthError() {
      if (!this.currentForm.month) {
        return this.$t("Checkin.the-following-field-is-required")
      } else if (
        this.currentForm.month &&
        (this.checkSpecificKey(this.currentForm.month) ||
          this.checkTwoCharacterMonth(this.currentForm.month))
      ) {
        return this.$t("Checkin.the-entered-characters-format-is-not-supported")
      } else {
        return null
      }
    },

    getDayError() {
      if (!this.currentForm.date) {
        return this.$t("Checkin.the-following-field-is-required")
      } else if (
        this.currentForm.date &&
        (this.checkSpecificKey(this.currentForm.date) ||
          this.checkTwoCharacterDate(this.currentForm.date))
      ) {
        return this.$t("Checkin.the-entered-characters-format-is-not-supported")
      } else {
        return null
      }
    },

    yearList() {
      const currentYear = new Date().getFullYear(),
        years = []
      let startYear = currentYear - 119
      while (startYear <= currentYear) {
        years.push(startYear++)
      }

      return years
    },

    monthList() {
      return [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ]
    },

    dateList() {
      let ar = []
      let start = moment(
        this.currentForm.year + "-" + this.currentForm.month,
        "YYYY-MM"
      )
      for (
        let end = moment(start).add(1, "month");
        start.isBefore(end);
        start.add(1, "day")
      ) {
        ar.push(start.format("DD"))
      }
      return ar
    },
  },
  watch: {
    guestBirthday() {
      this.currentForm = this.guestBirthday
    },
  },
  mounted() {
    this.fetchGuestBirthDay()
  },
  methods: {
    onSelect(e) {
      this.currentForm.month = e.target.value
    },
    checkSpecificKey(str) {
      var specialKey =
        "[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'"
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false
        }
      }
      return true
    },
    checkYear(str) {
      const current_year = new Date().getFullYear()
      return str < 1920 || str > current_year ? false : true
    },
    checkFourCharacters(str) {
      return /^[0-9]+$/.test(str)
    },
    checkTwoCharacterMonth(str) {
      return /^(0[1-9]|1[0-2])+$/.test(str)
    },
    checkTwoCharacterDate(str) {
      return /^(0[1-9]|[12][0-9]|3[01])+$/.test(str)
    },

    handleSubmitBirthday() {
      if (this.valid) {
        this.submitBirthdayForGuest()
      }
    },

    submitBirthdayForGuest() {
      let birthDay = `${this.guestBirthday.year}-${this.guestBirthday.month}-${this.guestBirthday.date}`
      this.$store
        .dispatch("guestUpdateReservation", {
          ...this.form,
          birthDate: birthDay,
        })
        .then((res) => {
          // this.$router.push(this.getGuestEnterCreateAddressScreenUrl)
          this.$store.commit("setGuestCurrent", {
            ...this.$store.state.guest,
            birthDate: res.data.birthDate,
          })
          this.$emit("next-step")
        })
        .catch((err) => {
          console.log(err.response)
        })
    },

    fetchGuestBirthDay() {
      // this.form = this.$store.state.guest;
      this.currentForm = this.guestBirthday
    },

    getDisable() {
      return (
        !this.guestBirthday.date ||
        !this.guestBirthday.month ||
        !this.guestBirthday.year
      )
    },
  },
}
</script>

<style lang="scss">
.custom-label-input {
  font-size: 11px;
  color: #424242;
}

.select-wrapper {
  display: flex;
  flex-direction: column;
  //overflow-y: auto;
  select {
    //margin-top: 20px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: #ffffff;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 8px 0;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }
  select::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

.birth-form {
  position: relative;
  max-height: 70px;
  z-index: 9999;
}
</style>
