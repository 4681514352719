<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    class="main-layout_content_second"
  >
    <v-layout justify-center wrap>
      <!-- inline edit table -->
      <v-flex xs12>
        <v-flex xs12 class="pt-0">
          <v-form
            ref="form"
            v-model="valid"
            v-if="form"
            class="mt-5 mb-30 pt-5 main_content checkin_form"
            @submit.prevent.stop="handleSubmit()"
          >
            <template v-if="askPrev">
              <h3 class="main_title">
                {{ $t("Checkin.please-enter-the-previous-stay") }}
              </h3>
              <v-text-field
                v-model="form.previousPlaceOfStay"
                required
                class="purple-input"
                :label="$t('Checkin.previous-stay')"
              />
            </template>

            <template v-if="askNext">
              <h3 class="main_title">
                {{ $t("Checkin.please-enter-the-next-stay") }}
              </h3>
              <v-text-field
                v-model="form.nextDestination"
                required
                class="purple-input"
                :label="$t('Checkin.next-stay')"
              />
            </template>
          </v-form>
        </v-flex>

        <div>
          <v-btn
            v-bind:disabled="!valid"
            v-bind:class="
              valid ? 'btn-submit-green' : 'btn-submit-green-disabled'
            "
            class="btn-right btn-custom"
            v-on:click="handleSubmit()"
            >{{ $t("Checkin.next") }}</v-btn
          >
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "CreatePrevAndNextPlace",
  props: {
    form: {
      type: Object,
      default: () => {},
      required: true,
    },
    userId: {
      type: String,
      default: () => null,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    inputField: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    addParams: "",
  }),

  computed: {
    ...mapState({
      currentUser: (state) => state.currentUser,
      currentStep: (state) => state.step,
    }),
    askPrev() {
      return this.inputField.previousPlaceOfStay
    },
    askNext() {
      return this.inputField.nextDestination
    },
  },
  watch: {
    visible() {
      if (this.visible) {
        this.$refs.form.resetValidation()
      }
    },
  },

  methods: {
    handleSubmit() {
      if (this.valid) {
        const newInfo = () => ({
          ...this.form,
          previousPlaceOfStay: this.form.previousPlaceOfStay,
          nextDestination: this.form.nextDestination,
        })
        this.$store
          .dispatch("updateReservation", newInfo())
          .then(() => {
            if (this.isGuest) this.$store.commit("update_guest", newInfo())
            else this.$store.commit("update_user", newInfo())

            this.$store.commit("setCurrentUser", newInfo())
            // this.$router.push(
            //     this.getEnterCreateAddressScreenUrl + this.addParams
            // );
            this.$emit("next-step")
          })
          .catch((err) => {
            console.log(err.response)
          })
      }
    },
  },
}
</script>

<style lang="scss">
.pw-title {
  font-weight: 400 !important;
  font-size: 60px !important;
  color: rgb(51 72 86) !important;
  text-align: center !important;
}

.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  color: #fff !important;
  height: 43px !important;
}

.btn-submit-green-disabled {
  width: 100%;
  background-color: #e0e0e0 !important;
  color: #f3f3f3;
  height: 43px !important;
}
</style>
