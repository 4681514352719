// https://vuex.vuejs.org/en/getters.html

// authorized lets you know if the token is true or not
export default {
  bookingId(state) {
    return state.bookingId
  },
  reserve_guests(state) {
    return state.reserve_guests
  },
  reserve_user(state) {
    return state.reserve_user
  },
  reserve(state) {
    return state.reserve
  },
  settings(state) {
    return state.settings
  },
  lang(state) {
    return state.lang
  },
  guest(state) {
    return state.guest
  },
  guestReserve(state) {
    return state.guestReserve
  },

  getBirthDay(state) {
    if (state.currentUser.birthDate) {
      const [year, month, day] = state.currentUser.birthDate.split("-")
      day < 10 ? `${parseInt(day).toFixed(0)}` : `${day}`
      return { year: year, month: month, date: day }
    }
    return { year: "", month: "", date: "" }
  },

  guestBirthday(state) {
    if (state.guest && state.guest.birthDate) {
      const [year, month, day] = state.guest.birthDate.split("-")
      day < 10 ? `${parseInt(day).toFixed(0)}` : `${day}`
      return { year: year, month: month, date: day }
    }
    return { year: "", month: "", date: "" }
  },

  notOtherThanOwner(state) {
    return state.settings.inputField?.notOtherThanOwner
  },

  // created for showing "2人目のお名前を入力してください" but not being used because
  // of the difficulty supporting multi language.
  // imagine "Please enter the second guest's name"
  getGuestIndex(state, getters) {
    const personId = state.currentUser.id
    const index = getters.reserve_guests.findIndex(
      (item) => item.id === personId
    )
    return index
  },
}
