<template>
  <v-app>
    <core-multi-lang />
    <v-container fill-height fluid grid-list-xl class="main-layout_inner layout-h">
      <v-layout justify-center wrap>
        <!-- inline edit table -->
        <v-flex xs12 class="pt-0">
          <v-flex xs12>
            <h3 class="main_title">
              {{
                $t(
                  "Checkin.resetting-your-password-will-delete-all-the-information-you"
                )
              }}
            </h3>

            <p class="error--text" v-if="apiMessage !== null">{{ apiMessage }}</p>
          </v-flex>

        </v-flex>
      </v-layout>
      <div class="main_btn">
            <v-btn
              block
              class="btn-custom btn-custom-black mb"
              v-on:click="handleBackToChangePassword()"
              >{{ $t("Checkin.return-to-input-screen") }}</v-btn
            >

            <v-btn
              block
              class="btn-custom btn-custom-main"
              v-on:click="handleSubmitForgotPassword()"
              >{{ $t("Checkin.reset") }}</v-btn
            >
          </div>
    </v-container>
  </v-app>
</template>

<style lang="scss">

</style>

<script>
export default {
  data: () => ({
    userId: null,
    rsvId: null,
    addParams: "",
    password : "",
    apiMessage : null
  }),

  computed: {
    changePasswordScreenUrl() {
      return "change-password";
    },
    getEnterCheckinLanguageScreenUrl() {
      return "CheckinLanguage";
    },
  },

  created() {
    
  },

  mounted() {
    this.fetchGuestId();
  },

  methods: {
    fetchGuestId() {
      const userId = this.$store.state.guestReserve.userId;
      const rsvId = this.$store.state.guestReserve.rsvId;

      this.userId = userId
      this.rsvId = rsvId

      if (userId && rsvId) {
        this.addParams =  '/' + rsvId + "?userId=" + userId
      }else{
        this.$router.push('confirm');
      }
    },
    handleBackToChangePassword() {
      this.$router.push(this.changePasswordScreenUrl + this.addParams);
    },

    handleSubmitForgotPassword() {
      this.$store
        .dispatch("personReset", { id: this.userId })
        .then(res => {
          this.password = res.data.password
          console.log(res)
          this.handleSubmitVerifyPassword()
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleSubmitVerifyPassword() {
      this.$store
              .dispatch("personLogin", {
                id: this.userId,
                rsvId: this.rsvId,
                password: this.password,
              })
              .then(() => {
                this.$router.push('/guest-upload-passport');
              })
              .catch((err) => {
                if (err.response.status == 422) {
                  this.apiMessage = err.response.data.global[0];
                }else{
                  this.apiMessage = err.response.data.error;
                }
                console.log(err.response);
              });
    },
  },
};
</script>

<style lang="scss"></style>
