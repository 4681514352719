<template>
  <v-app>
    <core-multi-lang />
    <v-container fill-height fluid grid-list-xl class="main-layout_inner">
      <v-layout justify-center wrap>
        <!-- inline edit table -->
        <v-flex xs12 class="pt-xs-0 pt-0">
          <v-flex>
            <h4 class="main_title">{{ $t("Checkin.intro2") }}</h4>
            <h4 class="main_title">
              {{ $t("Checkin.please-start-the-pre-check-in-system") }}
            </h4>
            <p class="message-desc">
              {{ $t("Checkin.new-intro").replace("%h%", getName) }}
            </p>
            <p class="message-desc mt-3">{{ $t("Checkin.second-intro") }}</p>
          </v-flex>
        </v-flex>
      </v-layout>
      <div class="main_btn">
        <v-btn
          class="btn-custom"
          v-bind:disabled="!valid"
          v-bind:class="
            valid ? 'btn-submit-green' : 'btn-submit-green-disabled'
          "
          v-on:click="handleNextToEnterInformationScreen()"
        >
          {{ $t("Checkin.next") }}
        </v-btn>
      </div>
    </v-container>
  </v-app>
</template>

<style scoped lang="scss">
.message-heading {
  color: rgb(66 66 66) !important;
  font-size: 19.5px !important;
  font-weight: 600 !important;
}
.message-desc {
  color: rgb(91 91 91) !important;
  font-size: 14px !important;
  margin-bottom: 30px;
}
.message-label {
  color: rgb(97 110 126);
  font-size: 17px !important;
}
.message-infor {
  color: rgb(112 124 138);
  font-size: 14px !important;
  margin-bottom: 0;
}

.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}
</style>
<script>
import StringUtil from "@/shared/StringUtil"
import { mapGetters, mapState } from "vuex"
export default {
  props: {
    nextRoute: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    valid: true,
    userId: null,
    isLogin: false,
    rsvId: null,
  }),

  computed: {
    getEnterInformationScreenUrl() {
      return "GuestCheckinUploadPassport"
    },
    ...mapGetters(["reserve_guests", "reserve_user", "reserve", "bookingId"]),
    ...mapState({
      settings: (state) => state.settings,
    }),

    getName() {
      return this.settings ? this.settings.name : ""
    },
  },
  watch: {},

  created() {
    // this.fetchReserve()
  },

  mounted() {
    this.userId = this.$route.query.userId
    this.isLogin = this.$route.query.isLogin === "0" ? false : true
    this.rsvId = this.bookingId
    if (!this.isLogin) {
      this.handleSubmitForgotPassword()
    }
  },

  methods: {
    handleNextToEnterInformationScreen() {
      this.$router.push("/guest-upload-passport")
    },

    fetchReserve() {
      this.$store
        .dispatch("getReserved")
        .then((res) => {
          this.valid = true
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleSubmitForgotPassword() {
      this.$store
        .dispatch("personReset", { id: this.userId })
        .then((res) => {
          this.password = res.data.password
          console.log(res)
          this.handleSubmitVerifyPasswordNew()
        })
        .catch((err) => {
          console.log(err)
        })
    },

    handleSubmitVerifyPasswordNew() {
      this.$store
        .dispatch("personLogin", {
          id: this.userId,
          rsvId: this.rsvId,
          password: this.password,
        })
        .then(() => {
          // this.$router.push('/guest-upload-passport');
        })
        .catch((err) => {
          if (err.response.status == 422) {
            this.apiMessage = err.response.data.global[0]
          } else {
            this.apiMessage = err.response.data.error
          }
          console.log(err.response)
        })
    },

    dateToString(date) {
      return StringUtil.dateToString(date)
    },
  },
}
</script>

<style lang="scss"></style>
