<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    class="main-layout_content_second"
  >
    <v-layout justify-center wrap>
      <!-- inline edit table -->
      <v-flex xs12>
        <v-flex xs12 class="pt-0">
          <h3 class="main_title">
            {{ $t("Checkin.please-enter-the-phone-number") }}
          </h3>
          <v-form
            ref="form"
            v-model="valid"
            v-if="form"
            class="mt-5 mb-30 pt-5 main_content checkin_form"
            @submit.prevent.stop="handleSubmitPhone()"
          >
            <v-text-field
              v-model="form.phone"
              required
              :rules="phoneVerifyRules"
              class="purple-input"
              :label="$t('Checkin.phone-number')"
            />
          </v-form>
        </v-flex>

        <div>
          <v-btn
            v-bind:disabled="!valid"
            v-bind:class="
              valid ? 'btn-submit-green' : 'btn-submit-green-disabled'
            "
            class="btn-right btn-custom"
            v-on:click="handleSubmitPhone()"
            >{{ $t("Checkin.next") }}</v-btn
          >
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "GuestPhone",
  props: {
    form: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    valid: false,
    addParams: "",
  }),

  computed: {
    ...mapState(["reserve_guests"]),
    phoneVerifyRules() {
      return [
        (v) => !!v || this.$t("Checkin.the-following-field-is-required"),
        (v) =>
          (v && /^\d{10,13}$/.test(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
      ]
    },
  },

  methods: {
    handleSubmitPhone() {
      if (this.valid) {
        const newInfo = () => ({
          ...this.form,
          phone: this.form.phone,
        })
        this.$store
          .dispatch("guestUpdateReservation", newInfo())
          .then(() => {
            this.$store.commit("setGuestCurrent", newInfo())
            this.loading = false
            this.$emit("next-step")
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
  },
}
</script>

<style lang="scss">
.pw-title {
  font-weight: 400 !important;
  font-size: 60px !important;
  color: rgb(51 72 86) !important;
  text-align: center !important;
}

.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  color: #fff !important;
  height: 43px !important;
}

.btn-submit-green-disabled {
  width: 100%;
  background-color: #e0e0e0 !important;
  color: #f3f3f3;
  height: 43px !important;
}
</style>
