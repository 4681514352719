<template>
  <v-app>
    <div v-if="sizePc && sheetPc">
      <NickNamePc
        :button-text-one="$t('Checkin.next-not-submit')"
        :button-text-two="$t('Checkin.next')"
        @submit-one="sheetPc = false"
        @submit-two="submitNickNameForGuest"
        :disabled-two="false"
        :loading="submitting"
        @icon-close="onIconClose"
      >
        <template v-slot:sheetpc>
          <v-form
            ref="nickForm"
            v-model="validNickName"
            class="mb-5 checkin_form main_content"
          >
            <v-text-field
              v-model="currentGuest.nickName"
              required
              :counter="10"
              :rules="nameVerifyRules"
              class="purple-input"
            />
          </v-form>
        </template>
      </NickNamePc>
    </div>
    <div v-else>
      <core-multi-lang v-show="!sheet" />
      <v-container fill-height fluid grid-list-xl class="main-layout_content">
        <v-layout justify-center wrap>
          <!-- inline edit table -->
          <v-flex xs12>
            <div class="main_content">
              <h4 class="main_title">
                {{
                  $t(
                    "Checkin.you-are-required-to-enter-information-about-all-the-guests"
                  )
                }}
              </h4>
              <template v-if="$store.getters.notOtherThanOwner">
                <p class="sub-title mt-5 mb-0 color-accent">
                  {{ $t("Checkin.the-info-easy") }}
                </p>
              </template>
              <template v-else>
                <p class="sub-title mt-5 mb-0">
                  {{ $t("Checkin.the-info") }}
                </p>

                <p class="sub-title my-0">
                  {{ $t("Checkin.sub-info1") }}
                </p>

                <p class="sub-title pb-5 mt-0">
                  {{ $t("Checkin.sub-info2") }}
                </p>
              </template>
              <hr class="border-bottom mt-20" />

              <div class="message_item">
                <h5 class="message_title">
                  {{ $t("Checkin.representative") }}
                </h5>
                <span v-if="reserveUserEntered">{{
                  $t("Checkin.entered")
                }}</span>
                <span v-else>{{ $t("Checkin.not-entered") }}</span>

                <div class="text-right">
                  <button
                    class="enter-infor-btn btn-custom"
                    v-on:click="handleToUploadPassportScreen(false)"
                  >
                    {{ $t("Checkin.enter") }}
                  </button>
                </div>
                <hr class="border-bottom mt-20" />
              </div>

              <PersonCard
                :person-item="personItem"
                :key="personIndex"
                :person-index="personIndex"
                v-for="(personItem, personIndex) in reserve_guests"
                :reserve="reserve"
                :guestLoginScreenUrl="guestLoginScreenUrl"
                :isPc="isPc"
                show-add-nick-name
                is-finish-page
                @share-mobile="
                  handleToShareMobile({
                    title: settings.name,
                    url:
                      guestLoginScreenUrl +
                      '/' +
                      reserve.booking.id +
                      '?userId=' +
                      personItem.id +
                      '&nickName=' +
                      personItem.nickName,
                  })
                "
                @upload-passport="
                  handleToUploadPassportScreen(true, personItem.id)
                "
                @edit-name="selectGuest(personItem)"
              />
            </div>

            <div v-show="false" class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="
                      min-height: 48px !important;
                      height: auto !important;
                      padding: 3px 8px !important;
                    "
                    class="btn-custom btn-custom-default"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <slot style="color: #616e7e !important"
                      >{{ $t("Checkin.change-no-of-guests") }}
                    </slot>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title
                      class="text-center font-weight-bold"
                      v-on:click="handleAddOneGuest()"
                      >{{ $t("Checkin.add-guest").replace("%n%", 1) }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-bind:key="personIndex"
                    v-for="(personItem, personIndex) in reserve_guests"
                  >
                    <v-list-item-title
                      class="red--text text-center font-weight-bold"
                      v-on:click="handleRemoveMember(personItem.id)"
                      >{{
                        $t("Checkin.delete-guest").replace(
                          "%n%",
                          personIndex + 2
                        )
                      }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <div class="main_content">
              <core-footer />
            </div>
          </v-flex>
        </v-layout>
      </v-container>
      <MyBottomSheet
        :dialog="sheet"
        @icon-close="onIconClose"
        :button-text-one="$t('Checkin.next-not-submit')"
        :button-text-two="$t('Checkin.next')"
        @submit-one="sheet = false"
        @submit-two="submitNickNameForGuest"
        :disabled-two="false"
        :loading="submitting"
      >
        <template v-slot:bodysheet>
          <v-form
            ref="nickForm"
            v-model="validNickName"
            class="mb-5 checkin_form main_content"
          >
            <v-text-field
              v-model="currentGuest.nickName"
              required
              :counter="10"
              :rules="nameVerifyRules"
              class="purple-input"
            />
          </v-form>
        </template>
      </MyBottomSheet>
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import PersonCard from "@/components/card/PersonCard"
export default {
  components: {
    MyBottomSheet: () => import("@/components/popup/MyBottomSheet"),
    PersonCard,
    NickNamePc: () => import("@/components/popup/NickNamePc"),
  },
  data: () => ({
    guests: [],
    isPc: true,
    supportedShare: false,
    sheet: false,
    validNickName: false,
    form: null,
    currentGuest: {},
    submitting: false,
    sheetPc: false,
  }),

  computed: {
    ...mapState({
      breakWidth: (state) => state.breakWidth,
    }),
    reserveUserEntered() {
      const user = this.reserve_user
      console.log({ user })
      return user && user.name && user.address
    },
    dataFinished() {
      const checkUser = this.reserveUserEntered
      let checkGuest = false

      for (let i = 0; i < this.reserve_guests.length; i++) {
        if (this.reserve_guests[i].name && this.reserve_guests[i].address) {
          checkGuest = true
        } else {
          checkGuest = false
        }
      }

      return checkUser || checkGuest ? true : false
    },

    getEnterFinishedScreenUrl() {
      return "finished"
    },

    getUploadPassportScreenUrl() {
      return "upload-passport"
    },

    guestLoginScreenUrl() {
      return (
        window.location.protocol +
        "//" +
        window.location.host +
        "/change-password"
      )
    },
    nameVerifyRules() {
      return [
        (v) => !!v || this.$t("Checkin.the-following-field-is-required"),
        (v) =>
          (v && v.length <= 10) ||
          this.$t("Checkin.please-enter-your-name-maximum-of-10-characters"),
        (v) =>
          (v && this.checkSpecificKey(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
      ]
    },
    ...mapGetters(["reserve", "reserve_guests", "reserve_user", "settings"]),

    sizePc() {
      return this.breakWidth >= 600
    },
  },
  watch: {
    currentGuest() {
      console.log(this.currentGuest)
    },
  },
  async created() {},

  async mounted() {
    await this.fetchReserve()

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isPc = false
      this.supportedShare = "share" in navigator
    }
    this.$store.commit("setCurrentUser", {})
    // console.log('user',Object.values(this.reserve_user))
  },

  methods: {
    checkIsFinished() {
      if (this.dataFinished) {
        this.$router.push("/finished")
      }
    },

    fetchReserve() {
      this.$store
        .dispatch("getReserved")
        .then(() => {
          this.valid = true
          this.checkIsFinished()
        })
        .catch((err) => {
          console.log(err)
        })
    },

    handleToUploadPassportScreen(isGuest = false, personId = false) {
      let addParam = "?isGuest=" + isGuest

      if (personId) {
        addParam = addParam + "&userId=" + personId
        const filterItem = this.reserve_guests.find(
          (item) => item.id === personId
        )
        this.$store.commit("setCurrentUser", filterItem)
      } else {
        this.$store.commit("setCurrentUser", this.reserve_user)
      }
      this.$router.push(this.getUploadPassportScreenUrl + addParam)
    },

    async handleRemoveMember(personIndex) {
      let el = document.getElementById("guest-item-" + personIndex)
      if (el) {
        el.animate({ opacity: "0" }, 500, function() {
          el.animate({ height: "0px" }, 600, function() {
            el.remove()
          })
        })
      }

      await this.$store
        .dispatch("deletePerson", { id: personIndex })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })

      await this.fetchReserve()
      this.checkIsFinished()
    },

    async handleAddOneGuest() {
      await this.$store
        .dispatch("addReservation")
        .then(() => {})
        .catch((err) => {
          console.log(err)
        })

      await this.fetchReserve()
    },

    handleToShareMobile(dataObj) {
      try {
        navigator.share(dataObj)
      } catch {
        console.log("Open: ", dataObj.url)
      }
    },

    checkSpecificKey(str) {
      const specialKey =
        "[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'"
      for (let i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false
        }
      }
      return true
    },

    selectGuest(item) {
      this.currentGuest = Object.assign(
        {},
        this.currentGuest,
        JSON.parse(JSON.stringify(item))
      )
      if (this.sizePc) {
        this.sheetPc = true
      } else {
        this.sheet = true
      }
    },

    submitNickNameForGuest() {
      if (this.validNickName) {
        this.submitting = true
        this.$store
          .dispatch("updateReservation", this.currentGuest)
          .then(() => {
            if (this.sizePc) {
              this.sheetPc = false
            } else {
              this.sheet = false
            }

            const editedGuest = this.reserve_guests.find(
              (item) => item.id === this.currentGuest.id
            )
            if (editedGuest) {
              editedGuest.nickName = this.currentGuest.nickName
            }
          })
          .catch((err) => {
            console.log(err.response)
          })
          .finally(() => (this.submitting = false))
      } else {
        if (this.sizePc) {
          this.sheetPc = false
        } else {
          this.sheet = false
        }

        this.$refs.nickForm.resetValidation()
      }
    },

    onIconClose() {
      this.$refs.nickForm.resetValidation()
      if (this.sizePc) {
        this.sheetPc = false
      } else {
        this.sheet = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.enter-infor-btn.btn-custom {
  background: rgb(97, 110, 126);
  color: rgb(255, 255, 255);
  width: 50%;
  background-position: 15px center !important;
}

.message-heading {
  color: rgb(66 66 66) !important;
  font-size: 19.5px !important;
  font-weight: 600 !important;
  margin-bottom: 25px;
}
.message_item {
  margin-top: 16px;
}
.message_title {
  font-size: 17px;
  color: #424242;
  margin-bottom: 10px;
}
.message-desc {
  color: rgb(91 91 91) !important;
  font-size: 14px !important;
}

.btn-submit-green span {
  font-weight: bold;
}
.v-menu__content {
  background-color: #ffffff;
  width: 70%;
  max-height: 150px;
  min-width: auto !important;
  left: 50% !important;
  transform: translate(-50%, -100px) !important;
}

@media screen and (min-width: 600px) {
  .show-mobile {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .show-pc {
    display: none;
  }
}
</style>
