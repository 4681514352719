<template>
  <v-container fill-height fluid grid-list-xl class="main-layout_content_second">
      <v-layout justify-center wrap>
        <!-- inline edit table -->
        <v-flex xs12>
          <v-flex xs12 class="pt-0">
            <h3 class="main_title">
              {{ $t("Checkin.please-sign") }}
            </h3>
            <v-form
              ref="form"
              v-model="valid"
              v-if="form"
              class="mt-5 mb-30 pt-5 main_content checkin_form"
              @submit.prevent.stop="handleSubmit()"
            >
              <guest-signature :file="file" @confirm="confirm" />
            </v-form>
          </v-flex>

          <div>
            <v-btn
              v-bind:disabled="!valid"
              v-bind:class="
                valid ? 'btn-submit-green' : 'btn-submit-green-disabled'
              "
              class="btn-right btn-custom"
              v-on:click="handleSubmit()"
              >{{ $t("Checkin.next") }}</v-btn
            >
          </div>
        </v-flex>
      </v-layout>
    </v-container>
</template>

<script>

import {mapState} from "vuex";
import GuestSignature from '../../Checkins/GuestSignature.vue';

export default {
  components: { GuestSignature },
  name: "CreateSignature",
  props:{
    form:{
      type:Object,
      default:() => {},
      required:true
    },
    isGuest:{
      type:Boolean,
      default:() => false,
      required: true
    },
    userId:{
      type:String,
      default:() => null,
      required:true
    },
    visible: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    valid: false,
    addParams: "",
    newSignature: null,
  }),

  computed: {
    ...mapState({
      currentUser:state => state.currentUser,
      currentStep: state => state.step
    }),
    phoneVerifyRules(){
      return [
        (v) => !!v || this.$t('Checkin.the-following-field-is-required'),
        (v) => (v && v.length <= 40) || this.$t('Checkin.please-enter-your-name-maximum-of-40-characters'),
        (v) => (v && /[0-9\-. +]*/.test(v)) || this.$t('Checkin.the-entered-characters-format-is-not-supported'),
      ]
    },
    file () {
      return this.newSignature
    }
  },
  watch:{
    visible() {
      if(this.visible) {
        this.$refs.form.resetValidation()
      }
    }
  },
  async mounted() {
    const blob = await this.$store.dispatch('downloadSignature', { id: this.currentUser.id })
    this.newSignature = blob;
  },
  methods: {

    handleSubmit() {
      if(this.valid){
        const newInfo = () => ({
          ...this.form,
          signatureImageURL: this.form.signatureImageURL
        })
        this.$store
          .dispatch("updateReservation", newInfo())
          .then(() => {
            if (this.isGuest)
              this.$store.commit("update_guest", newInfo());
            else
              this.$store.commit("update_user", newInfo());

            this.$store.commit('setCurrentUser',newInfo())
            // this.$router.push(
            //     this.getEnterCreateAddressScreenUrl + this.addParams
            // );
            this.$emit('next-step');
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
    async confirm(args) {
      await this.$loading(() => this.$store.dispatch('guestUploadSignature', {
        id: this.currentUser.id,
        file: args.file,
      }))
      this.form.signatureImageURL = 'just uploaded'
      this.newSignature = args.file;
    }
  },
}
</script>

<style lang="scss">
.pw-title {
  font-weight: 400 !important;
  font-size: 60px !important;
  color: rgb(51 72 86) !important;
  text-align: center !important;
}

.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  color: #fff !important;
  height: 43px !important;
}

.btn-submit-green-disabled {
  width: 100%;
  background-color: #e0e0e0 !important;
  color: #f3f3f3;
  height: 43px !important;
}

</style>
