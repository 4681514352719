<template>
    <v-app>
      <core-multi-lang />
      <v-container fill-height fluid grid-list-xl class="main-layout_content">
        <v-layout justify-center wrap>
          <!-- inline edit table -->
          <v-flex xs12>
            <h4 class="main_content main_title">
              {{ $t("Checkin.please-enter-the-password-printed-in-the-e-mail-that-was-sent-to-you") }}
            </h4>

            <v-form v-model="valid" class="mt-5" @submit.prevent.stop="handleSubmitPassword()">
              <div class="mb-30">
                <v-text-field
                  v-model="passwordVerify"
                  class="purple-input center-input"
                  required
                  :rules="passwordVerifyRules"
                />

                <div class="center-input v-text-field__details" v-if="messApi">
                  <div
                    class="v-messages theme--light error--text"
                    role="alert"
                  >
                    <div class="v-messages__wrapper">
                      <div
                        class="v-messages__message message-transition-enter-to"
                      >
                        {{ messApi }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <v-btn
                :disabled="!valid"
                :class="
                  valid ? 'btn-submit-green' : 'btn-submit-green-disabled'
                "
                @click="handleSubmitPassword()"
                class="btn-right btn-custom"
                :loading="loading"
              >
                {{ $t("Checkin.next") }}
              </v-btn>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-app>
</template>

<script>
export default {
  name: "DaihyoSubmitPassword",
  data() {
    return {
      email: null,
      rsvId: null,

      valid: false,
      passwordVerify: "",
      messApi: null,
      showPcConfirm : false,
      loading:false
    }
  },

  computed:{
    passwordVerifyRules(){
      return [
        (v) => !!v || this.$t('Checkin.the-following-field-is-required'),
        (v) => (v && v.length <= 10) || this.$t('Checkin.please-enter-your-password-maximum-of-10-characters'),
        (v) => (this.checkSpecificKey(v)) || this.$t('Checkin.the-entered-characters-format-is-not-supported'),
      ]
    },
  },

  watch:{
    passwordVerify() {
      if(!this.passwordVerify) {
        this.messApi = null;
      }
    }
  },

  created() {
    this.rsvId = this.$route.params.bookingId;
  },

  methods:{
    checkSpecificKey(str) {
      var specialKey = "[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false;
        }
      }
      return true;
    },
    handleSubmitPassword() {
      if(this.valid){
        this.loading = true;
        this.$store
            .dispatch("login", {
              password: this.passwordVerify,
              rsvId: this.rsvId,
            })
            .then(() => {
              this.$router.push('/enter-information');
            })
            .catch((err) => {
              console.log({ err });
              if (err?.response?.status == 422) {
                this.messApi = this.$t('Checkin.invalid-password-please-try-again');
              } else {
                this.messApi = this.$t('Checkin.network-error');
              }
              console.log(err);
            }).finally(() => this.loading = false)
      }
    },
  }
}
</script>

<style scoped>

</style>
