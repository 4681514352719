<template>
  <v-app>
    <div v-if="sizePc && sheetPc">
      <NickNamePc
        :button-text-one="$t('Checkin.next-not-submit')"
        :button-text-two="$t('Checkin.next')"
        @submit-one="sheetPc = false"
        @submit-two="submitNameForGuest"
        :disabled-two="false"
        :loading="submitting"
        @icon-close="onIconClose"
      >
        <template v-slot:sheetpc>
          <v-form
            ref="nickForm"
            v-model="validNickName"
            class="mb-5 checkin_form main_content"
          >
            <v-text-field
              v-model="currentGuest.nickName"
              required
              :counter="10"
              :rules="nameVerifyRules"
              class="purple-input"
            />
          </v-form>
        </template>
      </NickNamePc>
    </div>
    <div v-else>
      <core-multi-lang v-show="!sheet" />
      <v-container fill-height fluid grid-list-xl class="main-layout_content">
        <v-layout justify-center wrap>
          <!-- inline edit table -->
          <v-flex xs12>
            <v-flex xs12 md12 class="py-0">
              <h2 class="main_title">
                {{
                  userFinished && guestFinished
                    ? $t("Checkin.advanced-registration-complete")
                    : $t("Checkin.finished-register")
                }}
              </h2>
            </v-flex>
          </v-flex>

          <v-flex xs12>
            <div class="main_content">
              <div class="message_item">
                <h5 class="message_title">
                  {{ $t("Checkin.representative") }}
                </h5>
                <span v-if="reserve_user.name && reserve_user.address">{{
                  $t("Checkin.entered")
                }}</span>
                <span v-else>{{ $t("Checkin.not-entered") }}</span>

                <div class="text-right">
                  <button
                    class="enter-infor-btn btn-custom"
                    v-on:click="handleToUploadPassportScreen(false)"
                  >
                    {{ $t("Checkin.enter") }}
                  </button>
                </div>
                <hr class="border-bottom mt-20" />
              </div>

              <PersonCard
                :person-item="personItem"
                :key="personIndex"
                :person-index="personIndex"
                v-for="(personItem, personIndex) in reserve_guests"
                :reserve="reserve"
                :guestLoginScreenUrl="guestLoginScreenUrl"
                :isPc="isPc"
                show-add-nick-name
                is-finish-page
                @share-mobile="
                  handleToShareMobile({
                    title: settings.name,
                    url:
                      guestLoginScreenUrl +
                      '/' +
                      reserve.booking.id +
                      '?userId=' +
                      personItem.id +
                      '&nickName=' +
                      personItem.nickName,
                  })
                "
                @upload-passport="
                  handleToUploadPassportScreen(true, personItem.id)
                "
                @edit-name="selectGuest(personItem)"
              />
              <hr class="border-bottom" />
            </div>

            <div v-show="false" class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="
                      min-height: 48px !important;
                      height: auto !important;
                      padding: 3px 8px !important;
                    "
                    class="btn-custom btn-custom-default"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <slot style="color: #616e7e !important"
                      >{{ $t("Checkin.change-no-of-guests") }}
                    </slot>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title
                      class="text-center font-weight-bold"
                      v-on:click="handleAddOneGuest()"
                      >{{ $t("Checkin.add-guest").replace("%n%", 1) }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-bind:key="personIndex"
                    v-for="(personItem, personIndex) in reserve_guests"
                  >
                    <v-list-item-title
                      class="red--text text-center font-weight-bold"
                      v-on:click="handleRemoveMember(personItem.id)"
                      >{{
                        $t("Checkin.delete-guest").replace(
                          "%n%",
                          personIndex + 2
                        )
                      }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <div class="main_content mt-20 px-0">
              <center>
                <p class="mb-0 checkin_qr_txt font-weight-bold">
                  {{ $t("Checkin.qr-code-for-check-in") }}
                </p>

                <vue-qrcode
                  v-if="qrCodeStr"
                  id="qrblock"
                  ref="qrcode"
                  style="width: 70%"
                  :value="qrCodeStr"
                />

                <p
                  style="
                    color: rgb(59 59 59);
                    width: 70%;
                    font-size: 10px !important;
                  "
                >
                  {{
                    $t(
                      "Checkin.scanning-this-qr-code-with-the-tablet-device-at"
                    )
                  }}
                </p>

                <v-btn
                  @click.native="downloadQrCode()"
                  class="qr-code-download-btn"
                  >{{ $t("Checkin.save-image") }}
                </v-btn>

                <v-col
                  class="text-left"
                  style="width: 80%"
                  v-bind:style="{
                    'background-image': 'url(' + '/img/human_icon.png' + ')',
                    'background-repeat': 'no-repeat',
                    'background-position': '0 10px',
                  }"
                >
                  <p style="color: rgb(150 150 150); margin-left: 36px">
                    {{ $t("Checkin.please-note-that-during-check-in") }}
                  </p>
                </v-col>
              </center>
              <hr class="border-bottom" />
              <core-footer />
              <!--              <v-btn-->
              <!--                block-->
              <!--                class="btn-custom btn-custom-main mt-8"-->
              <!--                style="-->
              <!--                  height: auto !important;-->
              <!--                  min-height: 47px !important;-->
              <!--                  padding: 3px 0px !important;-->
              <!--                "-->
              <!--                >{{ $t("Checkin.close-browser") }}</v-btn-->
              <!--              >-->
            </div>
          </v-flex>
        </v-layout>
      </v-container>
      <MyBottomSheet
        :dialog="sheet"
        @icon-close="onIconClose"
        :button-text-one="$t('Checkin.next-not-submit')"
        :button-text-two="$t('Checkin.next')"
        @submit-one="sheet = false"
        @submit-two="submitNameForGuest"
        :disabled-two="false"
        :loading="submitting"
      >
        <template v-slot:bodysheet>
          <v-form
            ref="nickForm"
            v-model="validNickName"
            class="mb-5 checkin_form main_content"
          >
            <v-text-field
              v-model="currentGuest.nickName"
              required
              :counter="10"
              :rules="nameVerifyRules"
              class="purple-input"
            />
          </v-form>
        </template>
      </MyBottomSheet>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex"
import VueQrcode from "vue-qrcode"
import PersonCard from "@/components/card/PersonCard"
export default {
  components: {
    VueQrcode,
    PersonCard,
    MyBottomSheet: () => import("@/components/popup/MyBottomSheet"),
    NickNamePc: () => import("@/components/popup/NickNamePc"),
  },

  data: () => ({
    qrCodeStr: null,
    isPc: true,
    supportedShare: false,
    sheet: false,
    currentGuest: {},
    validNickName: false,
    submitting: false,
    sheetPc: false,
  }),

  computed: {
    dataFinished() {
      let checkUser = false
      let checkGuest = false
      if (this.reserve_user.name && this.reserve_user.address) {
        checkUser = true
      } else {
        checkUser = false
      }

      for (let i = 0; i < this.reserve_guests.length; i++) {
        if (this.reserve_guests[i].name && this.reserve_guests[i].address) {
          checkGuest = true
        } else {
          checkGuest = false
        }
      }

      return checkUser || checkGuest ? true : false
    },

    userFinished() {
      let checkUser = false
      if (this.reserve_user.name && this.reserve_user.address) {
        checkUser = true
      } else {
        checkUser = false
      }
      return checkUser ? true : false
    },
    guestFinished() {
      let checkGuest = false
      for (let i = 0; i < this.reserve_guests.length; i++) {
        if (this.reserve_guests[i].name && this.reserve_guests[i].address) {
          checkGuest = true
        } else {
          checkGuest = false
        }
      }

      return checkGuest ? true : false
    },
    getEnterFinishedScreenUrl() {
      return "checkin-daihyo-intro/finished"
    },

    getUploadPassportScreenUrl() {
      return "upload-passport"
    },

    guestLoginScreenUrl() {
      return (
        window.location.protocol +
        "//" +
        window.location.host +
        "/change-password"
      )
    },
    nameVerifyRules() {
      return [
        (v) => !!v || this.$t("Checkin.the-following-field-is-required"),
        (v) =>
          (v && v.length <= 10) ||
          this.$t("Checkin.please-enter-your-name-maximum-of-10-characters"),
        (v) =>
          (v && this.checkSpecificKey(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
      ]
    },
    ...mapState([
      "reserve",
      "reserve_guests",
      "reserve_user",
      "settings",
      "breakWidth",
    ]),
    sizePc() {
      return this.breakWidth >= 600
    },
    isWebviewAndroid() {
      return navigator.userAgent.includes("wv")
    },

    isWebviewIos() {
      return /MessengerLiteForiOS|Line|Zalo iOS|Instagram/i.test(
        navigator.userAgent
      )
    },
  },

  watch: {},
  created() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isPc = false
      this.supportedShare = "share" in navigator
    }
  },

  async mounted() {
    await this.fetchReserve()
    this.fetchQrCode()
    this.$store.commit("setCurrentUser", {})
  },

  methods: {
    checkIsFinished() {
      if (!this.dataFinished) {
        this.$router.push("/enter-information")
      }
    },

    checkSpecificKey(str) {
      const specialKey =
        "[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'"
      for (let i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false
        }
      }
      return true
    },

    fetchReserve() {
      this.$store
        .dispatch("getReserved")
        .then(() => {
          this.valid = true
          this.checkIsFinished()
        })
        .catch((err) => {
          console.log(err)
        })
    },

    fetchQrCode() {
      let formObj = {
        bookId: this.reserve.booking.id,
        personId: this.reserve.id,
      }
      this.qrCodeStr = JSON.stringify(formObj)
    },

    handleRemoveMember(personIndex) {
      const el = document.getElementById("guest-item-" + personIndex)
      if (el) {
        el.animate({ opacity: "0" }, 500, function() {
          el.animate({ height: "0px" }, 600, function() {
            el.remove()
          })
        })
      }

      this.$store
        .dispatch("deletePerson", { id: personIndex })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    handleAddOneGuest() {
      this.$store
        .dispatch("addReservation")
        .then((res) => {
          console.log(res)
          this.$router.push("/enter-information")
        })
        .catch((err) => {
          console.log(err)
        })
    },

    handleToUploadPassportScreen(isGuest = false, personId = false) {
      let addParam = "?isGuest=" + isGuest

      if (personId) {
        addParam = addParam + "&userId=" + personId
        const filterItem = this.reserve_guests.find(
          (item) => item.id === personId
        )
        this.$store.commit("setCurrentUser", filterItem)
      } else {
        this.$store.commit("setCurrentUser", this.reserve_user)
      }
      this.$router.push(this.getUploadPassportScreenUrl + addParam)
    },

    downloadQrCode() {
      let oQrcode = document.getElementById("qrblock")
      let url = oQrcode.src
      if (this.isWebviewAndroid || this.isWebviewIos) {
        this.$router.push({ path: "/qr", query: { qr: this.qrCodeStr } })
      } else {
        const a = document.createElement("a")
        let event = new MouseEvent("click")
        // Customize the name of the image after downloading
        a.download = "QR code"
        a.href = url
        a.dispatchEvent(event)
      }
    },
    handleToShareMobile(dataObj) {
      try {
        navigator.share(dataObj)
      } catch {
        console.log("Open: ", dataObj.url)
      }
    },
    selectGuest(item) {
      this.currentGuest = Object.assign(
        {},
        this.currentGuest,
        JSON.parse(JSON.stringify(item))
      )
      if (this.sizePc) {
        this.sheetPc = true
      } else {
        this.sheet = true
      }
    },

    submitNameForGuest() {
      if (this.validNickName) {
        this.submitting = true
        this.$store
          .dispatch("updateReservation", this.currentGuest)
          .then(() => {
            this.fetchReserve()
            this.fetchQrCode()
            if (this.sizePc) {
              this.sheetPc = false
            } else {
              this.sheet = false
            }
          })
          .catch((err) => {
            console.log(err.response)
          })
          .finally(() => (this.submitting = false))
      } else {
        if (this.sizePc) {
          this.sheetPc = false
        } else {
          this.sheet = false
        }

        this.$refs.nickForm.resetValidation()
      }
    },

    onIconClose() {
      this.$refs.nickForm.resetValidation()
      if (this.sizePc) {
        this.sheetPc = false
      } else {
        this.sheet = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.enter-infor-btn-disabled {
  line-height: 43px;
  background: rgb(224 225 226);
  color: rgb(251 251 252);
  width: 50%;
  border-radius: 4%;
}

.next-screen-btn {
  background-color: rgb(97 110 126) !important;
  color: #fff;
}

.next-screen-btn span {
  font-weight: bold;
}

.pw-title {
  font-size: 60px !important;
  color: rgb(51 72 86) !important;
  text-align: center !important;
}

.qr-code-download-btn {
  background-color: #1ea6c0 !important;
  border-radius: 999px !important;
  color: #fff !important;
  box-shadow: none !important;
  font-size: 15px !important;
  padding: 0px 25px !important;
  height: 45px !important;
}

.enter-infor-btn.btn-custom {
  background: rgb(97, 110, 126);
  color: rgb(255, 255, 255);
  width: 50%;
  background-position: 15px center !important;
}

.message-heading {
  color: rgb(66 66 66) !important;
  font-size: 19.5px !important;
  font-weight: 600 !important;
  margin-bottom: 25px;
}

.message_item {
  margin-top: 16px;
}

.message_title {
  font-size: 17px;
  color: #424242;
  margin-bottom: 10px;
}

.message-desc {
  color: rgb(91 91 91) !important;
  font-size: 14px !important;
}

.btn-submit-green span {
  font-weight: bold;
}

.v-menu__content {
  background-color: #ffffff;
  width: 70%;
  max-height: 150px;
  min-width: auto !important;
  left: 50% !important;
  transform: translate(-50%, -100px) !important;
}
</style>
