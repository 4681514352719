// https://vuex.vuejs.org/en/mutations.html

export default {
    setProperties( state, data ){
        state.settings = data
    },
    setBookingId(state, data){
        state.bookingId = data
    },
    clearForm(state ){
        state.authStatus = false
        state.reserveStatus = false
        state.reserve = null
        state.token = ''
        state.isPre = false

        state.guestToken = ''
        state.guest = null
        state.guestAuthStatus = false
    },
    auth_success(state, { token }){
        state.authStatus = true
        state.token = token
    },
    reserve_user(state, user ){
        state.reserve_user = user
    },
    reserve_data(state, { reserve }){
        state.reserveStatus = true
        state.reserve = reserve
    },
    reserve_guests(state, { reserve }){
        state.reserve_guests = []
        const persons = reserve.persons
        let i = 0
        for(i; i < persons.length; i++){
            if(persons[i].id != reserve.id){
                state.reserve_guests.push(persons[i])
            }
        }
    },
    add_guest(state, data ){
        state.reserve_guests.push(data)

    },
    removeGuest(state, data ){
        const index = state.reserve_guests.findIndex(guest => guest.id == data)
        state.reserve_guests.splice(index, 1)

    },
    update_guest(state, data ){
        const guestIndex = state.reserve_guests.findIndex( guest => guest.id == data.id)
        state.reserve_guests[guestIndex] = data
    },
    update_guest_by_field(state, data ){
        const guestIndex = state.reserve_guests.findIndex( guest => guest.id == data.id)
        state.reserve_guests[guestIndex][data.fieldName] = data[data.fieldName]
    },
    update_user(state, data ){
        state.reserve_user = data

    },
    update_user_by_field(state, data){
        state.reserve_user[data.fieldName] = data[data.fieldName]

    },
    guestLogout(state ){
        if(state.guestAuthStatus){
            state.guestToken = ""
            state.guest = null
        }
    },
    guestToken(state, data ){
        if(!state.guestAuthStatus){
            state.guestAuthStatus = true
            state.guestToken = data.access_token
            state.guest = data
        }
    },

    guestReserve(state, data ){
        if(state.guestAuthStatus){
            state.guestToken = ""
            state.guest = null
            state.guestAuthStatus = false
        }
        state.guestReserve = data
    },

    update_guest_by_field_single(state, data ){
        state.guest[data.fieldName] = data[data.fieldName]
    },

    isPc(state, status ){
        state.isPc = status
    },

    isPcConfirmed(state, status ){
        state.isPcConfirmed = status
    },
    setLang(state, data){
        state.lang = data
    },
    setShowHeader(state,status) {
        state.showHeader = status
    },
    setBreakWidth(state,width) {
        state.breakWidth = width
    },

    setGuestCurrent(state,data) {
        state.guest = data
    },

    setListGuest(state,list) {
        state.reserve_guests = list
    },
    setCurrentUser(state,data) {
        state.currentUser = data;
    },
    setStep(state,step) {
        state.step = step;
    }
}
