import defaultSettings from "@/settings";

const title = defaultSettings.title || "事前チェックインシステム";

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `&IoT事前チェックイン  - ${pageTitle}`;
  }
  return `${title}`;
}
