<template>
  <div class="custom-step">
    <v-stepper v-model="_currentStep">
      <div class="mt-2">
        <v-stepper-header
          class="mt-0 px-6"
          :class="{ 'hidden-step': !showHeader }"
          v-if="steps.length > 2"
        >
          <template v-for="(item, index) in steps">
            <v-divider v-if="index > 0" :key="`divider-${index}`"></v-divider>
            <v-stepper-step
              :key="`stepper-${index}`"
              :step="index + 1"
              :editable="getEditable(index)"
            />
          </template>
        </v-stepper-header>
      </div>

      <v-stepper-items>
        <template v-for="(item, index) in steps">
          <v-stepper-content
            :step="index + 1"
            :key="`step-item-${index}`"
            class="pa-0"
          >
            <component
              :is="item.component"
              @next-step="handleNextStep(index + 2)"
              :form="Object.assign({}, currentUser)"
              :user-id="userId"
              :is-guest="isGuest"
              :inputField="inputField"
              :visible="index + 1 === _currentStep"
            />
          </v-stepper-content>
        </template>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import UploadPassport from "@/components/stepper/DaihyoStep/UploadPassport"
import { mapState } from "vuex"
import StringUtil from "@/shared/StringUtil"

export default {
  name: "InfoStep",
  components: {
    UploadPassport,
    CreateName: () => import("@/components/stepper/DaihyoStep/CreateName"),
    CreatePhone: () => import("@/components/stepper/DaihyoStep/CreatePhone"),
    CreatePrevAndNextPlace: () =>
      import("@/components/stepper/DaihyoStep/CreatePrevAndNextPlace"),
    CreateSignature: () =>
      import("@/components/stepper/DaihyoStep/CreateSignature"),
    CreateAddress: () =>
      import("@/components/stepper/DaihyoStep/CreateAddress"),
    CreateCareer: () => import("@/components/stepper/DaihyoStep/CreateCareer"),
    CreateBirthDay: () =>
      import("@/components/stepper/DaihyoStep/CreateBirthDay"),
    CreateGender: () => import("@/components/stepper/DaihyoStep/CreateGender"),
    FinishRegistered: () =>
      import("@/components/stepper/DaihyoStep/FinishRegistered"),
  },
  data() {
    return {
      checkinStep: 1,
      objStep: {
        next1: true,
        next2: true,
        next3: false,
        next4: false,
        next5: false,
        next6: false,
        next7: false,
      },

      isGuest: false,
      userId: null,
      form: {},
      // inputField:{
      //   birthDate:true,
      //   gender:true
      // }
    }
  },
  computed: {
    ...mapState({
      breakWidth: (state) => state.breakWidth,
      reserve_user: (state) => state.reserve_user,
      reserve_guests: (state) => state.reserve_guests,
      currentUser: (state) => state.currentUser,
      currentStep: (state) => state.step,
      inputField: (state) => state.settings.inputField,
      showHeader: (state) => state.showHeader,
    }),
    _currentStep: {
      get() {
        return this.currentStep
      },

      set(val) {
        this.$emit("change", val)
        this.$store.commit("setStep", val)
      },
    },
    steps() {
      if (this.isGuest && this.$store.getters.notOtherThanOwner) {
        // only name is required.
        return [this.stepName, this.stepFinished]
      } else {
        const steps = [this.stepPassport, this.stepName]
        if (this.inputField.signature) steps.push(this.stepSignature)
        steps.push(this.stepAddress)
        if (this.inputField.birthDate) steps.push(this.stepBirthDate)
        if (this.inputField.gender) steps.push(this.stepGender)
        if (this.inputField.phone) steps.push(this.stepPhone)
        if (this.inputField.occupation) steps.push(this.stepOccupation)
        if (
          this.inputField.previousPlaceOfStay ||
          this.inputField.nextDestination
        )
          steps.push(this.stepPrevAndNextPlace)

        steps.push(this.stepFinished)
        return steps
      }
    },
    stepPassport() {
      return {
        component: "UploadPassport",
        check: "passportImageUrl",
      }
    },
    stepName() {
      return {
        component: "CreateName",
        check: "name",
      }
    },
    stepSignature() {
      return {
        component: "CreateSignature",
        check: "signatureImageUrl",
      }
    },
    stepAddress() {
      return {
        component: "CreateAddress",
        check: "address",
      }
    },
    stepBirthDate() {
      return {
        component: "CreateBirthDay",
        check: "birthDate",
      }
    },
    stepGender() {
      return {
        component: "CreateGender",
        check: "gender",
      }
    },
    stepPhone() {
      return {
        component: "CreatePhone",
        check: "phone",
      }
    },
    stepOccupation() {
      return {
        component: "CreateCareer",
        check: "occupation",
      }
    },
    stepPrevAndNextPlace() {
      return {
        component: "CreatePrevAndNextPlace",
      }
    },
    stepFinished() {
      return {
        component: "FinishRegistered",
      }
    },

    sizePc() {
      return this.breakWidth >= 600
    },
  },
  watch: {
    reserve_user() {
      this.fetchUser()
    },
    checkinStep() {},
  },
  created() {
    const isGuest = StringUtil.getParamData("isGuest") === "true"
    const uId = StringUtil.getParamData("userId")
    this.isGuest = isGuest
    this.userId = uId

    if (!isGuest) {
      this.fetchUser()
    } else {
      this.fetchGuest()
    }
  },
  mounted() {
    this.$store.commit("setStep", 1)
    if (!this.isGuest) this.$store.dispatch("loadDefaultGuestInfo")
  },
  methods: {
    handleNextStep(step) {
      this.checkinStep = step
      this.$store.commit("setStep", step)
      this.$set(this.objStep, `next${step}`, true)
      if (!this.isGuest) this.$store.dispatch("saveDefaultGuestInfo")
    },

    fetchUser() {
      this.form = this.currentUser
      if (this.form) {
        this.userId = this.form.id
      } else {
        console.log("User logout : ", this.form)
      }
    },

    fetchGuest() {
      this.form = this.currentUser
      if (!this.form) {
        console.log("Guest is not existed or deleted:", this.form)
      }
    },

    getEditable(index) {
      if (this.objStep[`next${index + 1}`]) return true
      // check all the input before this index is completed.
      for (let i = 0; i < index; i++) {
        const step = this.steps[i]
        if (!step.check) continue
        else if (typeof step.check === "string") {
          if (!this.currentUser[step.check]) {
            return false // this input is not completed yet
          }
        } else {
          // if neede support check = function style check
          throw Error("not implemented")
        }
      }
      return true
    },
  },
}
</script>
<style scoped lang="scss">
.hidden-step {
  opacity: 0;
  cursor: default;
  .v-stepper__step {
    cursor: default;
  }
}
</style>
