<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    class="main-layout_content_second"
  >
    <v-layout justify-center wrap>
      <!-- inline edit table -->
      <v-flex xs12>
        <v-flex xs12>
          <h3 class="main_title">
            {{ $t("Checkin.please-enter-the-guest-address") }}
          </h3>
          <p class="sub_title my-3 color-dark">
            {{ $t("Checkin.address-cations") }}
          </p>
          <v-form
            class="mt-5 mb-30 main_content checkin_form"
            @submit.prevent.stop="handleSubmitAddress()"
          >
            <v-text-field
              v-bind:disabled="disableInput"
              @blur="addressOnBlur()"
              v-model="address"
              class="purple-input"
              :label="$t('Checkin.postal-code-option')"
              maxlength="7"
              placeholder="1234567"
              :loading="isLoadingZipcode"
            />
            <p class="error--text" v-if="messValidPostcode">
              {{ messValidPostcode }}
            </p>
            <v-text-field
              v-bind:disabled="disableInput"
              v-model="formAddress"
              required
              :counter="100"
              class="purple-input"
              :rules="addressVerifyRules"
              :label="$t('Checkin.address')"
            />

            <v-checkbox
              class="checkin_form_chbox"
              v-if="userAuthor && userAuthor.address"
              v-model="autoFill"
              :label="$t('Checkin.same-as-representative')"
            ></v-checkbox>
          </v-form>
        </v-flex>

        <v-flex md12 xs12>
          <v-btn
            class="btn-right btn-custom"
            v-bind:disabled="!valid"
            v-bind:class="
              valid ? 'btn-submit-green' : 'btn-submit-green-disabled'
            "
            v-on:click="handleSubmitAddress()"
            >{{ $t("Checkin.next") }}</v-btn
          >
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex"
import { jsonp } from "vue-jsonp"
import axios from "axios"

export default {
  name: "GuestAddress",
  data: () => ({
    valid: false,
    prefs: [],
    loading: false,
    formAddress: null,
    formPostCode: null,
    formId: "",
    autoFill: false,
    disableInput: false,

    // from url params
    isGuest: false,
    userId: null,
    addParams: "",

    address: "",
    userAuthor: null,

    // validate
    messValidPostcode: null,
    isLoadingZipcode: false,
  }),

  computed: {
    addressVerifyRules() {
      return [
        (v) => !!v || this.$t("Checkin.the-following-field-is-required"),
        (v) =>
          (v && v.length <= 100) ||
          this.$t(
            "Checkin.please-enter-your-address-maximum-of-100-characters"
          ),
        (v) =>
          (v && this.checkSpecificKey(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
      ]
    },
    guestGetEnterCareerScreenUrl() {
      return "guest-checkin-create-career"
    },

    getGuestEnterCreateNameScreenUrl() {
      return "guest-checkin-create-name"
    },

    getZipCodeApiUrl() {
      return "https://zipcloud.ibsnet.co.jp/api/search"
    },

    ...mapState(["reserve_guests", "reserve_user"]),
  },

  watch: {
    async address(val) {
      this.messValidPostcode = null

      if (this.checkSpecificKey(val)) {
        if (val && val.length >= 7) {
          this.fetchAddressByCode(val)
        }
      } else {
        this.valid = false
        this.messValidPostcode = this.$t(
          "Checkin.the-entered-characters-format-is-not-supported"
        )
      }
    },

    autoFill(val) {
      if (val) {
        this.address = this.userAuthor.postcode
        this.formAddress = this.userAuthor.address
        this.valid = true
        this.disableInput = true
      } else {
        this.disableInput = false
      }
    },
    formAddress(val) {
      if (val) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
  },

  created() {},

  mounted() {
    if (this.reserve_user) {
      this.userAuthor = this.reserve_user
    } else {
      this.fetchUser()
    }
    this.fetchGuest()
  },

  methods: {
    addressOnBlur() {
      if (this.address && this.address.length > 0 && this.address.length < 7) {
        this.messValidPostcode = this.$t("Checkin.postal-code-is-invalid")
      }
    },
    async fetchAddressByCode(val) {
      this.isLoadingZipcode = true

      await jsonp(this.getZipCodeApiUrl, {
        zipcode: val,
      })
        .then((res) => {
          if (res.status == 200 && res.results) {
            const resMain = res.results[0]
            var newAddress = ""
            newAddress = resMain.address1 + resMain.address2 + resMain.address3
            if (this.formPostCode != val) {
              this.formAddress = newAddress
            }
          } else if (res.status == 400 || !res.results) {
            this.messValidPostcode = this.$t("Checkin.postal-code-is-invalid")
          } else {
            this.messValidPostcode = res.message
          }
        })

        .catch((err) => {
          console.log("api error, ", err)
        })
        .finally(() => (this.isLoadingZipcode = false))
    },
    checkSpecificKey(str) {
      var specialKey =
        "[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'"
      if (str) {
        for (var i = 0; i < str.length; i++) {
          if (specialKey.indexOf(str.substr(i, 1)) != -1) {
            return false
          }
        }
      }
      return true
    },
    handleSubmitAddress() {
      this.address = this.messValidPostcode ? null : this.address
      var addressForm, postcodeForm

      addressForm = this.formAddress ? this.formAddress : null
      postcodeForm = this.address ? this.address : null

      this.$store
        .dispatch("guestUpdateReservation", {
          address: addressForm,
          postcode: postcodeForm,
          id: this.formId,
        })
        .then(() => {
          this.$store.commit("update_guest_by_field_single", {
            address: addressForm,
            fieldName: "address",
          })
          this.$store.commit("update_guest_by_field_single", {
            postcode: postcodeForm,
            fieldName: "postcode",
          })
          // this.$router.push(this.guestGetEnterCareerScreenUrl)
          this.$emit("next-step")
        })
        .catch((err) => {
          console.log(err.response)
        })
    },

    async fetchGuest() {
      this.formPostCode = this.$store.state.guest.postcode
      this.formAddress = this.$store.state.guest.address
      this.formId = this.$store.state.guest.id
      if (this.formAddress) {
        this.address = this.formPostCode
        this.valid = true
      }
      this.userId = this.$store.state.guest.id
    },

    async fetchUser() {
      return new Promise((resolve, reject) => {
        axios
          .get(window.appApiUrl + "reserved/user", {
            headers: {
              Authorization: "Bearer " + this.$store.state.guestToken,
            },
          })
          .then((res) => {
            var persons = res.data.persons
            var personAuthor = persons.find((person) => person.isOwner == true)
            if (personAuthor) {
              this.userAuthor = personAuthor
            }
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.$store.commit("clearForm")
              window.location.href =
                "/change-password/" +
                this.$store.state.guestReserve.rsvId +
                "?userId=" +
                this.userId
            }
            reject(err.response)
          })
      })
    },
  },
}
</script>

<style lang="scss">
.pw-title {
  font-weight: 400 !important;
  font-size: 60px !important;
  color: rgb(51 72 86) !important;
  text-align: center !important;
}

.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  color: #fff !important;
  height: 43px !important;
}

.btn-submit-green-disabled {
  width: 100%;
  background-color: #e0e0e0 !important;
  color: #f3f3f3;
  height: 43px !important;
}

.next-screen-btn,
.btn-submit-green-disabled span {
  font-weight: bold;
}

.address-suggest-box {
}
.address-suggest-box ul li {
  list-style: none;
}

.theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  border-image: none !important;
}

.v-text-field.v-input--is-focused .v-input__control .v-input__slot:after {
  border-image: none !important;
  border: none !important;
}
.color-dark {
  color: #212221;
}
</style>
