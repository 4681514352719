<template>
  <v-app>
    <core-multi-lang
      :number-step="step"
      :back-path="getGuestEnterCreatePasswordScreenUrl"
    />
    <v-container fill-height fluid grid-list-xl class="main-layout_content">
      <v-layout justify-center wrap>
        <!-- inline edit table -->
        <v-flex xs12>
          <v-flex xs12 class="pt-0">
            <h3 class="main_title">
              {{ $t("Checkin.please-enter-the-guest-name") }}
            </h3>
            <p class="sub-title pb-5">
              {{ $t("Checkin.if-you-are-not-a-Japanese-citizen") }}
            </p>
            <v-form
              v-model="valid"
              v-if="form"
              class="mt-5 mb-5 pt-5 checkin_form main_content"
              @submit.stop.prevent="handleSubmitName()"
            >
              <v-text-field
                v-model="form.name"
                required
                :counter="40"
                :rules="nameVerifyRules"
                class="purple-input"
                :label="$t('Checkin.name')"
              />
            </v-form>
          </v-flex>

          <v-flex md12 xs12>
            <v-btn
              class="btn-custom btn-right"
              v-bind:disabled="!valid"
              v-bind:class="
                valid ? 'btn-submit-green' : 'btn-submit-green-disabled'
              "
              v-on:click="handleSubmitName()"
              >{{ $t("Checkin.next") }}</v-btn
            >
          </v-flex>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<style lang="scss">
.pw-title {
  font-weight: 400 !important;
  font-size: 60px !important;
  color: rgb(51 72 86) !important;
  text-align: center !important;
}

.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  color: #fff !important;
  height: 43px !important;
}

.btn-submit-green-disabled {
  width: 100%;
  background-color: #e0e0e0 !important;
  color: #f3f3f3;
  height: 43px !important;
}
</style>

<script>
export default {
  data: () => ({
    form: null,
    step: "3/6",
    valid: false,
  }),

  computed: {
    nameVerifyRules() {
      return [
        (v) => !!v || this.$t("Checkin.the-following-field-is-required"),
        (v) =>
          (v && v.length <= 40) ||
          this.$t("Checkin.please-enter-your-name-maximum-of-40-characters"),
        (v) =>
          (v && this.checkSpecificKey(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
      ]
    },
    getGuestEnterCreateAddressScreenUrl() {
      return "guest-checkin-create-address"
    },

    getGuestEnterCreatePasswordScreenUrl() {
      return "guest-checkin-create-password"
    },
  },

  created() {
    this.fetchGuest()
  },

  mounted() {},

  methods: {
    checkSpecificKey(str) {
      const specialKey =
        "[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'"
      for (let i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false
        }
      }
      return true
    },
    handleSubmitName() {
      if (this.valid) {
        this.submitNameForGuest()
      }
    },

    submitNameForGuest() {
      this.$store
        .dispatch("guestUpdateReservation", this.form)
        .then(() => {
          this.$router.push(this.getGuestEnterCreateAddressScreenUrl)
        })
        .catch((err) => {
          console.log(err.response)
        })
    },

    fetchGuest() {
      this.form = this.$store.state.guest
    },
  },
}
</script>
