<template>
  <v-app>
    <!-- <core-multi-lang /> -->
    <v-container
    fill-height
    fluid
    grid-list-xl
    class="main-layout_content"
  >
    <v-layout
      justify-center
      wrap
    >
      <!-- inline edit table -->
      <v-flex xs12>

        <div class="main_intro">
          <center class="main-layout_error">
            <p style="font-size: 45px; color:#77848d; font-weight: bold;margin-bottom: 0px;">404 Not Found</p>
            <h6 style="font-size: 11px;">{{ $t('Checkin.page-not-found') }}</h6>
            <p style="color : #334856; font-size : 11px;margin-top: 20px;">{{ $t('Checkin.the-page-you-are-looking-for-may-have-moved-or-been-deleted') }}</p>
          </center>
        </div>

      </v-flex>
    </v-layout>
  </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    iconPageError : require('@/assets/img/error-icon.png'),
  }),

  computed: {

  },
  watch: {
    
  },

  created () {
  },

  mounted(){

  },

  methods: {
  }
}
</script>

<style lang="scss">
</style>
