<template>
  <v-flex xs12 md12 v-if="reserve && reserve_user">
    <p class="message-label">{{ $t("Checkin.reservation-details") }}</p>
    <p class="message-infor" v-if="reserve.booking">
      {{ $t("Checkin.reservation-no") }} : {{ reserve.booking.reservedCode }}
    </p>
    <p class="message-infor" v-if="reserve.booking">
      {{ $t("Checkin.reserved-dates") }} :
      {{ dateToString(reserve.booking.checkInAt) }} -
      {{ dateToString(reserve.booking.checkOutAt) }}
    </p>
    <p class="message-infor">
      {{ $t("Checkin.name-of-representative") }} : {{ reserve_user.name }}
    </p>

    <p class="message-infor" v-if="reserve_guests">
      {{ $t("Checkin.no-of-guests")
      }}{{
        $i18n.locale == "ja"
          ? reserve_guests.length + 1 + "人"
          : " : " + (reserve_guests.length + 1)
      }}
    </p>
  </v-flex>
</template>

<style scoped>
.message-label {
  color: rgb(97 110 126);
  font-weight: bold;
  font-size: 17px !important;
  margin-bottom: 15px;
}
.message-infor {
  color: rgb(112 124 138);
  font-size: 14px !important;
  margin-bottom: 0;
}
</style>

<script>
import StringUtil from "@/shared/StringUtil"
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters(["reserve", "reserve_user", "reserve_guests"]),
  },
  mounted() {},
  methods: {
    dateToString(date) {
      return StringUtil.dateToString(date)
    },
  },
}
</script>
