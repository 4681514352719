<template>
  <v-app>
    <core-multi-lang v-show="showHeader" back-path="/checkin" />

    <GuestStep />
  </v-app>
</template>

<style lang="scss">
.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  color: #fff !important;
  height: 43px !important;
}

.btn-submit-green-disabled {
  width: 100%;
  background-color: #e0e0e0 !important;
  color: #f3f3f3;
  height: 43px !important;
}

.upload-passport-btn span {
  display: block;
}

.upload-passport-btn > span.v-btn__loader {
  text-align: center;
}
.upload-passport-btn > span.v-btn__loader > .v-progress-circular {
  margin-top: 6%;
}
.next-screen-btn {
  background: #ccc;
  color: #fff;
}

.next-screen-btn span {
  font-weight: bold;
  color: #fff;
}
.sub-title {
  ul {
    li:first-child {
      list-style: none;
    }
  }
}
</style>

<script>
import GuestStep from "@/components/stepper/GuestStep";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    GuestStep
  },
  data: () => ({

    userId: null

  }),

  computed: {
    ...mapGetters(["guest", "guestReserve"]),
    ...mapState({
      reserve_guests: state => state.reserve_guests,
      guest: state => state.guest,
      showHeader: state => state.showHeader
    })
  },
  watch: {},

  created() {},

  mounted() {
    this.userId = this.guest.id;
  },

  methods: {}
};
</script>

<style lang="scss"></style>
