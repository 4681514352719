<template>
    <v-container
        fill-height
        fluid
        grid-list-xl
        class="main-layout_inner_second"
    >
      <v-layout
          justify-center
          wrap
      >
        <!-- inline edit table -->
        <v-flex xs12 class="flex-col-between">

          <v-flex xs12 md12 class="pt-0">
            <h3 class="main_title" v-if="isGuest">{{ $t('Checkin.advanced-registration-of-representative-complete-for-guest') }}</h3>
            <h3 class="main_title" v-else>{{ $t('Checkin.advanced-registration-of-representative-complete') }}</h3>
          </v-flex>

        </v-flex>
      </v-layout>
      <div class="main_btn pt-0" >
        <v-btn block class="btn-custom btn-custom-main" style="height: auto !important; min-height: 47px !important;padding: 3px 0px !important"
               v-on:click="handleRedirectToCheckinInforScreen()"
        >{{ $t('Checkin.return-to-representative') }}</v-btn>
      </div>
    </v-container>
</template>

<style lang="scss">
.btn-custom-main>span{
  word-break: break-all;
}
.next-screen-btn {
  background-color: rgb(97 110 126) !important;
  color: #fff;
}

.pw-title{
  font-weight: 400 !important;
  font-size: 60px !important;
  color: rgb(51 72 86) !important;
  text-align: center !important;
}
</style>

<script>
import StringUtil from '@/shared/StringUtil'
import {mapGetters, mapState} from "vuex";

export default {
  name:"FinishRegistered",
  data: () => ({
    // from url params
    isGuest : false,
    userId : null,
    step : '5/5'
  }),
  watch : {
  },

  computed: {
    ...mapGetters(["reserve_user","reserve_guests"]),
    ...mapState({
      currentUser:state => state.currentUser
    }),
    dataFinished() {
      let nullArray = Object.values(this.reserve_user).filter(v => v === null);
      return nullArray.length === 0;
    },

    getEnterInformationScreenUrl(){
      return this.dataFinished ? 'finished' : 'enter-information'
    },

    getEnterCreateCareerScreenUrl() {
      return "checkin-create-career" + this.addParams;
    },

  },

  created () {
    const isGuest = StringUtil.getParamData("isGuest") === "true";
    const userId = StringUtil.getParamData("userId");
    this.isGuest = isGuest;
    this.userId = userId;

    this.addParams = "?isGuest=" + isGuest;

    if (isGuest) {
      this.addParams = this.addParams + "&userId=" + this.userId;
    }
  },

  mounted(){

  },

  methods: {
    handleRedirectToCheckinInforScreen(){

      if(this.isGuest) {
        // const filterItem = this.reserve_guests.find(item => item.id === this.userId);
        this.$store.dispatch('updateReservation',this.currentUser).then(() => {
          this.$router.push(this.getEnterInformationScreenUrl)
        }).catch(error => console.log(error))
      } else {
        this.$router.push(this.getEnterInformationScreenUrl)
      }

    }
  }
}
</script>

<style lang="scss">
</style>
