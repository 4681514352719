<template>
  <v-app>
    <core-multi-lang
      :number-step="step"
      :back-path="getEnterCreatePasswordScreenUrl"
    />
    <v-container fill-height fluid grid-list-xl class="main-layout_content">
      <v-layout justify-center wrap>
        <!-- inline edit table -->
        <v-flex xs12>
          <v-flex xs12 class="pt-0">
            <h3 class="main_title">
              {{ $t("Checkin.please-enter-the-guest-name") }}
            </h3>
            <p class="sub-title pb-5">
              {{ $t("Checkin.if-you-are-not-a-Japanese-citizen") }}
            </p>
            <v-form
              v-model="valid"
              v-if="form"
              class="mt-5 mb-30 pt-5 main_content checkin_form"
              @submit.prevent.stop="handleSubmitName()"
            >
              <v-text-field
                v-model="form.name"
                required
                :counter="40"
                :rules="nameVerifyRules"
                class="purple-input"
                :label="$t('Checkin.name')"
              />
            </v-form>
          </v-flex>

          <div>
            <v-btn
              v-bind:disabled="!valid"
              v-bind:class="
                valid ? 'btn-submit-green' : 'btn-submit-green-disabled'
              "
              class="btn-right btn-custom"
              v-on:click="handleSubmitName()"
              :loading="loading"
              >{{ $t("Checkin.next") }}</v-btn
            >
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<style lang="scss">
.pw-title {
  font-weight: 400 !important;
  font-size: 60px !important;
  color: rgb(51 72 86) !important;
  text-align: center !important;
}

.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  color: #fff !important;
  height: 43px !important;
}

.btn-submit-green-disabled {
  width: 100%;
  background-color: #e0e0e0 !important;
  color: #f3f3f3;
  height: 43px !important;
}
</style>

<script>
import StringUtil from "@/shared/StringUtil"
export default {
  data: () => ({
    form: null,
    step: "2/5",
    valid: false,
    loading: true,
    // from url params
    isGuest: false,
    userId: null,
    addParams: "",
  }),

  computed: {
    nameVerifyRules() {
      return [
        (v) => !!v || this.$t("Checkin.the-following-field-is-required"),
        (v) =>
          (v && v.length <= 40) ||
          this.$t("Checkin.please-enter-your-name-maximum-of-40-characters"),
        (v) =>
          (v && this.checkSpecificKey(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
      ]
    },
    getEnterCreateAddressScreenUrl() {
      return "checkin-create-address"
    },
    getEnterCreatePasswordScreenUrl() {
      return "upload-passport" + this.addParams
    },
  },

  created() {
    const isGuest = StringUtil.getParamData("isGuest") === "true"
    const userId = StringUtil.getParamData("userId")
    this.isGuest = isGuest
    this.userId = userId

    this.addParams = "?isGuest=" + isGuest

    if (!isGuest) {
      this.fetchUser()
    } else {
      this.addParams = this.addParams + "&userId=" + this.userId
      this.fetchGuest()
    }
  },

  mounted() {},

  methods: {
    checkSpecificKey(str) {
      var specialKey =
        "[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'"
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false
        }
      }
      return true
    },

    handleSubmitName() {
      if (this.valid) {
        if (this.isGuest) {
          this.submitNameForGuest()
        } else {
          this.submitName()
        }
      }
    },

    submitName() {
      this.loading = true
      this.$store
        .dispatch("updateReservation", this.form)
        .then(() => {
          this.$store.commit("update_user", this.form)
          this.$router.push(
            this.getEnterCreateAddressScreenUrl + this.addParams
          )
          this.loading = false
        })
        .catch((err) => {
          console.log(err.response)
        })
    },

    submitNameForGuest() {
      this.loading = true
      this.$store
        .dispatch("updateReservation", this.form)
        .then(() => {
          this.$store.commit("update_guest", this.form)
          this.$router.push(
            this.getEnterCreateAddressScreenUrl + this.addParams
          )
          this.loading = false
        })
        .catch((err) => {
          console.log(err.response)
        })
    },

    fetchUser() {
      this.form = this.$store.state.reserve_user
      if (this.form) {
        this.userId = this.form.id
      } else {
        console.log("User logout : ", this.form)
      }
    },

    async fetchGuest() {
      this.form = await this.$store.dispatch("getUser", this.userId)
      if (!this.form) {
        console.log("Guest is not existed or deleted: ", this.form)
      }
    },
  },
}
</script>
