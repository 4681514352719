<template>
  <v-app>
    <core-multi-lang
      :number-step="step"
      :back-path="getEnterCreateNameScreenUrl"
    />
    <v-container fill-height fluid grid-list-xl class="main-layout_content">
      <v-layout justify-center wrap>
        <!-- inline edit table -->
        <v-flex xs12>
          <v-flex xs12 class="pt-0">
            <h3 class="main_title">
              {{ $t("Checkin.please-enter-the-guest-address") }}
            </h3>

            <v-form
              class="mt-5 mb-30 main_content checkin_form"
              @submit.stop.prevent="handleSubmitAddress()"
            >
              <v-text-field
                v-bind:disabled="disableInput"
                @blur="addressOnBlur()"
                v-model="address"
                class="purple-input"
                :label="$t('Checkin.postal-code-option')"
                maxlength="7"
                placeholder="1234567"
                :loading="isLoadingZipcode"
              />

              <p class="error--text" v-if="messValidPostcode">
                {{ messValidPostcode }}
              </p>

              <v-text-field
                v-bind:disabled="disableInput"
                v-model="formAddress"
                required
                :counter="100"
                class="purple-input"
                :rules="addressVerifyRules"
                :label="$t('Checkin.address')"
              />

              <v-checkbox
                v-if="isGuest && reserve_user.address"
                class="checkin_form_chbox"
                v-model="autoFill"
                :label="$t('Checkin.same-as-representative')"
              ></v-checkbox>
            </v-form>
          </v-flex>

          <div>
            <v-btn
              v-bind:disabled="!valid"
              v-bind:class="
                valid ? 'btn-submit-green' : 'btn-submit-green-disabled'
              "
              class="btn-custom btn-right"
              v-on:click="handleSubmitAddress()"
              >{{ $t("Checkin.next") }}</v-btn
            >
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<style lang="scss">
.pw-title {
  font-weight: 400 !important;
  font-size: 60px !important;
  color: rgb(51 72 86) !important;
  text-align: center !important;
}

.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  color: #fff !important;
  height: 43px !important;
}

.btn-submit-green-disabled {
  width: 100%;
  background-color: #e0e0e0 !important;
  color: #f3f3f3;
  height: 43px !important;
}

.address-suggest-box {
}
.address-suggest-box ul li {
  list-style: none;
}

.theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  border-image: none !important;
}

.v-text-field.v-input--is-focused .v-input__control .v-input__slot:after {
  border-image: none !important;
  border: none !important;
}
</style>

<script>
import StringUtil from "@/shared/StringUtil"

import { jsonp } from "vue-jsonp"

import { mapState } from "vuex"

export default {
  data: () => ({
    step: "3/5",
    valid: false,
    prefs: [],
    loading: false,
    search: null,
    formPostCode: null,
    formAddress: null,
    formId: null,
    autoFill: false,
    disableInput: false,

    // from url params
    isGuest: false,
    userId: null,
    addParams: "",

    address: "",

    isLoadingZipcode: false,

    // validate
    messValidPostcode: null,
  }),

  computed: {
    addressVerifyRules() {
      return [
        (v) => !!v || this.$t("Checkin.the-following-field-is-required"),
        (v) =>
          (v && v.length <= 100) ||
          this.$t(
            "Checkin.please-enter-your-address-maximum-of-100-characters"
          ),
        (v) =>
          (v && this.checkSpecificKey(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
      ]
    },
    postcodeVerifyRules() {
      return [
        (v) =>
          (v.length > 0 && this.checkSpecificKey(v)) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
      ]
    },
    getEnterCareerScreenUrl() {
      return "checkin-create-career"
    },

    getZipCodeApiUrl() {
      return "https://zipcloud.ibsnet.co.jp/api/search"
    },

    getEnterCreateNameScreenUrl() {
      return "checkin-create-name" + this.addParams
    },

    ...mapState(["reserve_guests", "reserve_user"]),
  },

  watch: {
    async address(val) {
      this.messValidPostcode = null
      if (this.checkSpecificKey(val)) {
        if (val && val.length == 7) {
          this.fetchAddressByCode(val)
        }
        // else is not valid
      } else {
        this.messValidPostcode = this.$t(
          "Checkin.the-entered-characters-format-is-not-supported"
        )
      }
    },

    autoFill(val) {
      if (val) {
        this.formAddress = this.reserve_user.address
        this.address = this.reserve_user.postcode
        this.valid = true
        this.disableInput = true
      } else {
        this.disableInput = false
      }
    },
    formAddress(val) {
      if (val) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
  },

  created() {
    const isGuest = StringUtil.getParamData("isGuest") === "true"
    const userId = StringUtil.getParamData("userId")
    this.isGuest = isGuest
    this.userId = userId

    this.addParams = "?isGuest=" + isGuest

    if (!isGuest) {
      this.fetchUser()
    } else {
      this.addParams = this.addParams + "&userId=" + this.userId
      this.fetchGuest()
    }
  },

  mounted() {},

  methods: {
    addressOnBlur() {
      if (this.address.length > 0 && this.address.length < 7) {
        this.messValidPostcode = this.$t("Checkin.postal-code-is-invalid")
      }
    },
    async fetchAddressByCode(val) {
      this.isLoadingZipcode = true
      await jsonp(this.getZipCodeApiUrl, {
        zipcode: val,
      })
        .then((res) => {
          if (res.status == 200 && res.results) {
            const resMain = res.results[0]
            var newAddress = ""
            newAddress = resMain.address1 + resMain.address2 + resMain.address3

            if (this.formPostCode != val) {
              this.formAddress = newAddress
            }
          } else if (res.status == 400 || !res.results) {
            this.messValidPostcode = this.$t("Checkin.postal-code-is-invalid")
          } else {
            this.messValidPostcode = res.message
          }
        })

        .catch((err) => {
          console.log("api error, ", err)
        })
        .finally(() => (this.isLoadingZipcode = false))
    },

    checkSpecificKey(str) {
      var specialKey =
        "[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'"
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false
        }
      }
      return true
    },
    handleSubmitAddress() {
      if (this.valid) {
        if (!this.isGuest) {
          this.submitAddress()
        } else {
          this.submitAddressForGuest()
        }
      }
    },

    submitAddress() {
      this.formPostCode = this.messValidPostcode ? null : this.address

      this.$store
        .dispatch("updateReservation", {
          address: this.formAddress,
          postcode: this.formPostCode ? this.formPostCode : null,
          id: this.formId,
        })
        .then(() => {
          this.$store.commit("update_user_by_field", {
            address: this.formAddress,
            fieldName: "address",
          })
          this.$store.commit("update_user_by_field", {
            postcode: this.formPostCode ? this.formPostCode : null,
            fieldName: "postcode",
          })
          this.$router.push(this.getEnterCareerScreenUrl + this.addParams)
        })
        .catch((err) => {
          console.log(err.response)
        })
    },

    submitAddressForGuest() {
      this.address = this.messValidPostcode ? null : this.address

      var addressForm, postcodeForm

      addressForm = this.formAddress ? this.formAddress : null
      postcodeForm = this.address ? this.address : null

      this.$store
        .dispatch("updateReservation", {
          address: addressForm,
          postcode: postcodeForm,
          id: this.formId,
        })
        .then(() => {
          this.$store.commit("update_guest_by_field", {
            address: addressForm,
            id: this.formId,
            fieldName: "address",
          })
          this.$store.commit("update_guest_by_field", {
            postcode: postcodeForm,
            id: this.formId,
            fieldName: "postcode",
          })

          this.$router.push(this.getEnterCareerScreenUrl + this.addParams)
        })
        .catch((err) => {
          console.log(err.response)
        })
    },

    fetchUser() {
      if (this.reserve_user) {
        this.formPostCode = this.reserve_user.postcode
        this.formAddress = this.reserve_user.address
        this.formId = this.reserve_user.id

        if (this.formAddress) {
          this.address = this.formPostCode
          this.valid = true
        }

        this.userId = this.reserve_user.id
      } else {
        console.log("User logout: ", this.reserve_user)
      }
    },

    async fetchGuest() {
      var formGuest = await this.$store.dispatch("getUser", this.userId)
      if (formGuest) {
        this.formAddress = formGuest.address
        this.formPostCode = formGuest.postcode
        this.formId = formGuest.id
        if (this.formAddress) {
          this.valid = true
          this.address = this.formPostCode
        }
      } else {
        console.log("Guest is not existed or deleted : ", formGuest)
      }
    },
  },
}
</script>
