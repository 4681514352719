<template>
  <v-container
      fill-height
      fluid
      grid-list-xl
      class=" bts-class-pc"
  >
    <v-layout justify-center wrap>
      <v-flex>
        <div style="padding-bottom: 5rem">
          <div class="main_content d-flex flex-column">
            <img
                src="../../assets/img/icon-close.png"
                @click="_iconClick"
                class="align-self-end ic-close"
            />
            <v-flex>
              <h3 class="main_title">
                {{ $t("Checkin.check-ocr") }}
              </h3>
            </v-flex>
            <v-form
                ref="ocrForm"
                v-model="valid"
                class="mb-5 checkin_form main_content"
            >
              <v-text-field class="sub-feature-text" :label="$t('Checkin.ocr-name')" v-model="form.name"></v-text-field>
              <v-text-field class="sub-feature-text" :label="$t('Checkin.ocr-dob')" v-model="form.birthDate"></v-text-field>
              <v-text-field class="sub-feature-text" :label="$t('Checkin.ocr-gender')" v-model="form.gender"></v-text-field>
            </v-form>
          </div>
        </div>
        <div class="main_btn">
          <v-btn class="btn-custom btn-custom-default" @click="_submitOne">
            <slot style="color: #616e7e !important">{{ buttonTextOne }} </slot>
          </v-btn>
          <v-btn
              class="btn-custom btn-submit-green"
              @click="_submitTwo"
              :loading="loading"
          >
            {{ buttonTextTwo }}
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "OcrPc",
  props: {
    buttonTextOne: {
      type: String,
      default: () => ""
    },
    buttonTextTwo: {
      type: String,
      default: () => ""
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    form:{
      type:Object,
      default:() => {
        return {
          name:'',
          birthday:'',
          gender:''
        }
      }
    }
  },
  data() {
    return {
      valid:false
    }
  },
  methods: {
    _iconClick() {
      this.$emit("icon-close");
    },

    _submitOne() {
      this.$emit("submit-one");
    },

    _submitTwo() {
      this.$emit("submit-two");
    },
  },
}
</script>

<style scoped lang="scss">
.bts-class-pc {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  margin-top: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  border-top:1px solid rgba(0, 0, 0, 0.42);
  .ic-close {
    cursor: pointer;
    padding-bottom: 1rem;
  }
  .content-bts {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: calc(812px - 139px);
  }

  .btn-submit-green {
    width: 100%;
    background-color: rgb(97 110 126) !important;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #fff;
  }

  .btn-submit-green-disabled {
    width: 100%;
    background-color: #e0e0e0 !important;
    color: #f3f3f3;
    height: 43px !important;
  }

  .btn-custom-default {
    min-height: 48px !important;
    height: auto !important;
    padding: 3px 8px !important;
    margin-bottom: 1.5rem !important;
  }
}
</style>