// https://vuex.vuejs.org/en/actions.html
import axios from "axios"

// The login action passes vuex commit helper that we will use to trigger mutations.
export default {
  login({ commit }, data) {
    commit("clearForm")

    return new Promise((resolve, reject) => {
      const dataPost = { id: data.rsvId, password: data.password }
      axios
        .post(window.appApiUrl + "reserved/login", dataPost)
        .then((res) => {
          const token = res.data.access_token
          commit("auth_success", { token })
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // BOK002
  getReserved({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(window.appApiUrl + "reserved/user", {
          headers: {
            Authorization: "Bearer " + state.token,
          },
        })
        .then((res) => {
          const reserve = res.data
          commit("reserve_data", { reserve })
          commit("reserve_guests", { reserve })
          const user = reserve.persons.find((item) => item.isOwner === true)
          commit("reserve_user", {
            ...user,
          })
          resolve(res)
        })
        .catch((err) => {
          if (err.response.status == 401) {
            commit("clearForm")
            if (state.bookingId) {
              // window.location.href = "/confirm/" + state.bookingId;
            } else {
              window.location.href = "/error-page"
            }
          }
          reject(err.response)
        })
    })
  },

  // BOK008
  getPersonPassport({ state, commit }, data) {
    console.log(state)
    return new Promise((resolve, reject) => {
      axios
        .get(window.appApiUrl + "reserved/personPassport/" + data.id, {
          headers: {
            Authorization: "Bearer " + state.token,
          },
        })
        .then((res) => {
          console.log(res)
          resolve(res)
        })
        .catch((err) => {
          if (err.response.status == 401) {
            commit("clearForm")
            if (state.bookingId) {
              window.location.href = "/confirm/" + state.bookingId
            } else {
              window.location.href = "/error-page"
            }
          }
          console.log(err)
          reject(err)
        })
    })
  },
  guestGetPersonPassport({ state, commit }, data) {
    console.log(state)
    return new Promise((resolve, reject) => {
      axios
        .get(window.appApiUrl + "reserved/personPassport/" + data.id, {
          headers: {
            Authorization: "Bearer " + state.guestToken,
          },
        })
        .then((res) => {
          console.log(res)
          resolve(res)
        })
        .catch((err) => {
          if (err.response.status == 401) {
            commit("clearForm")
            if (state.guestReserve) {
              window.location.href =
                "/change-password/" +
                state.guestReserve.rsvId +
                "?userId=" +
                state.guestReserve.userId
            } else {
              window.location.href = "/error-page"
            }
          }
          console.log(err)
          reject(err)
        })
    })
  },

  //BOK009
  uploadPersonPassport({ state, commit }, data) {
    console.log(state)
    return new Promise((resolve, reject) => {
      axios
        .post(
          window.appApiUrl + "reserved/personPassport/" + data.id,
          data.form,
          {
            headers: {
              // 'Content-Type': 'multipart/form-data',
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + state.token,
            },
          }
        )
        .then((res) => {
          console.log(res)
          resolve(res)
        })
        .catch((err) => {
          if (err.response.status == 401) {
            commit("clearForm")
            if (state.reserve) {
              window.location.href = "/confirm/" + state.reserve.booking.id
            } else {
              window.location.href = "/error-page"
            }
          }
          console.log(err)
          reject(err)
        })
    })
  },

  guestUploadPersonPassport({ state, commit }, data) {
    console.log(state)
    return new Promise((resolve, reject) => {
      axios
        .post(
          window.appApiUrl + "reserved/personPassport/" + data.id,
          data.form,
          {
            headers: {
              // 'Content-Type': 'multipart/form-data',
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + state.guestToken,
            },
          }
        )
        .then((res) => {
          console.log(res)
          resolve(res)
        })
        .catch((err) => {
          if (err.response.status == 401) {
            commit("clearForm")
            if (state.guestReserve) {
              window.location.href =
                "/change-password/" +
                state.guestReserve.rsvId +
                "?userId=" +
                state.guestReserve.userId
            } else {
              window.location.href = "/error-page"
            }
          }
          console.log(err)
          reject(err)
        })
    })
  },

  // BOK004
  addReservation({ state, commit }, data) {
    console.log(state)
    return new Promise((resolve, reject) => {
      axios
        .post(window.appApiUrl + "reserved/person", data, {
          headers: {
            Authorization: "Bearer " + state.token,
          },
        })
        .then((res) => {
          commit("add_guest", res.data)
          resolve(res)
        })
        .catch((err) => {
          if (err.response.status == 401) {
            commit("clearForm")
            if (state.bookingId) {
              window.location.href = "/confirm/" + state.bookingId
            } else {
              window.location.href = "/error-page"
            }
          }
          console.log(err)
          reject(err)
        })
    })
  },

  // BOK005
  updateReservation({ state, commit }, data) {
    if (data.occupation) {
      data.occupation = parseInt(data.occupation)
    }
    if (data.birthDate == "Invalid date") {
      data.birthDate = null
    }
    if (data.gender) {
      data.gender = parseInt(data.gender)
    }
    return new Promise((resolve, reject) => {
      axios
        .put(
          window.appApiUrl + "reserved/person/" + data.id,
          {
            name: data.name || undefined,
            address: data.address || null,
            occupation: data.occupation || null,
            otherOccupationName: data.otherOccupationName,
            postcode: data.postcode || null,
            birthDate: data.birthDate || null,
            gender: data.gender || null,
            nickName: data.nickName || null,
            phone: data.phone || null,
            previousPlaceOfStay: data.previousPlaceOfStay || null,
            nextDestination: data.nextDestination || null,
          },
          {
            headers: {
              Authorization: "Bearer " + state.token,
            },
          }
        )
        .then((res) => {
          console.log(res)
          resolve(res)
        })
        .catch((err) => {
          if (err.response.status == 401) {
            commit("clearForm")
            if (state.bookingId) {
              window.location.href = "/confirm/" + state.bookingId
            } else {
              window.location.href = "/error-page"
            }
          }
          console.log(err)
          reject(err)
        })
    })
  },
  guestUpdateReservation({ state, commit }, data) {
    if (data.occupation) {
      data.occupation = parseInt(data.occupation)
    }

    if (data.gender) {
      data.gender = parseInt(data.gender)
    }

    console.log(state)
    return new Promise((resolve, reject) => {
      axios
        .put(
          window.appApiUrl + "reserved/person/" + data.id,
          {
            name: data.name || undefined,
            address: data.address || null,
            occupation: data.occupation || null,
            otherOccupationName: data.otherOccupationName,
            postcode: data.postcode || null,
            birthDate: data.birthDate || null,
            gender: data.gender || null,
            nickName: data.nickName || null,
            phone: data.phone || null,
            previousPlaceOfStay: data.previousPlaceOfStay || null,
            nextDestination: data.nextDestination || null,
          },
          {
            headers: {
              Authorization: "Bearer " + state.guestToken,
            },
          }
        )
        .then((res) => {
          console.log(res)
          // commit('setGuestCurrent', data)
          resolve(res)
        })
        .catch((err) => {
          if (err.response.status == 401) {
            commit("clearForm")
            if (state.guestReserve) {
              window.location.href =
                "/change-password/" +
                state.guestReserve.rsvId +
                "?userId=" +
                state.guestReserve.userId
            } else {
              window.location.href = "/error-page"
            }
          }
          console.log(err)
          reject(err)
        })
    })
  },

  // BOK006
  deletePerson({ state, commit }, data) {
    console.log(state)
    return new Promise((resolve, reject) => {
      axios
        .delete(window.appApiUrl + "reserved/person/" + data.id, {
          headers: {
            Authorization: "Bearer " + state.token,
          },
        })
        .then((res) => {
          if (res.data.deleted) {
            commit("removeGuest", data.id)
          }
          console.log(res)
          resolve(res)
        })
        .catch((err) => {
          if (err.response.status == 401) {
            commit("clearForm")
            if (state.bookingId) {
              window.location.href = "/confirm/" + state.bookingId
            } else {
              window.location.href = "/error-page"
            }
          }
          console.log(err)
          reject(err)
        })
    })
  },

  // BOK003
  personLogin({ commit }, data) {
    commit("guestLogout")
    return new Promise((resolve, reject) => {
      axios
        .post(window.appApiUrl + "reserved/personLogin/" + data.rsvId, data)
        .then((res) => {
          axios
            .get(window.appApiUrl + "reserved/user", {
              headers: {
                Authorization: "Bearer " + res.data.access_token,
              },
            })
            .then((resRsv) => {
              const persons = resRsv.data.persons
              const personItem = persons.find((item) => item.id == data.id)
              if (personItem) {
                console.log("xxx")
                commit("guestToken", {
                  id: data.id,
                  access_token: res.data.access_token,
                  ...personItem,
                })
                resolve(resRsv)
              } else {
                throw "Guest is not existed or deleted!"
              }
            })
            .catch((err) => {
              console.log(err)
              reject(err)
            })
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
    })
  },

  // BOK007
  personReset({ state }, data) {
    console.log(state)
    return new Promise((resolve, reject) => {
      axios
        .post(window.appApiUrl + "reserved/personReset/" + data.id, data)
        .then((res) => {
          console.log("api-log:", res)
          resolve(res)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
    })
  },

  // BOK0010
  checkLogin({ state }, data) {
    console.log(state)
    return new Promise((resolve, reject) => {
      axios
        .get(window.appApiUrl + "reserved/checkLogin/" + data.id, data)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
    })
  },

  // BOK0011
  getProperties({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(window.appApiUrl + "reserved/property/" + data.bookingId)
        .then((res) => {
          //   // THIS IS FOR TEST
          //   res.data.inputField.notOtherThanOwner = true
          //   // UNTIL HERE.
          commit("setProperties", res.data)
          resolve(res)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
    })
  },

  // Not using api
  async getUser({ state, dispatch, commit }, userId) {
    const guest = state.reserve_guests.find((guest) => guest.id == userId)
    if (guest && guest.password == null) {
      const resPersonReset = await dispatch("personReset", {
        id: userId,
        password: null,
      })
      commit("update_guest", resPersonReset.data)

      return resPersonReset.data
    } else {
      return guest
    }
  },

  //EXT007
  // eslint-disable-next-line
  detectPassport({ commit }, urlImage) {
    return new Promise((resolve, reject) => {
      axios
        .post(window.appApiUrl + "external/passportDetection", {
          url: window.appApiUrl + urlImage.split("/api/")[1],
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => reject(err))
    })
  },

  saveDefaultGuestInfo({ state }) {
    const saveProps = [
      "name",
      "birthday",
      "year",
      "month",
      "date",
      "gender",
      "email",
      "address",
      "postcode",
      "occupation",
    ]
    const toSave = saveProps.reduce((result, prop) => {
      result[prop] = state.currentUser[prop]
      return result
    }, {})

    localStorage.defaultGuestInfo = JSON.stringify(toSave)
  },

  loadDefaultGuestInfo({ commit, state }) {
    const data = localStorage.defaultGuestInfo
    if (data) {
      const info = JSON.parse(data)
      const copy = { ...state.currentUser }
      Object.keys(info).forEach((key) => {
        // if already has a value (input) use that
        if (info[key] && !copy[key]) {
          copy[key] = info[key]
        }
      })
      commit("setCurrentUser", copy)
    }
  },

  // BOK030
  guestUploadSignature({ state, commit }, { id, file }) {
    const form = new FormData()
    form.append("signatureImageFile", file)
    return new Promise((resolve, reject) => {
      axios
        .post(window.appApiUrl + "reserved/personSignature/" + id, form, {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + state.guestToken,
          },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          if (err.response.status == 401) {
            commit("clearForm")
            if (state.guestReserve) {
              window.location.href =
                "/change-password/" +
                state.guestReserve.rsvId +
                "?userId=" +
                state.guestReserve.userId
            } else {
              window.location.href = "/error-page"
            }
          }
          reject(err)
        })
    })
  },

  // BOK029
  downloadSignature({ state, commit }, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(window.appApiUrl + "reserved/personSignature/" + id, {
          responseType: "blob",
          headers: {
            // 'Content-Type': 'multipart/form-data',
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + state.guestToken,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          if (err.response.status == 401) {
            commit("clearForm")
            if (state.guestReserve) {
              window.location.href =
                "/change-password/" +
                state.guestReserve.rsvId +
                "?userId=" +
                state.guestReserve.userId
            } else {
              window.location.href = "/error-page"
            }
          }
          reject(err)
        })
    })
  },
}
