<template>
  <div class="custom-step">
    <v-stepper v-model="_currentStep">
      <div class="mt-2">
        <v-stepper-header
          class="mt-0 px-6"
          :class="{ 'hidden-step': !showHeader }"
          v-if="steps.length > 2"
        >
          <template v-for="(item, index) in steps">
            <v-divider v-if="index > 0" :key="`divider-${index}`"></v-divider>
            <v-stepper-step
              :key="`stepper-${index}`"
              :step="index + 1"
              :editable="getEditable(index)"
            />
          </template>
        </v-stepper-header>
      </div>

      <v-stepper-items>
        <template v-for="(item, index) in steps">
          <v-stepper-content
            :step="index + 1"
            :key="`step-item-${index}`"
            class="pa-0"
          >
            <component
              :is="item.component"
              @next-step="handleNextStep(index + 2)"
              :form="Object.assign({}, guest)"
              :inputField="inputField"
              :visible="index + 1 === _currentStep"
            />
          </v-stepper-content>
        </template>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import GuestUploadPassport from "@/components/stepper/GuestStep/GuestUploadPassport"
import { mapState } from "vuex"

export default {
  name: "InfoStep",
  components: {
    GuestUploadPassport,
    GuestPassword: () => import("@/components/stepper/GuestStep/GuestPassword"),
    GuestName: () => import("@/components/stepper/GuestStep/GuestName"),
    GuestAddress: () => import("@/components/stepper/GuestStep/GuestAddress"),
    GuestPhone: () => import("@/components/stepper/GuestStep/GuestPhone"),
    GuestOccupation: () =>
      import("@/components/stepper/GuestStep/GuestOccupation"),
    GuestBirthDay: () => import("@/components/stepper/GuestStep/GuestBirthDay"),
    GuestGender: () => import("@/components/stepper/GuestStep/GuestGender"),
    GuestReiviewRegisted: () =>
      import("@/components/stepper/GuestStep/GuestReiviewRegisted"),
  },
  data() {
    return {
      checkinStep: 1,
      objStep: {
        next1: true,
        next2: true,
        next3: false,
        next4: false,
        next5: false,
        next6: false,
        next7: false,
        next8: false,
      },
    }
  },
  computed: {
    ...mapState({
      breakWidth: (state) => state.breakWidth,
      reserve_user: (state) => state.reserve_user,
      reserve_guests: (state) => state.reserve_guests,
      guest: (state) => state.guest,
      currentStep: (state) => state.step,
      inputField: (state) => state.settings.inputField,
      showHeader: (state) => state.showHeader,
    }),
    _currentStep: {
      get() {
        return this.currentStep
      },

      set(val) {
        this.$emit("change", val)
        this.$store.commit("setStep", val)
      },
    },
    steps() {
      const steps = [this.stepPassport, this.stepPassword, this.stepName]
      steps.push(this.stepAddress)
      if (this.inputField.birthDate) steps.push(this.stepBirthDate)
      if (this.inputField.gender) steps.push(this.stepGender)
      if (this.inputField.phone) steps.push(this.stepPhone)
      if (this.inputField.occupation) steps.push(this.stepOccupation)

      steps.push(this.stepFinished)
      return steps
    },
    stepPassport() {
      return {
        component: "GuestUploadPassport",
        check: "passportImageUrl",
      }
    },
    stepPassword() {
      return {
        component: "GuestPassword",
        check: null,
      }
    },
    stepName() {
      return {
        component: "GuestName",
        check: "name",
      }
    },
    stepAddress() {
      return {
        component: "GuestAddress",
        check: "address",
      }
    },
    stepBirthDate() {
      return {
        component: "GuestBirthDay",
        check: "birthDate",
      }
    },
    stepGender() {
      return {
        component: "GuestGender",
        check: "gender",
      }
    },
    stepPhone() {
      return {
        component: "GuestPhone",
        check: "phone",
      }
    },
    stepOccupation() {
      return {
        component: "GuestOccupation",
        check: "occupation",
      }
    },
    stepFinished() {
      return {
        component: "GuestReiviewRegisted",
      }
    },

    sizePc() {
      return this.breakWidth >= 600
    },
  },
  mounted() {
    this.$store.commit("setStep", 1)
  },
  methods: {
    handleNextStep(step) {
      this.checkinStep = step
      this.$store.commit("setStep", step)
      this.$set(this.objStep, `next${step}`, true)
    },

    getEditable(index) {
      if (this.objStep[`next${index + 1}`]) return true
      // check all the input before this index is completed.
      for (let i = 0; i < index; i++) {
        const step = this.steps[i]
        if (!step.check) continue
        else if (typeof step.check === "string") {
          if (!this.guest[step.check]) {
            return false // this input is not completed yet
          }
        } else {
          // if neede support check = function style check
          throw Error("not implemented")
        }
      }
      return true
    },
  },
}
</script>
<style scoped lang="scss">
.hidden-step {
  opacity: 0;
  cursor: default;
  .v-stepper__step {
    cursor: default;
  }
}
</style>
