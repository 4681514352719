<template>
  <v-app>
    <core-multi-lang  :number-step="step" :back-path="getEnterCreateAddressScreenUrl" />
<v-container
    fill-height
    fluid
    grid-list-xl
    class="main-layout_inner"
  >
    <v-layout
      justify-center
      wrap
    >
      <!-- inline edit table -->
      <v-flex xs12 class="pt-0">

        <v-form v-if="form">
          <v-flex xs12>
            <h3 class="main_title">{{ $t('Checkin.please-select-the-guest-occupation') }}</h3>

            <v-row
              align="center"
              justify="space-around"
              class="mx-auto mt-5 px-0"
            >

            <div class="radio-block">
              <div v-bind:key="careerIndex" v-for="(careerItem, careerIndex) in careerItems" class="career-item" >
                <input type="radio"
                       :id="'radio-career-' + careerIndex" name="radio-career"
                       :value="careerItem.value"
                       v-model="form.occupation"
                >
                <label :for="'radio-career-' + careerIndex" v-bind:style="{ 'background-color' : ( (careerItem.value == form.occupation) ? '#ccc' : '#616e7e')}">{{ $t('Checkin.' + careerItem.label) }}</label>
              </div>
            </div>
            </v-row>
          </v-flex>
          <v-flex v-if="form.occupation == 3">
            <v-text-field
              v-model="otherOccupationName"
              class="purple-input"
              :label="$t('Checkin.other-optional')"
            />
          </v-flex>
          </v-form>
        </v-flex>
      </v-layout>
      <div class="main_btn">
              <v-btn
                class="btn-custom"
                v-on:click="handleSubmitOccupation()"
                v-bind:disabled="!valid"
                v-bind:class="
                  valid ? 'btn-submit-green' : 'btn-submit-green-disabled'
                "
                >{{ $t("Checkin.next") }}</v-btn
              >
            </div>
    </v-container>
  </v-app>
</template>

<style lang="scss">
.radio-block {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.radio-block .career-item {
  padding: 0 12px;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .radio-block .career-item {
    padding: 0 10px;
  }
}

.radio-block input[type="radio"] {
  opacity: 0;
  width: 0;
}

.radio-block label {
  display: inline-block;
  background-color: #616e7e;
  padding: 10px 15px;
  font-size: 17px;
  color: #f3f4f6;
  border-radius: 999px;
}

.radio-block label:hover {
  background-color: #ccc;
}

.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  color: #fff !important;
}

.btn-submit-green-disabled {
  width: 100%;
  background-color: #e0e0e0 !important;
  color: #f3f3f3;
}

</style>

<script>
import StringUtil from "@/shared/StringUtil";
import { mapState, mapGetters } from "vuex";

export default {
  data: () => ({
    form: null,
    step: '4/5',
    valid: false,
    careerItems: [
      {
        label : 'company-employee',
        value : 1,
      },
      {
        label : 'business-owner',
        value : 2,
      },
      {
        label : 'other',
        value : 3,
      },
    ],

    // from url params
    isGuest: false,
    userId: null,
    addParams: "",
  }),
  watch: {
    occupation(value) {
      if (value) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
    lang(val){
      this.handleOccupationGrid(val)
    }
  },

  computed: {
    getEnterReviewRegisteredScreenUrl() {
      return "review-registered";
    },

    getEnterCreateAddressScreenUrl() {
      return "checkin-create-address" + this.addParams;
    },

    getEnterFinishRegisteredScreenUrl() {
      return "finish-registered";
    },
    ...mapGetters(['lang']),
    ...mapState(["reserve_guests", "reserve_user"]),

    occupation() {
      if (this.form) {
        return parseInt(this.form.occupation);
      } else {
        return null;
      }
    },
    otherOccupationName: {
      get() {
        if (this.form){
          return this.form.otherOccupationName
        } else {
          return null
        }
      },
      set(value) {
        if (value) {
          this.form.otherOccupationName = value
        } else {
          this.form.otherOccupationName = null
        }
      }
    }
  },

  created() {
    const isGuest = StringUtil.getParamData("isGuest") === "true";
    const userId = StringUtil.getParamData("userId");
    this.isGuest = isGuest;
    this.userId = userId;

    this.addParams = "?isGuest=" + isGuest;

    if (!isGuest) {
      this.fetchUser();
    } else {
      this.addParams = this.addParams + "&userId=" + this.userId;
      this.fetchGuest();
    }
  },

  mounted() {
    this.handleOccupationGrid(this.$i18n.locale)
  },
  updated(){
    this.handleOccupationGrid(this.$i18n.locale)
  },

  methods: {
    handleOccupationGrid(lang){
      var lists = document.getElementsByClassName('career-item')
      if(lists.length){
        for(let item of lists){
          item.style.minWidth = 'auto'
        }
      }

      if(lang !== 'ja'){
        var width = 0
        for(let item of lists){
          if(item.offsetWidth > width){
            width = item.offsetWidth
          }
        }

        console.log(width)
        for(let item of lists){
          item.style.minWidth = width + 'px'
        }
      }else{
        for(let item of lists){
          item.style.minWidth = 'auto'
        }
      }
    },

    handleSubmitOccupation() {
      if (this.isGuest) {
        this.submitOccupationForGuest();
      } else {
        this.submitOccupation();
      }
    },

    submitOccupation(){
      this.$store.dispatch('updateReservation', this.form).then(() => {
        this.$store.commit('update_user', this.form)
        this.$router.push(this.getEnterFinishRegisteredScreenUrl + this.addParams)
        console.log('success')
      }).catch( err => {
        console.log(err.response)
      })
    },

    submitOccupationForGuest() {
      // update api
      this.$store.dispatch('updateReservation', this.form).then(() => {
        this.$store.commit('update_guest', this.form) // update state
        this.$router.push(this.getEnterFinishRegisteredScreenUrl + this.addParams)
      }).catch( err => {
        console.log(err.response)
      })
    },

    fetchUser() {
      this.form = this.$store.state.reserve_user;
      if(this.form){
        this.userId = this.form.id;
      }else{
        console.log('User logout', this.form)
      }
    },

    async fetchGuest() {
      this.form = await this.$store.dispatch("getUser", this.userId);
      if(!this.form){
        console.log('Guest is not existed or deleted:', this.form)
      }
    },
  },
};
</script>

<style lang="scss"></style>
