<template>
  <v-container fill-height fluid grid-list-xl class="main-layout_inner_second">
    <v-layout justify-center wrap>
      <!-- inline edit table -->
      <v-flex xs12 class="pt-0">
        <v-form v-if="form">
          <v-flex xs12>
            <h3 class="main_title">
              {{ $t("Checkin.please-select-the-guest-occupation") }}
            </h3>

            <v-row
              align="center"
              justify="space-around"
              class="mx-auto mt-5 px-0"
            >
              <div class="radio-block">
                <div
                  :key="careerIndex"
                  v-for="(careerItem, careerIndex) in careerItems"
                  class="career-item"
                >
                  <input
                    type="radio"
                    :id="'radio-career-' + careerIndex"
                    name="radio-career"
                    :value="careerItem.value"
                    v-model="newForm.occupation"
                  />
                  <label
                    :for="'radio-career-' + careerIndex"
                    :style="{
                      'background-color':
                        careerItem.value == newForm.occupation
                          ? '#ccc'
                          : '#616e7e',
                      color: '#FFFFFF',
                    }"
                    >{{ $t("Checkin." + careerItem.label) }}</label
                  >
                </div>
              </div>
            </v-row>
          </v-flex>
          <v-flex v-if="newForm.occupation === 3">
            <v-text-field
              v-model="otherOccupationName"
              class="purple-input"
              :label="$t('Checkin.other-optional')"
            />
          </v-flex>
        </v-form>
      </v-flex>
    </v-layout>
    <div class="main_btn">
      <v-btn
        style="color: #ffffff !important"
        class="btn-custom"
        @click="handleSubmitOccupation()"
        :loading="loading"
        :disabled="!newForm.occupation"
        :class="
          newForm.occupation ? 'btn-submit-green' : 'btn-submit-green-disabled'
        "
        >{{ $t("Checkin.next") }}</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex"

export default {
  name: "CreateCareer",
  props: {
    form: {
      type: Object,
      default: () => {},
      required: true,
    },
    isGuest: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    userId: {
      type: String,
      default: () => null,
      required: true,
    },
  },
  data: () => ({
    newForm: {},
    loading: false,
    valid: false,
    careerItems: [
      {
        label: "company-employee",
        value: 1,
      },
      {
        label: "business-owner",
        value: 2,
      },
      {
        label: "other",
        value: 3,
      },
    ],
    addParams: "",
  }),

  computed: {
    getEnterReviewRegisteredScreenUrl() {
      return "review-registered"
    },

    getEnterCreateAddressScreenUrl() {
      return "checkin-create-address" + this.addParams
    },

    getEnterFinishRegisteredScreenUrl() {
      return "finish-registered"
    },
    ...mapGetters(["lang"]),
    ...mapState(["reserve_guests", "reserve_user", "currentUser"]),

    occupation() {
      if (this.newForm) {
        return parseInt(this.newForm.occupation)
      } else {
        return null
      }
    },
    otherOccupationName: {
      get() {
        if (this.newForm){
          return this.newForm.otherOccupationName
        } else {
          return null
        }
      },
      set(value) {
        if (value) {
          this.newForm.otherOccupationName = value
        } else {
          this.newForm.otherOccupationName = null
        }
      }
    }
  },

  watch: {
    occupation(value) {
      if (value) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
    lang(val) {
      this.handleOccupationGrid(val)
    },
  },

  mounted() {
    this.handleOccupationGrid(this.$i18n.locale)
    this.newForm = Object.assign({}, this.newForm, this.form)
  },
  updated() {
    this.handleOccupationGrid(this.$i18n.locale)
  },

  methods: {
    handleOccupationGrid(lang) {
      let lists = document.getElementsByClassName("career-item")
      if (lists.length) {
        for (let item of lists) {
          item.style.minWidth = "auto"
        }
      }

      if (lang !== "ja") {
        let width = 0
        for (let item of lists) {
          if (item.offsetWidth > width) {
            width = item.offsetWidth
          }
        }

        console.log(width)
        for (let item of lists) {
          item.style.minWidth = width + "px"
        }
      } else {
        for (let item of lists) {
          item.style.minWidth = "auto"
        }
      }
    },

    handleSubmitOccupation() {
      if (this.isGuest) {
        this.submitOccupationForGuest()
      } else {
        this.submitOccupation()
      }
    },

    submitOccupation() {
      this.loading = true
      this.$store
        .dispatch("updateReservation", {
          ...this.currentUser,
          occupation: this.newForm.occupation,
          otherOccupationName: this.newForm.otherOccupationName
        })
        .then(() => {
          this.$store.commit("update_user", {
            ...this.currentUser,
            occupation: this.newForm.occupation,
            otherOccupationName: this.newForm.otherOccupationName
          })
          this.$store.commit("setCurrentUser", {
            ...this.currentUser,
            occupation: this.newForm.occupation,
            otherOccupationName: this.newForm.otherOccupationName
          })
          // this.$router.push(
          //     this.getEnterCreateAddressScreenUrl + this.addParams
          // );
          this.loading = false
          this.$emit("next-step")
        })
        .catch((err) => {
          console.log(err.response)
        })
    },

    submitOccupationForGuest() {
      // update api
      this.loading = true
      this.$store
        .dispatch("updateReservation", {
          ...this.currentUser,
          occupation: this.newForm.occupation,
          otherOccupationName: this.newForm.otherOccupationName
        })
        .then(() => {
          // this.$store.commit("update_guest", {...this.form, occupation:this.form.occupation}); // update state
          // this.$router.push(this.getEnterFinishRegisteredScreenUrl + this.addParams)
          const filterIndex = this.reserve_guests.findIndex(
            (item) => item.id === this.userId
          )
          let newList = [...this.reserve_guests]
          for (let i = 0; i < newList.length; i++) {
            newList[filterIndex].occupation = this.newForm.occupation
            newList[filterIndex].otherOccupationName = this.newForm.otherOccupationName
          }
          this.$store.commit("setListGuest", newList)
          this.$store.commit("setCurrentUser", {
            ...this.currentUser,
            occupation: this.newForm.occupation,
            otherOccupationName: this.newForm.otherOccupationName
          })
          this.loading = false
          this.$emit("next-step")
        })
        .catch((err) => {
          console.log(err.response)
        })
    },
  },
}
</script>

<style lang="scss">
.radio-block {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.radio-block .career-item {
  padding: 0 12px;
  text-align: center;
  white-space: nowrap;
}
@media (max-width: 576px) {
  .radio-block .career-item {
    padding: 0 10px;
  }
}

.radio-block input[type="radio"] {
  opacity: 0;
  width: 0;
}

.radio-block label {
  display: inline-block;
  background-color: #616e7e;
  padding: 10px 15px;
  font-size: 17px;
  color: #f3f4f6;
  border-radius: 999px;
}

.radio-block label:hover {
  background-color: #ccc;
}

.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  color: #fff !important;
}

.btn-submit-green-disabled {
  width: 100%;
  background-color: #e0e0e0 !important;
  color: #f3f3f3;
}
</style>
