<template>
  <v-container fill-height fluid grid-list-xl class="main-layout_inner_second">
    <v-layout justify-center wrap>
      <!-- inline edit table -->
      <v-flex xs12>
        <v-form v-if="form">
          <v-flex xs12>
            <h3 class="main_title">
              {{ $t("Checkin.please-select-the-guest-occupation") }}
            </h3>

            <v-row
              align="center"
              justify="space-around"
              class="mx-auto mt-5 px-0"
            >
              <div class="radio-block">
                <div
                  xs4
                  sm4
                  v-bind:key="careerIndex"
                  v-for="(careerItem, careerIndex) in careerItems"
                  class="career-item"
                >
                  <input
                    type="radio"
                    :id="'radio-career-' + careerIndex"
                    name="radioCareer"
                    :value="careerIndex"
                    v-model="newForm.occupation"
                  />
                  <label
                    :for="'radio-career-' + careerIndex"
                    :style="{
                      'background-color':
                        careerIndex == newForm.occupation ? '#ccc' : '#616e7e',
                      color: '#FFFFFF',
                    }"
                    >{{ $t("Checkin." + careerItem) }}</label
                  >
                </div>
              </div>
            </v-row>
          </v-flex>
          <v-flex v-if="newForm.occupation == 3">
            <v-text-field
              v-model="otherOccupationName"
              class="purple-input"
              :label="$t('Checkin.other-optional')"
            />
          </v-flex>
        </v-form>
      </v-flex>
    </v-layout>
    <div class="main_btn">
      <v-btn
        style="color: #FFFFFF !important;"
        class="btn-custom w-100"
        @click="handleSubmit()"
        :loading="loading"
        :disabled="!newForm.occupation"
        :class="newForm.occupation ? 'btn-custom-main' : 'btn-custom-disabled'"
        >{{ $t("Checkin.next") }}</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex"

export default {
  name: "GuestOccupation",
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    // form: null,
    newForm: {},
    loading: false,
    valid: false,
    careerItems: { 1: "company-employee", 2: "business-owner", 3: "other" },
  }),

  computed: {
    getEnterReviewRegisteredScreenUrl() {
      return "review-registered"
    },

    getGuestEnterCreateAddressScreenUrl() {
      return "guest-checkin-create-address"
    },

    getEnterFinishRegisteredScreenUrl() {
      return "finish-registered"
    },

    ...mapState(["reserve_guests", "guest"]),
    ...mapGetters(["lang"]),

    occupation() {
      if (this.newForm) {
        return parseInt(this.newForm.occupation)
      } else {
        return null
      }
    },
    otherOccupationName: {
      get() {
        if (this.newForm) {
          return this.newForm.otherOccupationName
        } else {
          return null
        }
      },
      set(value) {
        if (value) {
          this.newForm.otherOccupationName = value
        } else {
          this.newForm.otherOccupationName = null
        }
      },
    },
  },
  watch: {
    occupation(value) {
      if (value) {
        this.valid = true
      } else {
        this.valid = false
      }
    },

    lang(val) {
      this.handleOccupationGrid(val)
    },
  },
  created() {},

  mounted() {
    this.fetchGuestId()
    this.handleOccupationGrid(this.$i18n.locale)
    this.newForm = Object.assign({}, this.newForm, this.form)
  },

  updated() {
    this.handleOccupationGrid(this.$i18n.locale)
  },

  methods: {
    handleOccupationGrid(lang) {
      var lists = document.getElementsByClassName("career-item")
      if (lists.length) {
        for (let item of lists) {
          item.style.minWidth = "auto"
        }
      }

      if (lang !== "ja") {
        var width = 0
        for (let item of lists) {
          if (item.offsetWidth > width) {
            width = item.offsetWidth
          }
        }

        console.log(width)
        for (let item of lists) {
          item.style.minWidth = width + "px"
        }
      } else {
        for (let item of lists) {
          item.style.minWidth = "auto"
        }
      }
    },

    handleSubmit() {
      this.loading = true
      this.newForm.occupation = this.newForm.occupation
        ? parseInt(this.newForm.occupation)
        : null
      this.$store
        .dispatch("guestUpdateReservation", {
          ...this.form,
          occupation: this.newForm.occupation,
          otherOccupationName: this.newForm.otherOccupationName,
        })
        .then((res) => {
          // this.$router.push(this.getEnterReviewRegisteredScreenUrl)
          this.$store.commit("setGuestCurrent", {
            ...this.$store.state.guest,
            occupation: res.data.occupation,
            otherOccupationName: this.newForm.otherOccupationName,
          })
          this.loading = false
          this.$emit("next-step")
        })
        .catch((err) => {
          console.log(err)
        })
    },

    fetchGuestId() {
      // this.form = this.$store.state.guest;
      this.userId = this.form.id
    },
  },
}
</script>

<style lang="scss">
.radio-toolbar {
  margin: 10px;
}

.radio-toolbar .career-item {
  float: left;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  background-color: #616e7e;
  padding: 10px 20px;
  font-family: sans-serif, Arial;
  font-size: 12px;
  color: #f3f4f6;
  border-top-right-radius: 20% 50%;
  border-bottom-right-radius: 20% 50%;

  border-top-left-radius: 20% 50%;
  border-bottom-left-radius: 20% 50%;
}

.radio-toolbar label:hover {
  background-color: #ccc;
}

.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  color: #fff !important;
}

.btn-submit-green-disabled {
  width: 100%;
  background-color: #e0e0e0 !important;
  color: #ffffff !important;
}
</style>
