<template>
  <div>
    <v-dialog v-model="input" transition="out">
      <template v-slot:activator="{ on, attrs }">
        <img v-on="on" v-bind="attrs" :src="imgSrc" height="200" width="100%" class="signature-img"/>
      </template>
      <template v-slot:default>
        <v-card>
          <v-card-title>
            <div>{{ $t('Checkin.signature') }}</div>
            <v-spacer />
            <v-btn small @click="clear">やり直し</v-btn>
          </v-card-title>
          <v-card-text>
            <canvas ref="canvas" 
              class="signature-canvas"
              @mousedown="dragStart"
              @mousemove="draw"
              @mouseup="dragEnd"
              @touchstart="touchDragStart"
              @touchmove="touchDraw"
              @touchend="touchDragEnd"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancel">キャンセル</v-btn>
            <v-btn color="primary" @click="confirm">確定</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    file: { type: [String, File, Blob], default: null }
  },
  data() {
    return {
      input: false,
      imgSrc: '',
      context: null,
      isDrag: false,
    }
  },
  computed: {
    canvas () {
      return this.$refs.canvas;
    }
  },
  watch: {
    file: {
      immediate: true,
      handler () {
        this.imgSrc = null;

        if (typeof this.file === 'string') {
          // this is the data url, so just return it
          this.imgSrc = this.file
        } else if (this.file instanceof File || this.file instanceof Blob) {
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            this.imgSrc = reader.result;
          })
          reader.readAsDataURL(this.file);
        }
      }
    },
    async input () {
      if (this.input) {
        await this.$nextTick()
        this.clear();
      }
    }
  },
  methods: {
    getImageFile () {
      return new Promise(resolve => {
        this.canvas.toBlob(blob => {
          const file = new File([blob], "signature.png", { type: "image/png" })
          resolve(file);
        }, 'image/png')
      })
    },
    clear () {
      const canvas = this.canvas;
      this.context = canvas.getContext('2d')
      this.context.clearRect(0, 0, canvas.width, canvas.height);
      this.context = this.canvas.getContext('2d')
      this.context.lineCap = 'round'
      this.context.lineJoin = 'round'
      this.context.lineWidth = 1
      this.context.strokeStyle = '#000000'
      this.context.fillStyle = '#ffffff'
      this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)
    },
    async confirm () {
      const file = await this.getImageFile();
      this.$emit('confirm', {
        file
      })
      this.input = false
    },
    cancel () {
      this.input = false
    },

    dragStart(e) {
      e.preventDefault()
      const x = e.layerX
      const y = e.layerY

      this.context.beginPath()
      this.context.lineTo(x, y)
      this.context.stroke()

      this.isDrag = true
    },
    draw(e) {
      const x = e.layerX
      const y = e.layerY

      if (!this.isDrag) {
        return
      }

      this.context.lineTo(x, y)
      this.context.stroke()
    },
    dragEnd() {
      this.context.closePath()
      this.isDrag = false
    },
    touchDragStart(e) {
      e.preventDefault()

      const clientRect = this.canvas
        .getBoundingClientRect()
      const touchEvent = e.touches[0] || e.changedTouches[0]
      const x = touchEvent.clientX - clientRect.left - 1
      const y = touchEvent.clientY - clientRect.top - 1

      this.context.beginPath()
      this.context.lineTo(x, y)
      this.context.stroke()

      this.isDrag = true
    },
    touchDraw(e) {
      const clientRect = this.canvas
        .getBoundingClientRect()
      const touchEvent = e.touches[0] || e.changedTouches[0]
      const x = touchEvent.clientX - clientRect.left - 1
      const y = touchEvent.clientY - clientRect.top - 1

      if (!this.isDrag) {
        return
      }

      this.context.lineTo(x, y)
      this.context.stroke()
    },
    touchDragEnd() {
      this.context.closePath()
      this.isDrag = false
    },
  }
}
</script>

<style lang="scss" scoped>
.signature-img {
  border: 1px solid black;
}

.signature-canvas {
  border: 1px solid black;
}
</style>