<template>
  <div>
  <div class="main_intro">
    <div class="main_intro_inner">
      <div class="main_intro_head">
        <img :src="iconConfirmScreenUrl" alt="">
        <h3 v-if="settings" class="main_intro_title">{{ settings.name }}</h3>
      </div>
      <p class="main_intro_desc" v-html="$t('Checkin.this-site-uses-a-layout-designed-for-smartphones')"></p>
      <a href="javascript:void(0)" v-on:click="handleToCheckinLoginScreen()" class="main_intro_link">
        {{ $t('Checkin.continue-in-browser')}}
      </a>
    </div>
  </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    data: () => ({
      iconConfirmScreenUrl : require('@/assets/img/icon-checkin-change.png'),
      bookingId : null,
    }),

    computed: {
      ...mapState(['settings'])
    },
    watch: {

    },

    beforeCreate(){
    },

    created () {
      this.bookingId = this.$route.params.bookingId
    },

    async mounted(){
      await this.$store.commit('clearForm')
      await this.$store.dispatch('getProperties', { bookingId : this.bookingId})

      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // true for mobile device
        console.log('this is mobile')
        this.$router.push({ name : 'CheckinDaihyoPassword', query : {rsvId : this.bookingId, email : this.email}})
      }else{
        // false for not mobile device
        console.log('not mobile')
      }
    },

    methods: {
      handleToCheckinLoginScreen(){
        this.$router.push({ name : 'CheckinDaihyoPassword', query : {rsvId : this.bookingId,}})
      },
    }
  }
</script>

<style lang="scss">
</style>
