<template>
  <v-bottom-sheet
    v-model="_dialog"
    persistent
    no-click-animation
    content-class="bts-class"
  >
    <v-sheet class="content-bts">
      <v-container
        fill-height
        fluid
        grid-list-xl
        class="main-layout_content"
        style="overflow-y: auto"
      >
        <v-layout justify-center wrap>
          <v-flex xs12 class="content-form">
            <div class="main_content d-flex flex-column">
              <img
                src="../../assets/img/icon-close.png"
                @click="_iconClick"
                class="align-self-end ic-close"
              />
              <v-flex>
                 <h3 class="main_title">
                {{ $t("Checkin.please-enter-nick-name") }}
              </h3>

              <p class="sub-title pb-5 mt-3">
                {{ $t("Checkin.nick-name-description") }}
              </p>
              </v-flex>
             
              <slot name="bodysheet"></slot>
            </div>
          </v-flex>
        </v-layout>
        <div class="bottom-button">
          <v-btn class="btn-custom btn-custom-default" @click="_submitOne">
            <slot style="color: #616e7e !important">{{ buttonTextOne }} </slot>
          </v-btn>
          <v-btn
            :disabled="disabledTwo"
            :class="[
              'btn-custom',
              !disabledTwo ? 'btn-submit-green' : 'btn-submit-green-disabled',
            ]"
            @click="_submitTwo"
            :loading="loading"
          >
            {{ buttonTextTwo }}
          </v-btn>
        </div>
      </v-container>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: "MyBottomSheet",
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    disabledOne: {
      type: Boolean,
      default: () => false,
    },
    disabledTwo: {
      type: Boolean,
      default: () => false,
    },

    buttonTextOne: {
      type: String,
      default: () => "",
    },
    buttonTextTwo: {
      type: String,
      default: () => "",
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    _dialog() {
      return this.dialog;
    },
  },

  methods: {
    _iconClick() {
      this.$emit("icon-close");
    },

    _submitOne() {
      this.$emit("submit-one");
    },

    _submitTwo() {
      this.$emit("submit-two");
    },
  },
};
</script>

<style scoped lang="scss">
.bts-class {
  overflow-y: auto;
  .content-bts {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .ic-close {
      cursor: pointer;
    }
  }
  .content-form {
    height: 60vh;
  }

  .bottom-button {
    padding: 12px 12px 30px;
    width: 100%;
  }
  .btn-submit-green {
    width: 100%;
    background-color: rgb(97 110 126) !important;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #fff;
  }

  .btn-submit-green-disabled {
    width: 100%;
    background-color: #e0e0e0 !important;
    color: #f3f3f3;
    height: 43px !important;
  }

  .btn-custom-default {
    min-height: 48px !important;
    height: auto !important;
    padding: 3px 8px !important;
    margin-bottom: 1.5rem !important;
  }
}
</style>
