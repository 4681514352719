<template>
  <header class="header">
    <v-btn
      v-if="backPath != '' || isNormal"
      @click="handleBackToCheckinInfo()"
      class="header_back"
      ><v-icon>mdi-arrow-left</v-icon></v-btn
    >
    <div class="header_wrap">
      <h2 class="header_title" v-if="settings">
        {{ settings.name }}
      </h2>
    </div>
    <v-select
      @input="handleSelectedLang()"
      v-model="langSelected"
      :items="langs"
      item-text="label"
      item-value="value"
      class="header_lang"
    ></v-select>
  </header>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: {
    numberStep: {
      type: [String, Boolean],
      default: false,
    },
    backPath: {
      type: String,
      default: "",
      required: false,
    },
    isNormal:{
      type:Boolean,
      default:false
    }
  },
  data: () => ({
    langSelected: "ja",
    langs: [
      {
        label: "日本語",
        value: "ja",
      },
      {
        label: "ENGLISH",
        value: "en",
      },
      {
        label: "简体中文",
        value: "cn",
      },
      {
        label: "繁體中文",
        value: "tw",
      },
      {
        label: "한국",
        value: "ko",
      },
      {
        label: "ภาษาไทย",
        value: "th",
      },
      {
        label: "Tiếng việt",
        value: "vi",
      },
      {
        label: "indonesia",
        value: "id",
      },
      {
        label: "Deutsch",
        value: "de",
      },
      {
        label: "français",
        value: "fr",
      },
      {
        label: "Español",
        value: "es",
      },
      {
        label: "русский",
        value: "ru",
      },
      {
        label: "عربي",
        value: "ar",
      },
      {
        label: "Português",
        value: "pt",
      },      
    ],
  }),
  watch: {
    // langSelected(val) {
    //   this.$i18n.locale = val;
    //   this.setLang(val);
    // },
  },
  computed: {
    ...mapGetters(["settings", "lang"]),
    ...mapState({
      step:state => state.step
    })
  },
  mounted() {
    this.langSelected = this.lang;
  },
  methods: {
    // ...mapMutations(["setLang"]),
    handleSelectedLang() {
      this.$i18n.locale = this.langSelected;
      this.$store.commit('setLang',this.langSelected)
      console.log(this.langSelected);
      this.$forceUpdate();
    },
    handleBackToCheckinInfo() {
    if(this.isNormal) {
      this.$router.back();
    } else {
      if(this.step > 1) {
        this.$store.commit('setStep', this.step - 1);
      } else {
        this.$router.push(this.backPath);
      }
    }
    },
  },
};
</script>
<style>
@media (min-width: 768px) {
  .v-menu__content {
    left: calc(100% - 115px) !important;
    transform: translateY(-80px);
  }
}
</style>
<style lang="scss" scope>
.header_wrap::webkit-scrollbar{
  width: 2px !important;
  height: 2px !important;
}
.v-avatar,
.v-responsive {
  cursor: pointer;
}
.title-page {
  font-weight: 400 !important;
  text-align: center;
  font-size: 25px !important;
  margin-bottom: 0px !important;
}

.language-select-box {
  right: 0;
  position: absolute;
  max-width: 82px;
  padding-right: 0px;
  top: 0px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 30%), 0 0 40px rgb(0 0 0 / 10%);
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%), 0 0 40px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 1px 4px rgb(0 0 0 / 30%), 0 0 40px rgb(0 0 0 / 10%);
}
.language-select-box select {
  padding: 18px;
  width: 60px;
  height: 60px;
}
.language-select-box select.selector {
}

.language-select-box select option {
  background: #fff;
  color: red;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.language-select-box select option span {
  color: red;
}
</style>
