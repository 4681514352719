<template>
  <component :is="Com" @next-step="nextStep"></component>
</template>

<script>
import { mapState } from "vuex";

import DaihyoIntro from "@/components/fixed/DaihyoIntro";
export default {
  data: () => ({
    iconConfirmScreenUrl : require('@/assets/img/icon-checkin-change.png'),
    email: null,
    rsvId: null,
    tab: "DaihyoIntro",
    Com: DaihyoIntro,
  }),

  computed: {
    loadMainTab() {
      return () =>
        import(
          /* webpackChunkName: "main-[request]" */ `@/components/fixed/${this.tab}.vue`
        );
    },
    passwordVerifyRules() {
      return [
        (v) => !!v || this.$t("Checkin.the-following-field-is-required"),
        (v) =>
          (v && v.length <= 10) ||
          this.$t(
            "Checkin.please-enter-your-password-maximum-of-10-characters"
          ),
        (v) =>
          this.checkSpecificKey(v) ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
      ];
    },
    ...mapState(["settings", "authStatus", "isPc", "isPcConfirmed"]),
  },

  created() {
   
  },

  mounted() {
     this.$store.commit("clearForm");
    this.rsvId = this.$route.params.bookingId;
    this.$store.commit("setBookingId", this.rsvId);

    let isPc = true;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      isPc = false;
    }

    if (isPc) {
      if (this.isPcConfirmed) {
        if (!this.isPc) {
          this.$store.commit("isPc", true);
        }

        this.tab = "DaihyoIntro";
      } else {
        this.tab = "DaihyoLanding";
      }
    } else {
      this.$store.commit("isPc", false);
      this.$store.commit("isPcConfirmed", false);
      this.tab = "DaihyoIntro";
    }
    
    this.loadCom();
  },

  methods: {
    loadCom() {
      this.loadMainTab().then(({ default: COM }) => {
        console.log('comm',COM)
        this.Com = COM;
      });
    },

    nextStep(e) {
      this.tab = e;
      console.log(e)
      this.loadCom();
    },
  },
};
</script>
