/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  // add any extra routes that you want rendered in the dashboard as a child below. Change toolbar names here
  {
    path: "/",
    meta: {
      title: "事前チェックインシステム",
    },
    component: () => import(`@/views/Landing.vue`),
  },
  {
    path: "/confirm/:bookingId",
    meta: {
      title: "Check-In Confirm Screen",
    },
    component: () => import(`@/components/Checkins/CheckinDaihyoPassword.vue`),
  },
  {
    path: "/checkin",
    name: "CheckinDaihyoIntro",
    meta: {
      title: "Check-In Daihyo",
    },
    component: () => import(`@/components/Checkins/CheckinDaihyoIntro.vue`),
  },
  {
    path: "/enter-password",
    name: "CheckinDaihyoPassword",
    meta: {
      title: "Check-In Daihyo Password",
    },
    component: () => import(`@/components/Checkins/CheckinDaihyoPassword.vue`),
  },
  {
    path: "/enter-information",
    meta: {
      title: "Check-In Daihyo Information",
    },
    component: () => import(`@/components/Checkins/CheckinDaihyoInfor.vue`),
  },
  {
    path: "/upload-passport",
    meta: {
      title: "Check-In Daihyo Upload Passport",
    },
    component: () =>
      import(`@/components/Checkins/CheckinDaihyoUploadPassport.vue`),
  },
  {
    path: "/checkin-language",
    name: "CheckinLanguage",
    meta: {
      title: "Check-In Language",
    },
    component: () => import(`@/components/Checkins/CheckinLanguage.vue`),
  },
  {
    path: "/checkin-create-password",
    meta: {
      title: "Check-In Create Password",
    },
    component: () => import(`@/components/Checkins/CheckinCreatePassword.vue`),
  },
  {
    path: "/checkin-create-name",
    meta: {
      title: "Check-In Create Name",
    },
    component: () => import(`@/components/Checkins/CheckinCreateName.vue`),
  },
  {
    path: "/checkin-create-address",
    meta: {
      title: "Check-In Create Address",
    },
    component: () => import(`@/components/Checkins/CheckinCreateAddress.vue`),
  },
  {
    path: "/checkin-create-career",
    meta: {
      title: "Check-In Create Career",
    },
    component: () => import(`@/components/Checkins/CheckinCreateCareer.vue`),
  },

  {
    path: "/review-registered",
    meta: {
      title: "Check-In Finished Review",
    },
    component: () =>
      import(`@/components/Checkins/CheckinReviewRegistered.vue`),
  },
  {
    path: "/change-password/:bookingId",
    name: "CheckinChangePassword",
    meta: {
      title: "Check-In Change Password",
    },
    component: () => import(`@/components/Checkins/CheckinChangePassword.vue`),
  },
  {
    path: "/change-password-confirm",
    name: "CheckinChangePasswordConfirm",
    meta: {
      title: "Check-In Change Password Confirm",
    },
    component: () =>
      import(`@/components/Checkins/CheckinChangePasswordConfirm.vue`),
  },
  {
    path: "/guest-upload-passport",
    name: "GuestCheckinUploadPassport",
    meta: {
      title: "Guest Check-In Upload Passport",
    },
    component: () =>
      import(`@/components/Checkins/GuestCheckinUploadPassport.vue`),
  },
  {
    path: "/guest-checkin-create-password",
    meta: {
      title: "Guest Check-In Create Password",
    },
    component: () =>
      import(`@/components/Checkins/GuestCheckinCreatePassword.vue`),
  },
  {
    path: "/guest-checkin-create-name",
    meta: {
      title: "Guest Check-In Create Name",
    },
    component: () => import(`@/components/Checkins/GuestCheckinCreateName.vue`),
  },
  {
    path: "/guest-checkin-create-address",
    meta: {
      title: "Guest Check-In Create Address",
    },
    component: () =>
      import(`@/components/Checkins/GuestCheckinCreateAddress.vue`),
  },
  {
    path: "/guest-checkin-create-career",
    meta: {
      title: "Guest Check-In Create Occupation",
    },
    component: () =>
      import(`@/components/Checkins/GuestCheckinCreateOccupation.vue`),
  },
  {
    path: "/finish-registered",
    meta: {
      title: "Check-In Finish Registered",
    },
    component: () =>
      import(`@/components/Checkins/CheckinFinishRegistered.vue`),
  },
  {
    path: "/finished",
    meta: {
      title: "Check-In Daihyo Finished",
    },
    component: () => import(`@/components/Checkins/CheckinDaihyoFinished.vue`),
  },
  {
    path: "/qr",
    meta: {
      title: "QR Code",
    },
    component: () => import('@/views/WebViewQr.vue'),
  },
  {
    path: "/error-page",
    meta: {
      title: "Error Page Screen",
    },
    component: () => import(`@/components/Checkins/CheckinErrorPage.vue`),
  },

  {
    path: "*",
    meta: {
      title: "Error Page",
    },
    component: () => import(`@/components/Checkins/CheckinErrorPage.vue`),
  },
];
