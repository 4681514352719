export default {
  bookingId: null,
  settings: null,
  authStatus: false,
  reserveStatus: false,
  reserve: null,
  reserve_guests: [],
  reserve_user: null,
  token: "",
  isPre: false,

  guestToken: "",
  guest: null,
  guestAuthStatus: false,
  guestReserve: null,

  isPc: false,
  isPcConfirmed: false,
  lang: "ja",
  showHeader: true,
  breakWidth: 0,
  currentUser: {},
  step: 1,
}
