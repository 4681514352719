<template>
  <v-app>
    <core-multi-lang />
<v-container
    fill-height
    fluid
    grid-list-xl
    class="main-layout_inner"
  >
    <v-layout
      justify-center
      wrap
    >
      <!-- inline edit table -->
      <v-flex xs12>

        <v-flex xs12 class="pt-0">
          <h3 class="main_title mb-5">{{ $t('Checkin.please-start-the-check-in') }}</h3>
          <p>{{ $t('Checkin.japanese-laws-and-ordinances') }}</p>
        </v-flex>

      </v-flex>
    </v-layout>
    <div class="main_btn">
          <v-btn block class="btn-custom btn-custom-main" v-on:click="handleSubmit()"
          >{{ $t('Checkin.next') }}</v-btn>
        </div>
  </v-container>
  </v-app>
</template>

<style lang="scss">
  .next-screen-btn {
    background-color: rgb(97 110 126) !important;
    color: #fff;
  }
  .next-screen-btn span {
    font-weight : bold;
  }
</style>

<script>
  import { mapState } from 'vuex'
export default {
  data: () => ({
    userId : null,
    password : null,
    rsvId : null
  }),

  computed: {
    getEnterUploadPassportScreenUrl(){
      return 'guest-upload-passport'
    },
    ...mapState(['guestAuthStatus'])

  },

  created () {
    const userId = this.$store.state.guestReserve.userId;
    const rsvId = this.$store.state.guestReserve.rsvId;

    this.userId = userId
    this.rsvId = rsvId
  },

  mounted(){

  },

  methods: {
    handleSubmit(){
      if(this.guestAuthStatus){
        this.$router.push(this.getEnterUploadPassportScreenUrl)
      }else{
        console.log(this.userId)
        this.handleSubmitForgotPassword()
      }
    },

    handleSubmitForgotPassword() {
      this.$store
              .dispatch("personReset", { id: this.userId })
              .then(res => {
                this.password = res.data.password
                console.log(res)
                this.handleSubmitVerifyPassword()
              })
              .catch((err) => {
                console.log(err);
              });
    },

    handleSubmitVerifyPassword() {
      this.$store
              .dispatch("personLogin", {
                id: this.userId,
                rsvId: this.rsvId,
                password: this.password,
              })
              .then(() => {
                this.$router.push(this.getEnterUploadPassportScreenUrl);
              })
              .catch((err) => {
                if (err.response.status == 422) {
                  alert(err.response.data.global[0])
                }
                console.log(err.response);
              });
    },
  }
}
</script>

<style lang="scss">
</style>
