import moment from 'moment'
class StringUtil {
    static getParamData(param){
        const url = window.location.href
        const newUrl = new URL(url)
        const paramData = newUrl.searchParams.get(param)
        return paramData
    }

    static dateToString(val, format = 'YYYY/MM/DD'){
        if(val.includes('T')){
            return val.substr(0, 10).replace(/-/g, '/')
        }else{
            return moment(val).format(format);
        }
    }
}

export default StringUtil