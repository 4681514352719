<template>
  <div v-if="showPcConfirm">
    <div class="main_intro">
      <div class="main_intro_inner">
        <div class="main_intro_head">
          <img :src="iconConfirmScreenUrl" alt="" />
          <h3 v-if="settings" class="main_intro_title">{{ settings.name }}</h3>
        </div>
        <p
          class="main_intro_desc"
          v-html="
            $t('Checkin.this-site-uses-a-layout-designed-for-smartphones')
          "
        ></p>
        <a
          href="javascript:void(0)"
          v-on:click="pcConfirmed()"
          class="main_intro_link"
        >
          {{ $t("Checkin.continue-in-browser") }}
        </a>
      </div>
    </div>
  </div>
  <v-app v-else>
    <core-multi-lang />
    <v-container fill-height fluid grid-list-xl class="main-layout_content">
      <v-layout justify-center wrap>
        <!-- inline edit table -->
        <v-flex xs12 style="position: relative">
          <div class="content-info">
            <p class="sub-title" v-if="nickName">
              {{ $t("Checkin.mr").replace("%n%", nickName) }}
            </p>
            <v-tooltip
              v-if="nickName"
              v-model="snackbar"
              content-class="sn-card"
              bottom
              :nudge-right="breakWidth < 600 ? 140 : -655"
              :nudge-left="0"
              :nudge-top="breakWidth < 600 ? 0 : 90"
              open-on-hover
              open-on-click
              open-on-focus
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="manualToolTip"
                  color="#616E7E"
                  class="info-icon"
                  >mdi-information-outline</v-icon
                >
              </template>
              <p style="line-height: 19px" class="sub-title ma-0">
                {{ $t("Checkin.nick-name-set-by") }}
              </p>
            </v-tooltip>
          </div>

          <template v-if="checkLoginMess">
            <p class="error--text">{{ checkLoginMess }}</p>
          </template>

          <template v-if="!restore">
            <h3 class="main_title">
              {{ $t("Checkin.select-if-resotre") }}
            </h3>
            <div>
              <v-btn
                block
                class="btn-custom btn-custom-black w-100 mb-4"
                v-on:click="handleSubmitResetPassword()"
                >{{ $t("Checkin.guest-start-over") }}</v-btn
              >
            </div>
            <div>
              <v-btn
                block
                class="btn-custom btn-custom-black w-100"
                v-on:click="restore = true"
                >{{ $t("Checkin.guest-restore") }}</v-btn
              >
            </div>
          </template>
          <template v-else>
            <h3 class="main_title">
              {{ $t("Checkin.please-enter-the-privacy-protection-password") }}
            </h3>
            <v-form
              class="mt-5 mb-30 main_content checkin_form"
              @submit.prevent.stop="handleSubmitVerifyPassword()"
            >
              <v-text-field
                class="purple-input center-input"
                required
                :counter="4"
                :rules="passwordVerifyRules"
                v-model="password"
              />
              <div
                class="center-input v-text-field__details"
                v-if="validateGlobal"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div
                      class="v-messages__message message-transition-enter-to"
                    >
                      {{ validateGlobal }}
                    </div>
                  </div>
                </div>
              </div>
            </v-form>

            <v-btn
              :disabled="!valid"
              :class="valid ? 'btn-submit-green' : 'btn-submit-green-disabled'"
              class="btn-right btn-custom mb-5"
              @click="handleSubmitVerifyPassword()"
              :loading="submitting"
              >{{ $t("Checkin.send-message") }}</v-btn
            >
          </template>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<style lang="scss">
.pw-title {
  font-weight: 400 !important;
  font-size: 60px !important;
  color: rgb(51 72 86) !important;
  text-align: center !important;
}

.next-screen-btn {
  background-color: rgb(97 110 126) !important;
  color: #fff;
}
.next-screen-btn span {
  font-weight: bold;
}

.sn-card {
  background: #ffffff !important;
  border-radius: 10px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.content-info {
  width: 85px;
  display: inline-flex;
  align-items: baseline;
  .info-icon {
    margin-left: 5px;
    font-size: 18px !important;
    position: relative;
    top: 2px;
  }

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 60px;
  }
}

@media screen and (max-width: 375px) {
  .sn-card {
    min-width: 250px;
    max-width: 258px;
  }
}

@media screen and (min-width: 400px) and (max-width: 767px) {
  .sn-card {
    min-width: 258px;
    max-width: 300px;
  }
}

@media screen and (min-width: 768px) {
  .sn-card {
    max-width: 240px;
  }
}

@media screen and (max-width: 320px) {
  .sn-card {
    min-width: 200px;
    max-width: 210px;
  }
}
</style>

<script>
import StringUtil from "@/shared/StringUtil"
import { mapState } from "vuex"

export default {
  data: () => ({
    iconConfirmScreenUrl: require("@/assets/img/icon-checkin-change.png"),
    valid: false,

    userId: null,
    rsvId: null,
    password: null,
    validateGlobal: null,
    checkLoginMess: null,
    showPcConfirm: false,
    currentGuest: {},
    snackbar: false,
    nickName: "",
    submitting: false,
  }),

  computed: {
    restore: {
      get() {
        return !!this.$route.query.restore
      },
      set(value) {
        this.$router.push({
          path: this.$route.fullPath, query: { restore: value ? 'true' : '' }
        })
      }
    },
    isMobileSafari() {
      let nav = window.navigator
      let ua = nav.userAgent
      return (
        "standalone" in nav && // There's a thing called standalone in nav
        !nav.standalone && // It is not running in standalone mode
        ua.indexOf("iPhone") != -1 && // iPhone is in the UA string (could be Opera)
        ua.indexOf("Mac OS") != -1 && // There's Mac in the UA string (not Opera)
        ua.indexOf("Safari") != -1
      )
    },
    passwordVerifyRules() {
      return [
        (v) => !!v || this.$t("Checkin.the-following-field-is-required"),
        (v) =>
          (v && v.length <= 4) ||
          this.$t(
            "Checkin.please-enter-your-privacy-password-maximum-of-4-characters"
          ),
        (v) =>
          v >= 0 ||
          this.$t("Checkin.the-entered-characters-format-is-not-supported"),
      ]
    },
    getEnterCheckinPassportScreenUrl() {
      return "CheckinDaihyoIntro"
    },

    getEnterCheckinLanguageScreenUrl() {
      return "CheckinLanguage"
    },

    getEnterForgotPasswordScreenUrl() {
      return "CheckinChangePasswordConfirm"
    },

    ...mapState([
      "guestAuthStatus",
      "settings",
      "isPc",
      "isPcConfirmed",
      "reserve_guests",
      "breakWidth",
    ]),
  },
  watch: {
    password(value) {
      this.validateGlobal = null

      if (value && value.length <= 4) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
  },

  created() {},

  mounted() {
    const rsvId = this.$route.params.bookingId
    if (rsvId) {
      this.$store.commit("setBookingId", rsvId)
    }
    const userId = StringUtil.getParamData("userId")
    this.rsvId = rsvId
    this.userId = userId
    this.nickName =
      StringUtil.getParamData("nickName") !== "null"
        ? StringUtil.getParamData("nickName")
        : ""
    this.$store.commit("guestReserve", { userId: userId, rsvId: rsvId })

    let isPc = true
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      isPc = false
    }

    if (isPc) {
      if (this.isPcConfirmed) {
        if (!this.isPc) {
          this.$store.commit("isPc", true)
        }
      } else {
        this.showPcConfirm = true
      }
    } else {
      this.$store.commit("isPc", false)
      this.$store.commit("isPcConfirmed", false)
    }

    if (this.guestAuthStatus) {
      // this.$router.push({ name: this.getEnterCheckinLanguageScreenUrl });
    } else {
      if (!this.showPcConfirm) {
        this.fetchCheckLogin()
      }
    }

    if (this.reserve_guests.length) {
      this.currentGuest = this.reserve_guests.find(
        (item) => item.id === this.userId
      )
    }
  },

  methods: {
    pcConfirmed() {
      this.$store.commit("isPcConfirmed", true)
      this.showPcConfirm = false
      this.fetchCheckLogin()
    },
    checknumberRule(str) {
      if (!str.trim()) return true
      if (!isNaN(parseFloat(str)) && str >= 0 && str <= 999) return true
      return false
    },
    handleSubmitVerifyPassword() {
      if (this.valid) {
        this.submitting = true
        this.$store
          .dispatch("personLogin", {
            id: this.userId,
            rsvId: this.rsvId,
            password: this.password,
          })
          .then(() => {
            this.$router.push("/guest-upload-passport")
          })
          .catch((err) => {
            // if (err.response.status == 422) {
            //   this.validateGlobal = err.response.data.global[0];
            // }
            this.validateGlobal = this.$t(
              "Checkin.invalid-privacy-password-please-try-again"
            )
            console.log(err.response)
          })
          .finally(() => (this.submitting = false))
      }
    },
    handleSubmitResetPassword() {
      this.$router.push({ name: this.getEnterForgotPasswordScreenUrl })
    },
    fetchCheckLogin() {
      this.$store
        .dispatch("checkLogin", { id: this.userId })
        .then((res) => {
          if (!res.data) {
            this.$router.push({
              path: "/checkin",
              query: { isLogin: "0", userId: this.userId },
            })
            // this.handleSubmitForgotPassword()
          } else {
            this.checkLoginMess = false
          }
        })
        .catch((err) => {
          if (err.response.status == 422) {
            this.checkLoginMess = err.response.data.global[0]
          } else {
            this.checkLoginMess = err.response.message
          }
        })
    },

    handleSubmitForgotPassword() {
      this.$store
        .dispatch("personReset", { id: this.userId })
        .then((res) => {
          this.password = res.data.password
          console.log(res)
          this.handleSubmitVerifyPasswordNew()
        })
        .catch((err) => {
          console.log(err)
        })
    },

    handleSubmitVerifyPasswordNew() {
      this.$store
        .dispatch("personLogin", {
          id: this.userId,
          rsvId: this.rsvId,
          password: this.password,
        })
        .then(() => {
          // this.$router.push('/guest-upload-passport');
        })
        .catch((err) => {
          if (err.response.status == 422) {
            this.apiMessage = err.response.data.global[0]
          } else {
            this.apiMessage = err.response.data.error
          }
          console.log(err.response)
        })
    },

    manualToolTip() {
      if (this.isMobileSafari) {
        this.snackbar = !this.snackbar
      }
    },
  },
}
</script>

<style lang="scss"></style>
