import Vue from "vue";

import "./components";
import "./plugins";
import { sync } from "vuex-router-sync";

import App from "./App.vue";
import i18n from "@/i18n";

import router from "@/router";

import "@/permission";
import store from "@/store";

import vuetify from "./plugins/vuetify";
const path = require("path")

const ENV_PATH = path.join(__dirname, '.env')
require('dotenv').config({ path: ENV_PATH })

Vue.config.productionTip = false;

Vue.use({
  install (Vue) {
    Vue.prototype.$loading = function (funcOrBool) {
      if (typeof funcOrBool === 'function') {
        // in this case execute the func while showing the loading overlay.
        this.$store.commit('app/setLoading', true);
        
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          try{
            await funcOrBool();
            resolve();
          } catch(e) {
            reject(e);
          } finally {
            this.$store.commit('app/setLoading', false);
          }
        })
      }
      return this.$store.getters.isMainUser
    }
  }
})

window.appApiUrl = process.env.VUE_APP_API_URL

sync(store, router);

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
