<template>
  <v-container fill-height fluid grid-list-xl class="main-layout_content_second">
      <v-layout justify-center wrap>
        <!-- inline edit table -->
        <v-flex xs12>
          <v-flex xs12 class="pt-0">
            <h3 class="main_title">
              {{ $t("Checkin.please-enter-the-guest-name") }}
            </h3>
            <p class="sub-title pb-5">
              {{ $t("Checkin.if-you-are-not-a-Japanese-citizen") }}
            </p>
            <v-form
              ref="form"
              v-model="valid"
              v-if="form"
              class="mt-5 mb-30 pt-5 main_content checkin_form"
              @submit.prevent.stop="handleSubmitName()"
            >
              <v-text-field
                v-model="form.name"
                required
                :counter="40"
                :rules="nameVerifyRules"
                class="purple-input"
                :label="$t('Checkin.name')"
              />
            </v-form>
          </v-flex>

          <div>
            <v-btn
              v-bind:disabled="!valid"
              v-bind:class="
                valid ? 'btn-submit-green' : 'btn-submit-green-disabled'
              "
              class="btn-right btn-custom"
              v-on:click="handleSubmitName()"
              :loading="loading"
              >{{ $t("Checkin.next") }}</v-btn
            >
          </div>
        </v-flex>
      </v-layout>
    </v-container>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "CreateName",
  props:{
    form:{
      type:Object,
      default:() => {},
      required:true
    },
    isGuest:{
      type:Boolean,
      default:() => false,
      required: true
    },
    userId:{
      type:String,
      default:() => null,
      required:true
    },
    visible: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({

    step: '2/5',
    valid: false,
    loading: false,
    addParams: "",
  }),

  computed: {
    ...mapState({
      currentUser:state => state.currentUser,
      currentStep: state => state.step
    }),
    nameVerifyRules(){
      return [
        (v) => !!v || this.$t('Checkin.the-following-field-is-required'),
        (v) => (v && v.length <= 40) || this.$t('Checkin.please-enter-your-name-maximum-of-40-characters'),
        (v) => (v && this.checkSpecificKey(v)) || this.$t('Checkin.the-entered-characters-format-is-not-supported'),
      ]
    },
    getEnterCreateAddressScreenUrl() {
      return "checkin-create-address";
    },
    getEnterCreatePasswordScreenUrl() {
      return "upload-passport" + this.addParams;
    },
  },
  watch:{
    visible() {
      if(this.visible) {
        this.$refs.form.resetValidation()
      }
    }
  },
  mounted() {},

  methods: {
    checkSpecificKey(str) {
      const specialKey = "[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
      for (let i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false;
        }
      }
      return true;
    },

    handleSubmitName() {
      if(this.valid){
        if (this.isGuest) {
          this.submitNameForGuest();
        } else {
          this.submitName();
        }
      }
    },

    submitName() {
      this.loading = true
      this.$store
          .dispatch("updateReservation", {...this.form,name:this.form.name})
          .then(() => {
            this.$store.commit("update_user", {...this.form,name:this.form.name});
            this.$store.commit('setCurrentUser',{...this.form,name:this.form.name})
            // this.$router.push(
            //     this.getEnterCreateAddressScreenUrl + this.addParams
            // );
            this.loading = false
            this.$emit('next-step');
          })
          .catch((err) => {
            console.log(err.response);
          });
    },

    submitNameForGuest() {
      this.loading = true
      this.$store
          .dispatch("updateReservation", {...this.form,name:this.form.name})
          .then(() => {
            this.$store.commit("update_guest", {...this.form,name:this.form.name});
            this.$store.commit('setCurrentUser',{...this.form,name:this.form.name})
            // this.$router.push(
            //     this.getEnterCreateAddressScreenUrl + this.addParams
            // );
            this.loading = false
            this.$emit('next-step');
          })
          .catch((err) => {
            console.log(err.response);
          });
    },

  },
}
</script>

<style lang="scss">
.pw-title {
  font-weight: 400 !important;
  font-size: 60px !important;
  color: rgb(51 72 86) !important;
  text-align: center !important;
}

.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  color: #fff !important;
  height: 43px !important;
}

.btn-submit-green-disabled {
  width: 100%;
  background-color: #e0e0e0 !important;
  color: #f3f3f3;
  height: 43px !important;
}

</style>
