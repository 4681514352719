<template>
  <v-container fill-height fluid grid-list-xl class="main-layout_inner_second">
    <v-layout justify-center wrap>
      <v-flex xs12 class="pt-0">
        <v-form v-if="form">
          <v-flex xs12>
            <h3 class="main_title">{{ $t("Checkin.please-enter-gender") }}</h3>

            <v-row class="mx-auto mt-5 px-0">
              <div class="radio-block flex-nowrap radio-block2">
                <div
                  :key="index"
                  v-for="(item, index) in genderItems"
                  class="career-item"
                >
                  <input
                    type="radio"
                    :id="'radio-gender-' + index"
                    name="radio-gender"
                    :value="item.value"
                    v-model="newForm.gender"
                  />
                  <label
                    :for="'radio-gender-' + index"
                    :style="{
                      'background-color':
                        item.value == newForm.gender ? '#ccc' : '#616e7e',
                      color: '#FFFFFF',
                      width: '80px',
                    }"
                    >{{ $t("Checkin." + item.label) }}</label
                  >
                </div>
              </div>
            </v-row>
          </v-flex>
        </v-form>
      </v-flex>
    </v-layout>
    <div class="main_btn">
      <v-btn
        style="color: #FFFFFF !important;"
        class="btn-custom"
        @click="handleSubmitGender()"
        :disabled="!newForm.gender"
        :class="
          newForm.gender ? 'btn-submit-green' : 'btn-submit-green-disabled'
        "
        >{{ $t("Checkin.next") }}</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex"

export default {
  name: "GuestGender",
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    // form: null,
    newForm: {},
    valid: false,
    genderItems: [
      {
        label: "male",
        value: 1,
      },
      {
        label: "female",
        value: 2,
      },
      {
        label: "other",
        value: 3,
      },
    ],

    // from url params
    isGuest: false,
    userId: null,
    addParams: "",
  }),
  computed: {
    ...mapState(["reserve_guests", "reserve_user", "guest"]),
    ...mapGetters(["lang"]),

    gender() {
      if (this.form) {
        return parseInt(this.newForm.gender)
      } else {
        return null
      }
    },
  },
  watch: {
    // guest() {
    //   this.fetchGuest();
    // },
    gender(value) {
      if (value) {
        this.valid = true
      } else {
        this.valid = false
      }
    },

    lang(val) {
      this.handleGenderGrid(val)
    },
    guest() {
      this.newForm = Object.assign({}, this.newForm, this.form)
    },
  },
  created() {},

  mounted() {
    this.fetchGuestId()
    this.handleGenderGrid(this.$i18n.locale)
    this.newForm = Object.assign({}, this.newForm, this.form)
  },

  updated() {
    this.handleGenderGrid(this.$i18n.locale)
  },
  methods: {
    handleGenderGrid(lang) {
      var lists = document.getElementsByClassName("career-item")
      if (lists.length) {
        for (let item of lists) {
          item.style.minWidth = "auto"
        }
      }

      if (lang !== "ja") {
        var width = 0
        for (let item of lists) {
          if (item.offsetWidth > width) {
            width = item.offsetWidth
          }
        }

        console.log(width)
        for (let item of lists) {
          item.style.minWidth = width + "px"
        }
      } else {
        for (let item of lists) {
          item.style.minWidth = "auto"
        }
      }
    },
    handleSubmitGender() {
      // update api
      this.$store
        .dispatch("guestUpdateReservation", {
          ...this.form,
          gender: this.newForm.gender,
        })
        .then((res) => {
          console.log("res", res.data)
          this.$store.commit("setGuestCurrent", {
            ...this.$store.state.guest,
            gender: res.data.gender,
          })
          // this.$router.push(this.getEnterFinishRegisteredScreenUrl + this.addParams)
          this.$emit("next-step")
        })
        .catch((err) => {
          console.log(err.response)
        })
    },
    fetchGuestId() {
      // this.form = this.$store.state.guest;
      this.userId = this.form.id
    },
  },
}
</script>

<style lang="scss">
.radio-block2 {
  label {
    padding: 10px;
  }
}
.radio-toolbar {
  margin: 10px;
}

.radio-toolbar .career-item {
  float: left;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  background-color: #616e7e;
  padding: 10px 20px;
  font-family: sans-serif, Arial;
  font-size: 12px;
  color: #f3f4f6;
  border-top-right-radius: 20% 50%;
  border-bottom-right-radius: 20% 50%;

  border-top-left-radius: 20% 50%;
  border-bottom-left-radius: 20% 50%;
}

.radio-toolbar label:hover {
  background-color: #ccc;
}

.btn-submit-green {
  width: 100%;
  background-color: rgb(97 110 126) !important;
  color: #ffffff !important;
}

.btn-submit-green-disabled {
  width: 100%;
  background-color: #e0e0e0 !important;
  color: #ffffff !important;
}
</style>
