<template>
  <v-app>
    <core-multi-lang :number-step="step" :back-path="getGuestEnterCreateCareerScreenUrl"/>
<v-container
    fill-height
    fluid
    grid-list-xl
    class="main-layout_content"
  >
    <v-layout
      justify-center
      wrap
    >
      <!-- inline edit table -->
      <v-flex xs12>

        <v-flex xs12 md12 v-if="form">
          <center>
            <h3 class="main_title" style="color:rgb(61 81 94)">{{ $t('Checkin.protection-password') }}</h3>

            <p class="pw-title font-weight-bold" style="color:rgb(51 72 86)">{{ form.password }}</p>

            <p style="color:rgb(186 186 186); width: 70%; font-size: 12px;">{{ $t('Checkin.you-will-need-the-above-password-the-next-time-you-access-the-page') }}</p>

            <h5 class="mb-0 checkin_qr_txt font-weight-bold">{{ $t('Checkin.qr-code-for-check-in') }}</h5>
            <vue-qrcode v-if="qrCodeStr" id="qrblock" ref="qrcode" style="width: 70%" :value="qrCodeStr" />

            <p class="mb-5 checkin_qr_txt">{{ $t('Checkin.scanning-this-qr-code-with-the-tablet-device-at') }}</p>

            <v-btn v-on:click="downloadQrCode()"
            class="qr-code-download-btn checkin_qr mb-5"
            >{{ $t('Checkin.save-image') }}</v-btn>

            <v-col class="text-left" style="width: 80%" v-bind:style="{ 'background-image': 'url(' + '/img/user-checkin-icon.png' + ')', 'background-repeat' : 'no-repeat', 'background-position' : '-2px 10px' }">
              <p style="color: rgb(150 150 150); margin-left:36px;">{{ $t('Checkin.please-note-that-during-check-in') }}</p>
            </v-col>

          </center>
        </v-flex>
      </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<style lang="scss">
.next-screen-btn {
  background-color: rgb(97 110 126) !important;
  color: #fff;
}
.next-screen-btn span {
  font-weight: bold;
}

.pw-title {
  font-size: 60px !important;
  color: rgb(51 72 86) !important;
  text-align: center !important;
}

.qr-code-download-btn.checkin_qr {
  background-color: rgb(204, 177, 255) !important;
  border-radius: 999px !important;
  color: #fff !important;
  box-shadow: none !important;
  font-size: 15px !important;
  padding: 0px 25px !important;
  height: 45px!important;
}
</style>

<script>
import VueQrcode from "vue-qrcode";

export default {
  components: {
    VueQrcode,
  },

  data: () => ({
    form : null,
    qrCodeStr : '',
    step : '6/6',
    careerItems : { 1 : '会社員', 2 : '自営業', 3 : 'その他' },
  }),
  watch : {

  },

  computed: {
    getEnterFinishedScreenUrl() {
      return "finished";
    },

    getGuestEnterCreateCareerScreenUrl(){
      return 'guest-checkin-create-career'
    },
  },

  created () {
    this.fetchGuest()
  },

  mounted() {
  },

  methods: {
    fetchGuest(){
      this.form = this.$store.state.guest
      var qrCodeForm = {bookId: this.$store.state.guestReserve.rsvId, personId: this.$store.state.guest.id}
      this.qrCodeStr = JSON.stringify(qrCodeForm)
    },

    downloadQrCode() {
      var oQrcode = document.getElementById('qrblock')
      var url = oQrcode.src
      var a = document.createElement('a')
      var event = new MouseEvent('click')
      // Customize the name of the image after downloading
      a.download ='QR code'
      a.href = url
      a.dispatchEvent(event)

    },
  }
}
</script>

<style lang="scss"></style>
