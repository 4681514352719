<template>
  <v-app>
    <core-multi-lang
      v-show="showHeader"
      :back-path="getCheckinInforScreenUrl"
    />
    <InfoStep />
  </v-app>
</template>

<style lang="scss">

</style>

<script>
import InfoStep from "@/components/stepper/InfoStep";
import { mapState } from "vuex";
import StringUtil from "@/shared/StringUtil";
export default {
  components: {
    InfoStep
  },
  data: () => ({
    isGuest: false,
    userId: null,
    addParams: ""
  }),

  computed: {
    ...mapState({
      reserve_user: state => state.reserve_user,
      showHeader: state => state.showHeader,
      reserve_guests: state => state.reserve_guests
    }),
    dataFinished() {
      let nullArray = Object.values(this.reserve_user).filter(v => v === null);
      return nullArray.length === 0;
    },

    getCheckinInforScreenUrl() {
      return this.dataFinished ? "finished" : "enter-information";
    }
  },
  watch: {},

  created() {},

  mounted() {
    const isGuest = StringUtil.getParamData("isGuest") === "true";
    const userId = StringUtil.getParamData("userId");
    this.isGuest = isGuest;
    this.userId = userId;

    this.addParams = "?isGuest=" + isGuest;

    if (!isGuest) {
      this.fetchUser();
    } else {
      this.addParams = this.addParams + "&userId=" + this.userId;
      this.fetchGuest();
    }
    // this.fetchPassportImageUrl();
  },

  methods: {
    fetchUser() {
      // this.userObj = this.reserve_user
    },

    fetchGuest() {
      // const filterItem = this.reserve_guests.find(item => item.id === this.userId);
      // this.userObj = filterItem;
    }
  }
};
</script>

<style lang="scss"></style>
