var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"message_item mb-20",attrs:{"id":'guest-item-' + _vm.personItem.id}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-inline-flex align-baseline"},[(_vm.isFinishPage)?_c('h5',{staticClass:"message_title"},[_vm._v(" "+_vm._s(_vm.personItem.nickName ? _vm.personItem.nickName : _vm.$t("Checkin.guest").replace("%n%", _vm.personIndex + 2))+" ")]):_c('h5',{staticClass:"message_title"},[_vm._v(" "+_vm._s(_vm.$t("Checkin.guest").replace("%n%", _vm.personIndex + 2))+" ")]),(!_vm.notOtherThanOwner)?[(_vm.showAddNickName)?_c('img',{staticClass:"mx-3 ic-edit",attrs:{"alt":"ic-edit","src":require("../../assets/img/icon-edit.png")},on:{"click":_vm._handleEditName}}):_vm._e(),_c('span',{staticClass:"sub-feature-text"},[_vm._v(_vm._s(_vm.$t("Checkin.add-nick-name")))])]:_vm._e()],2),(!_vm.notOtherThanOwner)?[(_vm.personItem.name && _vm.personItem.address)?_c('span',[_vm._v(_vm._s(_vm.$t("Checkin.entered")))]):_c('span',[_vm._v(_vm._s(_vm.$t("Checkin.not-entered")))])]:_vm._e()],2),(!_vm.notOtherThanOwner)?[(_vm.isPc)?_c('div',{staticClass:"text-right mb"},[(_vm.reserve)?_c('a',{staticClass:"enter-infor-btn btn-custom",style:({
          'background-image': 'url(' + '/img/icon-share.png' + ')',
          'background-repeat': 'no-repeat',
          'background-position': '20px',
          'text-decoration': 'none',
          'line-height': '48px',
          display: 'inline-block',
        }),attrs:{"href":'mailto:' +
            (_vm.personItem.email != null ? _vm.personItem.email : '') +
            '?body=' +
            _vm.guestLoginScreenUrl +
            '/' +
            _vm.reserve.booking.id +
            '?userId=' +
            _vm.personItem.id +
            '%26nickName=' +
            _vm.personItem.nickName}},[_vm._v(_vm._s(_vm.$t("Checkin.send")))]):_vm._e()]):_c('div',{staticClass:"text-right mb"},[(_vm.reserve)?_c('button',{staticClass:"enter-infor-btn btn-custom",style:({
          'background-image': 'url(' + '/img/icon-share.png' + ')',
          'background-repeat': 'no-repeat',
          'background-position': '20px',
          cursor: 'pointer',
          background: '',
        }),on:{"click":_vm._handleShareMobile}},[_vm._v(" "+_vm._s(_vm.$t("Checkin.send"))+" ")]):_vm._e()])]:_vm._e(),(_vm.notOtherThanOwner)?[_c('div',{staticClass:"text-right mt-n6"},[_c('button',{staticClass:"enter-infor-btn btn-custom",on:{"click":_vm._handleUploadPassport}},[_vm._v(" "+_vm._s(_vm.$t("Checkin.enter"))+" ")])])]:[_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"enter-infor-btn btn-custom",on:{"click":_vm._handleUploadPassport}},[_vm._v(" "+_vm._s(_vm.$t("Checkin.agent-enter"))+" ")])])],_c('hr',{staticClass:"border-bottom mt-20"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }