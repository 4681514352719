<template>
  <div>
    <div class="main_intro">
      <div class="main_intro_inner">
        <div class="main_intro_head">
          <img :src="iconConfirmScreenUrl" alt="">
          <h3 v-if="settings" class="main_intro_title">{{ settings.name }}</h3>
        </div>
        <p class="main_intro_desc" v-html="$t('Checkin.this-site-uses-a-layout-designed-for-smartphones')"></p>
        <a href="javascript:void(0)" v-on:click="pcConfirmed()" class="main_intro_link">
          {{ $t('Checkin.continue-in-browser')}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "DaihyoLanding",
  data() {
    return {
      iconConfirmScreenUrl : require('@/assets/img/icon-checkin-change.png'),
    }
  },

  computed:{
    ...mapState({
      settings:state => state.settings
    })
  },

  methods:{
    pcConfirmed(){
      this.$store.commit('isPcConfirmed', true)
      this.showPcConfirm = false
      this.$emit('next-step', 'DaihyoIntro')
    },
  }
}
</script>

<style scoped lang="scss">
.message-heading {
  color: rgb(66 66 66) !important;
  font-size: 19.5px !important;
  font-weight: 600 !important;
}
.message-desc {
  color: rgb(91 91 91) !important;
  font-size: 15.5px !important;
}

.btn-submit-green {
  // width: 100% !important;
  // font-size : 18px !important;
  // font-weight: 400 !important;
  // text-align : center !important;
  background-color: rgb(97 110 126) !important;
  color: #fff !important;
}

.btn-submit-green-disabled {
  background-color: #e0e0e0 !important;
  color: #f3f3f3;
}
</style>