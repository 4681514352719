<template>
  <transition mode="out-in">
    aaa
    <div id="main">
      <div id="main-layout" >
        <router-view class="main-layout" />
      </div>
      <img src="/img/bg.png" alt="" id="main-bg">
      <img src="/img/bg-main.png" alt="" id="main-frame" @load="onImgLoad">
    <v-overlay :value="loading">
      <v-progress-circular />
    </v-overlay>
    </div>
  </transition>
</template>

<style lang="scss">
  // @import "@/styles/index.scss";
  @import "@/styles/style.scss";
  /* Remove in 1.2 */
  .v-datatable thead th.column.sortable i {
    vertical-align: unset;
  }
</style>
<script>
import {mapGetters, mapState} from 'vuex'
  // checks to see if auth jwt token is valid or has expired, if it gets back 401 error log out
  export default {
    // metaInfo () {
    //   return {
    //     title: '&IoT事前チェックイン ' + (this.$store.state.settings ? ('-' + this.$store.state.settings.name) : '')
    //   }
    // },
    data: () => ({
    }),
    computed : {
      ...mapState({
        breakWidth: state => state.breakWidth,
        loading: state => state.app.loading
      }),
      ...mapGetters(['bookingId']),
    },
    watch:{
      async bookingId(bookingId){

        await this.$loading(async () => {
          if(bookingId){
            await this.$store.dispatch('getProperties', {bookingId : bookingId})
          }else{
            bookingId = this.$route.params.bookingId
            if(bookingId){
              await this.$store.dispatch('getProperties', {bookingId : bookingId})
            }
          }
        })
      }
    },
    created: function () {
      var currentUrl = window.location.href
      var pageForGuest = false
      if(!pageForGuest && currentUrl.includes('checkin-language')){
        pageForGuest = true
      }
      if(!pageForGuest && currentUrl.includes('change-password')){
        pageForGuest = true
      }
      if(!pageForGuest && currentUrl.includes('change-password-confirm')){
        pageForGuest = true
      }
      if(!pageForGuest && currentUrl.includes('guest-upload-passport')){
        pageForGuest = true
      }
      if(!pageForGuest && currentUrl.includes('guest-checkin-create-password')){
        pageForGuest = true
      }
      if(!pageForGuest && currentUrl.includes('guest-checkin-create-name')){
        pageForGuest = true
      }
      if(!pageForGuest && currentUrl.includes('guest-checkin-create-address')){
        pageForGuest = true
      }
      if(!pageForGuest && currentUrl.includes('guest-checkin-create-career')){
        pageForGuest = true
      }
      if(!pageForGuest && currentUrl.includes('review-registered')){
        pageForGuest = true
      }
      if(!currentUrl.includes('error-page')){
        if(pageForGuest){
          if(!this.$store.state.guestAuthStatus || this.$store.state.guestToken == ''){
            if(!currentUrl.includes('change-password')){
              if(this.$store.state.guestReserve !== null){
                window.location.href = "/change-password/" + this.$store.state.guestReserve.rsvId + "?userId=" + this.$store.state.guestReserve.userId
              }else{
                if(this.$store.state.reserve){
                  window.location.href = "/confirm/" + this.$store.state.reserve.booking.id
                }else{
                  window.location.href = "/error-page"
                }
              }
            }
          }
        }else{
          if(!this.$store.state.authStatus || !this.$store.state.reserveStatus){
            if(!currentUrl.includes('confirm')){
              if(this.$store.state.reserve){
                window.location.href = "/confirm/" + this.$store.state.reserve.booking.id
              }else{
                window.location.href = "/error-page"
              }
            }
          }
        }
      }
    },
     mounted() {
       window.addEventListener("resize", this.handleResize);
    },
    updated() {
        this.handleResize();
    },
    methods: {
      handleResize() {
        this.$store.commit('setBreakWidth',window.innerWidth)
      },
      onImgLoad(){
        var boxWidth = document.getElementById('main-bg').offsetWidth;
        var widthBg = document.getElementById('main-frame').offsetWidth;
        document.getElementById("main").style.width = boxWidth + "px";
        document.getElementById("main-layout").style.width = widthBg + "px";
      },
    },
    destroyed() {
      window.removeEventListener("resize", this.handleResize);
    },
  }
</script>
<style scoped>
  #main,
  #main-layout {
    margin: auto;
    width: 500px;
  }
  @media (max-width: 576px) {
    #main,
    #main-layout {
      width: 100% !important;
    }
  }
  @media (max-width: 767px) {
    #main-layout {
      width: 100% !important;
    }
  }
  #main-bg,
  #main-frame {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    z-index: -1;
    height: 100vh;
    /* overflow: hidden;
    z-index: 9999; */
  }
</style>